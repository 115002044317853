import { TextField, InputAdornment, Icon } from "@mui/material";
import styled from "styled-components";
import AutoCompleteField from "../components/AutoCompleteField";
import MainHeader from "../components/Header";
import Button from "@mui/material/Button";
import { useState, useRef, useEffect, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import ReCAPTCHA from "react-google-recaptcha";
import { RECAPTCHA_SITEKEY } from "../constants";
import axios from "axios";
import ReactLoading from "react-loading";
import "@fontsource/caveat/700.css";
import "@fontsource/quicksand/700.css";
import LoadingState from "./LoadingState";
import { LoadScript, StandaloneSearchBox, useLoadScript } from "@react-google-maps/api";
import MobileNavbar from "./mobile/Navbar";
import Footer from "../components/Footer";
import jwt_decode from 'jwt-decode';
const REACT_APP_GOOGLE_MAPS_KEY = process.env.REACT_APP_MAPS_API_KEY;


const MaskedIcon = styled.img`
  position: relative;
  width: 18px;
  height: 22px;
  display: none;
`;
const Button2 = styled.b`
  position: relative;
  letter-spacing: 0.46px;
  line-height: 26px;
  text-transform: capitalize;
`;
const Base = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: var(--gap-5xs);
`;
const Button1 = styled.div`
  position: absolute;
  top: 0px;
  left: 0px;
  border-radius: var(--br-9xs);
  background-color: var(--color-crimson);
  box-shadow: var(--elevation-2);
  overflow: hidden;
  display: flex;
  flex-direction: column;
  padding: var(--padding-5xs) var(--padding-3xl);
  align-items: center;
  justify-content: center;
`;
const Button3 = styled.div`
  position: absolute;
  top: 710px;
  left: 0px;
  width: 193px;
  height: 42px;
`;
const HeroImages1 = styled.div`
  position: absolute;
  top: 44px;
  left: 0px;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  width: 202px;
  height: 292px;
`;
const HeroImages2 = styled.div`
  position: absolute;
  top: 0px;
  left: 241px;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  width: 228px;
  height: 294px;
`;
const HeroImages3 = styled.div`
  position: absolute;
  top: 294px;
  left: 144px;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  width: 167px;
  height: 193px;
`;
const Backgorund = styled.div`
  position: absolute;
  top: 16px;
  left: 0px;
  border-radius: var(--br-78xl);
  background-color: var(--color-palevioletred);
  width: 177px;
  height: 267px;
`;
const ImageIcon = styled.img`
  position: absolute;
  top: -19px;
  left: 30px;
  border-radius: var(--br-78xl);
  width: 188px;
  height: 283px;
  object-fit: cover;
`;
const HeroImages4 = styled.div`
  position: absolute;
  top: 44px;
  left: 26px;
  width: 202px;
  height: 292px;
    &:hover,
  &:focus {
    filter: brightness(0.6);
  }
`;
const Image23 = styled.div`
  position: absolute;
  top: 16px;
  left: 0px;
  border-radius: var(--br-120xl);
  background-color: var(--color-lightskyblue);
  width: 217px;
  height: 271px;

`;
const Image22Icon = styled.img`
  position: absolute;
  top: 0px;
  left: 30px;
  border-radius: var(--br-84xl);
  width: 206px;
  height: 257px;
  object-fit: cover;

`;
const HeroImages5 = styled.div`
  position: absolute;
  top: 0px;
  left: 267px;
  width: 228px;
  height: 294px;
    &:hover,
  &:focus {
    filter: brightness(0.6);
  }
`;
const Image1 = styled.div`
  position: absolute;
  top: 16px;
  left: 0px;
  border-radius: var(--br-78xl);
  background-color: var(--color-gold);
  width: 154px;
  height: 177px;
`;
const ImageIcon1 = styled.img`
  position: absolute;
  top: 0px;
  left: 30px;
  border-radius: var(--br-78xl);
  width: 154px;
  height: 177px;
  object-fit: cover;

`;
const HeroImages6 = styled.div`
  position: absolute;
  top: 294px;
  left: 170px;
  width: 167px;
  height: 193px;
    &:hover,
  &:focus {
    filter: brightness(0.6);
  }
`;
const HeroImages = styled.div`
  position: absolute;
  top: 0px;
  left: 0px;
  width: 521px;
  height: 463px;
`;
const Custom = styled.div`
  margin: 0;
  position: relative;
  font-size: inherit;
  letter-spacing: -1.5px;
  line-height: 120%;
  font-weight: 700;
  font-family: inherit;
  display: inline-block;
  width: 421px;
  color: var(--primary-main, #D81B60);
    font-feature-settings: 'clig' off, 'liga' off;

    font-family: Caveat;
    font-size: 72px;
    font-style: normal;
    font-weight: 700;
    line-height: 120%; /* 86.4px */
    letter-spacing: -1.5px;
`;
const CustomTextContainer2 = styled.div`
  position: relative;
  color: var(--text-primary, rgba(0, 0, 0, 0.87));
font-feature-settings: 'clig' off, 'liga' off;

font-family: Quicksand;
font-size: 20px;
font-style: normal;
font-weight: 700;
line-height: 160%; /* 32px */
letter-spacing: 0.15px;
  width: 400px;
`;
const HeroTitle = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-5xl);
`;
const Textfield1 = styled(TextField)`
  border: none;
  background-color: transparent;
`;
const HeroText = styled.div`
    position: absolute;
  top: 0px;
  left: 650px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-5xl);
  font-size: var(--typography-h1-handwriting-size);
  color: var(--color-crimson);
  font-family: var(--typography-h1-handwriting);
`;
const Container = styled.div`
  position: absolute;
  top: 0px;
  left: 3px;
  width: 1029px;
  height: 366px;
`;
const TravelArrow = styled.div`
  position: absolute;
  top: 384px;
  left: 458px;
  width: 870px;
  height: 203px;
  overflow: hidden;
`;
const HeroSection = styled.section`
  position: absolute;
  top: 130px;
  left: 200px;
  width: 1032px;
  height: 557px;
  text-align: left;
  font-size: var(--button-large-size);
  color: var(--color-white);
  font-family: var(--typography-h6);
`;
const LandingPageRoot = styled.div`
  position: relative;
  background-color: var(--color-white);
  width: 100%;
  height: 800px;
  overflow: hidden;
`;

const SubmitButton = styled(Button)`

`

const libraries = ["places"];

const Home = () => {
  let [state, setState] = useState({});
  let [isLoading, setisLoading] = useState(false);
  const navigate = useNavigate();
  const reCaptchaRef = useRef();
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  const [numDays, setNumDays] = useState(1);
  const isSmallDevice = () => {
    return window.innerWidth < 600
  }

  const [isuserLoggedIn, setIsuserLoggedIn] = useState(false);
  const [user, setUser] = useState(null);
  const token = localStorage.getItem('token');
  if (token && !isuserLoggedIn) {
    try {
      setIsuserLoggedIn(true);
      setUser(jwt_decode(token));
    } catch (error) {
      setIsuserLoggedIn(false);
      localStorage.removeItem('token');
    }
  }

  const intervalItineraryRef = useRef(null);
  const [tempItineraryID, setTempItineraryID ] = useState(null);

  const [mapsAPILoaded, setMapsAPILoaded] = useState(false);

  const onLogoClick = useCallback(() => {
    navigate("/");
  }, [navigate]);

  const placeInputRef = useRef(null);
  const [place, setPlace] = useState(null);

  const handlePlaceChange = () => {
    const place = placeInputRef.current.getPlaces();
    // console.log(place);
    setPlace(place);
  }

  const handleSubmit = event => {
    event.preventDefault();
    if (!event.target.checkValidity()) {
      alert("Please Fill the form.");
      return
    }
    // if (!place) {
    // alert("Please select a location from the dropdown");
    // return
    // }

    const data = {
      days: state.days,
      // city_or_address: place[0].formatted_address,
      city_or_address: state.city_or_address,
      interests: state.interests
    }
    setisLoading(true);

    const createItineraryGenerationRequest = async () => {
      axios.post(`${process.env.REACT_APP_BACKEND_URL}/generateItinerary`, { data }, {
        timeout: 1000 * 60 * 5,
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`
        }
      })
        .then(res => {
          console.log(res.data.id)
          if (!res.data.id) {
            alert("Error in creating itinerary. Please try again.")
            setisLoading(false);
            return;
          }
          setTempItineraryID(res.data.id);
          intervalItineraryRef.current = setInterval(() => checkItineraryGenerationStatus(res.data.id), 1000 * 5);
        }).catch(err => {
          console.log(err)
          alert("Error in creating itinerary. Please try again.")
          setisLoading(false);
        })
    }

    const checkItineraryGenerationStatus = async (id) => {
      axios.post(`${process.env.REACT_APP_BACKEND_URL}/checkItineraryStatus`, { id: id }, {
        timeout: 1000 * 60 * 5,
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`
        }
      })
        .then(res => {
          console.log(res.data)
          if (res.data.status === "done") {
            clearInterval(intervalItineraryRef.current);
            setisLoading(false);
            navigate("/itinerary?it=" + res.data.id)
          }
        }).catch(err => {
          console.log(err)
          clearInterval(intervalItineraryRef.current);
          alert("Error in creating itinerary. Please try again.")
          setisLoading(false);
        })
    }

    createItineraryGenerationRequest();
  }
  const handleChange = event => {
    state[event.target.name] = event.target.value
    setState(state)
  }

  const submitWithCaptcha = (event) => {
    event.preventDefault();

    reCaptchaRef.current.executeAsync().then((token) => {
      console.log(token);
      reCaptchaRef.current.reset();
      handleSubmit(event);
    });
  };

  const captchaOnChange = (value) => {
    // console.log("Captcha value:", value);
  };




  if (isLoading) {
    // return <LoadingState place={place[0].formatted_address ? place[0].formatted_address : state.city_or_address} />
    return <LoadingState place={state.city_or_address} />
  }

  if (isSmallDevice()) {

    return (
      <div className="relative bg-secondary-contrast w-full h-[854px] overflow-y-auto text-left text-[56px] text-primary-main font-typography-h6">

        <div style={{ width: "95vw", minWidth: "300px" }} className="absolute top-[56px] left-[16px] w-[328px] h-[798px] overflow-hidden flex flex-col items-start justify-start gap-[16px]" >
          <b style={{ width: "100%" }} className="relative tracking-[-1.5px] leading-[120%] inline-block font-caveat w-[328px]">
            Vacation Bliss
          </b>
          <div style={{ margin: "auto", marginTop: "0", marginBottom: "0" }} className="relative w-[328px] h-[323px]">
            <div className="absolute top-[21px] left-[0px]  w-36 h-52">
              <div className="absolute top-[16px] left-[0px] rounded-78xl bg-tripdaddy-pink-200 shadow-[0px_1px_10px_rgba(0,_0,_0,_0.12),_0px_4px_5px_rgba(0,_0,_0,_0.14),_0px_2px_4px_-1px_rgba(0,_0,_0,_0.2)] w-32 h-48" />
              <img
                className="absolute top-[0px] left-[16px] rounded-78xl w-32 h-[197.82px] object-cover"
                alt=""
                src="/home1.png"
              />
            </div>
            <div className="absolute top-[0px] left-[184px]  w-36 h-[177px]">
              <div className="absolute top-[16px] left-[0px] rounded-120xl bg-tripdaddy-lightblue-200 shadow-[0px_1px_10px_rgba(0,_0,_0,_0.12),_0px_4px_5px_rgba(0,_0,_0,_0.14),_0px_2px_4px_-1px_rgba(0,_0,_0,_0.2)] w-32 h-[159.85px]" />
              <img
                className="absolute top-[0px] left-[16px] rounded-84xl w-32 h-[158.93px] object-cover"
                alt=""
                src="/home2.png"
              />
            </div>
            <div className="absolute top-[187px] left-[104px]  w-[120px] h-[136px]">
              <div className="absolute top-[16px] left-[0px] rounded-78xl  w-[104px] h-[119.65px]" />
              <img
                className="absolute top-[0px] left-[16px] rounded-78xl w-[104px] h-[119.53px] object-cover"
                alt=""
                src="/hero-images.png"
              />
            </div>
          </div>
          <b className="relative text-xl tracking-[0.15px] leading-[160%] inline-block text-text-primary w-[328px]">
            Organize activities for your next trip or simply wanderlust for your
            bucket list
          </b>
          <form id='main-form' onSubmit={submitWithCaptcha}>
            {/* {mapsAPILoaded ? <div style={{ height: "64px" }}>
              <StandaloneSearchBox style={{ height: "50px" }} onLoad={ref => placeInputRef.current = ref}
                onPlacesChanged={handlePlaceChange} >
                <TextField style={{ width: "292px" }}
                  name="city_or_address"
                  onChange={handleChange}
                  placeholder="Where to?"
                  label="Location"
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                      </InputAdornment>
                    ),
                  }}
                  variant="standard"
                />

              </StandaloneSearchBox>
            </div>
              :  */}
            <TextField style={{ width: "292px" }}
              name="city_or_address"
              onChange={handleChange}
              placeholder="Where to?"
              label="Location"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                  </InputAdornment>
                ),
              }}
              variant="standard"
            />
            {/* } */}
            <TextField style={{ width: "292px" }}
              name="days"
              placeholder="For how long?"
              onChange={handleChange}
              label="Number of Days"
              type="number"
              min="1"
              max="5"
              InputProps={{
                inputProps: {
                  max: 3, min: 1
                },
                startAdornment: (
                  <InputAdornment position="start">
                  </InputAdornment>
                ),
              }}
              variant="standard"
            />
            <TextField style={{ width: "292px" }}
              name="interests"
              onChange={handleChange}
              placeholder="Iconic Sights, Kids Activities, Food"
              label="Activities"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                  </InputAdornment>
                ),
              }}
              variant="standard"
            />
            {/* <AutoCompleteField /> */}
            <ReCAPTCHA
              sitekey={RECAPTCHA_SITEKEY}
              size="invisible"
              onChange={captchaOnChange}
              ref={reCaptchaRef}
            />
            <Button3>
              {isLoading ? <ReactLoading type="spin" color="blue" /> :
                <Button type="submit" variant="contained" style={{ backgroundColor: "var(--color-crimson)", width: "100px", height: "35px", backgroundColor: "var(--color-crimson)", width: "100px", fontFamily: "Quicksand", fontSize: "15px" }}>Let's Go</Button>

              }    </Button3>
          </form>
        </div>
        <MobileNavbar />
        <Footer />
        <div style={{ display: "none" }} id="map">
          {/* <LoadScript googleMapsApiKey={REACT_APP_GOOGLE_MAPS_KEY} libraries={libraries} onLoad={() => { setMapsAPILoaded(true) }} onError={() => { alert("Unable to Load Maps API. Some Features may not work!"); setMapsAPILoaded(true) }} /> */}
        </div>

      </div>
    );

  }

  return (
    <>

      <LandingPageRoot>

        <HeroSection id="hero-section">

          <form id='main-form' onSubmit={submitWithCaptcha}>
            <Container>

              <Button3 style={{ top: "420px", left: "850px" }}>
                {isLoading ? <ReactLoading type="spin" color="blue" /> :
                  <Button type="submit" variant="contained" style={{ backgroundColor: "var(--color-crimson)", width: "100px", height: "35px", backgroundColor: "var(--color-crimson)", width: "100px", fontFamily: "Quicksand", fontSize: "15px" }}>Let's Go</Button>

                }    </Button3>
              <HeroImages>
                <HeroImages4 >
                  <Backgorund />
                  <ImageIcon className="img.darker" alt="" src="/home1.png" />
                </HeroImages4>
                <HeroImages5>
                  <Image23 />
                  <Image22Icon alt="" src="/home2.png" />
                </HeroImages5>
                <HeroImages6>
                  {/* <Image1 /> */}
                  <ImageIcon1 alt="" src="/hero-images.png" />
                </HeroImages6>
              </HeroImages>

              <HeroText>
                <HeroTitle>
                  <Custom>Vacation Bliss</Custom>
                  <CustomTextContainer2>
                    Organize activities for your next trip or simply wanderlust for
                    your bucket list
                  </CustomTextContainer2>
                </HeroTitle>
                <HeroTitle>
                  {/* {mapsAPILoaded ? <div style={{ height: "50px" }}>
                    <StandaloneSearchBox style={{ height: "50px" }} onLoad={ref => placeInputRef.current = ref}
                      onPlacesChanged={handlePlaceChange} > */}
                  {/* <TextField style={{ width: "292px" }}
                        name="city_or_address"
                        onChange={handleChange}
                        placeholder="Where to?"
                        label="Location"
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">
                            </InputAdornment>
                          ),
                        }}
                        variant="standard"
                      />

                    </StandaloneSearchBox>
                  </div>
                    :  */}
                  <TextField style={{ width: "292px" }}
                    name="city_or_address"
                    onChange={handleChange}
                    placeholder="Where to?"
                    label="Location"
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                        </InputAdornment>
                      ),
                    }}
                    variant="standard"
                  />
                  {/* } */}


                  <TextField style={{ width: "292px" }}
                    name="days"
                    placeholder="For how long?"
                    onChange={handleChange}
                    label="Number of Days"
                    type="number"
                    min="1"
                    max="5"
                    InputProps={{
                      inputProps: {
                        max: 3, min: 1
                      },
                      startAdornment: (
                        <InputAdornment position="start">
                        </InputAdornment>
                      ),
                    }}
                    variant="standard"
                  />
                  <TextField style={{ width: "292px" }}
                    name="interests"
                    onChange={handleChange}
                    placeholder="Iconic Sights, Kids Activities, Food"
                    label="Activities"
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                        </InputAdornment>
                      ),
                    }}
                    variant="standard"
                  />
                  {/* <AutoCompleteField /> */}
                  <ReCAPTCHA
                    sitekey={RECAPTCHA_SITEKEY}
                    size="invisible"
                    onChange={captchaOnChange}
                    ref={reCaptchaRef}
                  />
                </HeroTitle>
              </HeroText>


            </Container>
            {/* <TravelArrow /> */}
          </form>
        </HeroSection>
        <MainHeader />
      </LandingPageRoot>
      <div style={{ display: "none" }} id="map">
        <LoadScript googleMapsApiKey={REACT_APP_GOOGLE_MAPS_KEY} libraries={libraries} onLoad={() => { setMapsAPILoaded(true) }} onError={() => { alert("Unable to Load Maps API. Some Features may not work!"); setMapsAPILoaded(true) }} />
      </div>
      <Footer />

    </>
  );
};

export default Home;
