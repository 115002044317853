import styled from "styled-components";

const TravelArrowlogoIconRoot = styled.img`
  position: relative;
  width: 220px;
  height: 40px;
`;
const Logo = () => {
  return <TravelArrowlogoIconRoot alt="" src="/logo.svg" />;
};

export default Logo;
