import * as React from 'react';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
// import Link from '@mui/material/Link';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import axios from "axios";
import { useNavigate, useSearchParams } from 'react-router-dom';
import ReCAPTCHA from "react-google-recaptcha";
import { RECAPTCHA_SITEKEY } from "../constants";
import { useRef } from 'react';
import { NavLink, Link } from "react-router-dom"

function Copyright(props) {
  return (
    <Typography variant="body2" color="text.secondary" align="center" {...props}>
      {'Copyright © '}
      <NavLink color="inherit" to="/">
        TripDaddy
      </NavLink>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}

const defaultTheme = createTheme();

export default function SignInSide() {
  const navigate = useNavigate();
  const reCaptchaRef = useRef();
  const [searchParams, setSearchParams] = useSearchParams();

  const captchaOnChange = (value) => {
    // console.log("Captcha value:", value);
  };

  const handleCaptchaExpire = () => {
    reCaptchaRef.current.reset();
  };
  const [redirect, setRedirect] = React.useState(false);

  React.useEffect(() => {
    if (searchParams.has('redirect')) {
      setRedirect(true);
    }
  }, []);



  const handleSubmit = async (event) => {
    event.preventDefault();
    if (!event.target.checkValidity()) {
      alert("Please Fill the form.");
      return
    }

    const handleResponse = (response) => {
      const token = response.data.token;
      localStorage.setItem('token', token);
      alert("Login Successful");
      if (redirect) {
        navigate("/itinerary?it=" + searchParams.get('it') + "&token=" + searchParams.get('token'));
        return
      }
      navigate("/");
    }

    const handleError = (error) => {
      console.log(error);
      if (error.response.status === 500) {
        alert("Some error occured on the server. Please try again later.");
        return
      }
      alert("Login Failed : " + error.response.data.message);
    }

    const data = new FormData(document.getElementById('login-form'));
    let bodyContent = {
      "email": data.get('email'),
      "password": data.get('password'),
    };

    let reqOptions = {
      url: process.env.REACT_APP_BACKEND_URL + "/api/auth/signin",
      method: "POST",
      data: bodyContent,
      withCredentials: true,
    }

    axios.post(reqOptions.url, reqOptions.data, {
      withCredentials: true,
    }).then(handleResponse).catch(handleError);

  };

  const submitWithCaptcha = (event) => {
    event.preventDefault();

    reCaptchaRef.current.executeAsync().then((token) => {
      // console.log(token);
      handleSubmit(event);
    });
  };

  

  return (
    <ThemeProvider theme={defaultTheme}>
      <Grid container component="main" sx={{ height: '100vh' }}>
        <CssBaseline />
        <Grid
          item
          xs={false}
          sm={4}
          md={7}
          sx={{
            backgroundImage: 'url(https://source.unsplash.com/random?trip)',
            backgroundRepeat: 'no-repeat',
            backgroundColor: (t) =>
              t.palette.mode === 'light' ? t.palette.grey[50] : t.palette.grey[900],
            backgroundSize: 'cover',
            backgroundPosition: 'center',
          }}
        />
        <Grid item xs={12} sm={8} md={5} component={Paper} elevation={6} square>
          <Box
            sx={{
              my: 8,
              mx: 4,
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
            }}
          >
            <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
              <LockOutlinedIcon />
            </Avatar>
            <Typography component="h1" variant="h5">
              Sign in
            </Typography>
            <Box component="form" id='login-form' noValidate onSubmit={submitWithCaptcha} sx={{ mt: 1 }}>
              <TextField
                margin="normal"
                required
                fullWidth
                id="email"
                label="Email Address"
                name="email"
                autoComplete="email"
                autoFocus
              />
              <TextField
                margin="normal"
                required
                fullWidth
                name="password"
                label="Password"
                type="password"
                id="password"
                autoComplete="current-password"
              />
              <FormControlLabel
                control={<Checkbox value="remember" color="primary" />}
                label="Remember me"
              />
              <ReCAPTCHA
                sitekey={RECAPTCHA_SITEKEY}
                size="invisible"
                onChange={captchaOnChange}
                ref={reCaptchaRef}
              />
              <Button
                type="submit"
                fullWidth
                variant="contained"
                sx={{ mt: 3, mb: 2 }}
              >
                Sign In
              </Button>
              <Grid container>
                <Grid item xs>
                  {/* <Link href="#" variant="body2">
                    Forgot password?
                  </Link> */}
                </Grid>
                <Grid item>
                  <Link to="/register" variant="body2">
                    {"Don't have an account? Sign Up"}
                  </Link>
                </Grid>
              </Grid>
              <Copyright sx={{ mt: 5 }} />
            </Box>
          </Box>
        </Grid>
      </Grid>
    </ThemeProvider>
  );
}