import * as React from "react";
import { GoogleMap, useLoadScript, useJsApiLoader, MarkerF, InfoBoxF } from "@react-google-maps/api";
import Marker from "./Marker";
import GoogleMapReact from 'google-map-react';

const REACT_APP_GOOGLE_MAPS_KEY = process.env.REACT_APP_MAPS_API_KEY;

const MapPin = () => {
    return <svg xmlns="http://www.w3.org/2000/svg" width="46" height="46" viewBox="0 0 46 46" fill="none">
        <path d="M23.0002 3.83301C15.5827 3.83301 9.5835 9.83217 9.5835 17.2497C9.5835 27.3122 23.0002 42.1663 23.0002 42.1663C23.0002 42.1663 36.4168 27.3122 36.4168 17.2497C36.4168 9.83217 30.4177 3.83301 23.0002 3.83301ZM23.0002 22.0413C20.3552 22.0413 18.2085 19.8947 18.2085 17.2497C18.2085 14.6047 20.3552 12.458 23.0002 12.458C25.6452 12.458 27.7918 14.6047 27.7918 17.2497C27.7918 19.8947 25.6452 22.0413 23.0002 22.0413Z" fill="#F48FB1" />
    </svg>
}

const InfoWindow = () => {
    return <div>InfoWindow</div>
}





const MapComponent = ({ activities, locations, width }) => {
    const isLoaded = true;

    let containerStyle = {
        width: '792px',
        height: '250px'
    };
    if (width) {
        containerStyle.width = width
    }
    const distanceToMouse = (pt, mp) => {
        if (pt && mp) {
            // return distance between the marker and mouse pointer
            return Math.sqrt(
                (pt.x - mp.x) * (pt.x - mp.x) + (pt.y - mp.y) * (pt.y - mp.y)
            );
        }
    };
    console.log(activities)

    return isLoaded ? (
        <div style={containerStyle}>
            <GoogleMapReact
                defaultZoom={9}
                defaultCenter={locations[0]}
                bootstrapURLKeys={{
                    key: REACT_APP_GOOGLE_MAPS_KEY,
                    libraries: ['places', 'geometry'],
                }}
                options={{
                    mapTypeId: "terrain",
                }}
                distanceToMouse={distanceToMouse}
            >
                {activities ?
                    activities.map((activity, index) => {
                        return <Marker key={index} text={index + 1} lat={locations[index].lat} lng={locations[index].lng} data={activity} trip_data={activities.trip_data} image={activity.images[0].url} tooltip={"test tooltip"} />
                    })
                    : null}
                <></>
            </GoogleMapReact>
        </div>
    ) : <></>
};

export default MapComponent;