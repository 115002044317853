import * as React from 'react';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';

export default function SelectInput({label, defaultValue, onChange, options, ...rest}) {
    const [value, selectValue] = React.useState(defaultValue);
    // console.log(value);

    const handleChange = (event) => {
        selectValue(event.target.value);
        onChange(event.target.value);
    };

    return (
        <FormControl size="small" variant="standard" fullWidth>
            <InputLabel id="select-label">{label}</InputLabel>
            <Select
                labelId="select-label"
                id="select-"
                value={value}
                label="Label"
                onChange={handleChange}
            >
                {options.map((option, index) => (
                    <MenuItem key={index} value={option.poi}>{option.poi}</MenuItem>
                ))}
            </Select>
        </FormControl>
    );
}