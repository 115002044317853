import { useCallback, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { TextField, InputAdornment, Button } from "@mui/material";
import ReCAPTCHA from "react-google-recaptcha";
import { RECAPTCHA_SITEKEY } from "../../constants";
import styled from "styled-components";
import ReactLoading from "react-loading";
const SubmitButton = styled(Button)`

`


const Button3 = styled.div`
  position: absolute;
  top: 700px;
  left: 0px;
  width: 193px;
  height: 42px;
`;



const LandingPageMobile = () => {
    const navigate = useNavigate();
    const reCaptchaRef = useRef();
    let [isLoading, setisLoading] = useState(false);

    const handleChange = event => {
        state[event.target.name] = event.target.value
        setState(state)
    }

    const captchaOnChange = (value) => {
        // console.log("Captcha value:", value);
    };

    const onLogoClick = useCallback(() => {
        navigate("/");
    }, [navigate]);

    return (
        <div className="relative bg-secondary-contrast w-full h-[854px] overflow-y-auto text-left text-[56px] text-primary-main font-typography-h6">
            <div style={{ width: "95vw", minWidth: "300px" }} className="absolute top-[56px] left-[16px] w-[328px] h-[798px] overflow-hidden flex flex-col items-start justify-start gap-[16px]" >
                <b style={{ width: "100%" }} className="relative tracking-[-1.5px] leading-[120%] inline-block font-caveat w-[328px]">
                    Vacation Bliss
                </b>
                <div style={{ margin: "auto", marginTop: "0", marginBottom: "0" }} className="relative w-[328px] h-[323px]">
                    <div className="absolute top-[21px] left-[0px]  w-36 h-52">
                        <div className="absolute top-[16px] left-[0px] rounded-78xl bg-tripdaddy-pink-200 w-32 h-48" />
                        <img
                            className="absolute top-[0px] left-[16px] rounded-78xl w-32 h-[197.82px] object-cover"
                            alt=""
                            src="/home1.png"
                        />
                    </div>
                    <div className="absolute top-[0px] left-[184px]  w-36 h-[177px]">
                        <div className="absolute top-[16px] left-[0px] rounded-120xl bg-tripdaddy-lightblue-200 w-32 h-[159.85px]" />
                        <img
                            className="absolute top-[0px] left-[16px] rounded-84xl w-32 h-[158.93px] object-cover"
                            alt=""
                            src="/home2.png"
                        />
                    </div>
                    <div className="absolute top-[187px] left-[104px]  w-[120px] h-[136px]">
                        <div className="absolute top-[16px] left-[0px] rounded-78xl  w-[104px] h-[119.65px]" />
                        <img
                            className="absolute top-[0px] left-[16px] rounded-78xl w-[104px] h-[119.53px] object-cover"
                            alt=""
                            src="/hero-images.png"
                        />
                    </div>
                </div>
                <b className="relative text-xl tracking-[0.15px] leading-[160%] inline-block text-text-primary w-[328px]">
                    Organize activities for your next trip or simply wanderlust for your
                    bucket list
                </b>
                <TextField style={{ width: "292px" }}
                    name="city_or_address"
                    onChange={handleChange}
                    placeholder="Where to?"
                    label="Location"
                    InputProps={{
                        startAdornment: (
                            <InputAdornment position="start">
                            </InputAdornment>
                        ),
                    }}
                    variant="standard"
                />
                <TextField style={{ width: "292px" }}
                    name="days"
                    placeholder="For how long?"
                    onChange={handleChange}
                    label="Number of Days"
                    type="number"
                    min="1"
                    max="5"
                    InputProps={{
                        inputProps: {
                            max: 3, min: 1
                        },
                        startAdornment: (
                            <InputAdornment position="start">
                            </InputAdornment>
                        ),
                    }}
                    variant="standard"
                />
                <TextField style={{ width: "292px" }}
                    name="interests"
                    onChange={handleChange}
                    placeholder="Iconic Sights, Kids Activities, Food"
                    label="Activities"
                    InputProps={{
                        startAdornment: (
                            <InputAdornment position="start">
                            </InputAdornment>
                        ),
                    }}
                    variant="standard"
                />
                {/* <AutoCompleteField /> */}
                <ReCAPTCHA
                    sitekey={RECAPTCHA_SITEKEY}
                    size="invisible"
                    onChange={captchaOnChange}
                    ref={reCaptchaRef}
                />
                <Button3>
                    {isLoading ? <ReactLoading type="spin" color="blue" /> :
                        <Button type="submit" variant="contained" style={{ backgroundColor: "var(--color-crimson)", width: "100px", height: "35px", backgroundColor: "var(--color-crimson)", width: "100px", fontFamily: "Quicksand", fontSize: "15px" }}>Let's Go</Button>

                    }    </Button3>
            </div>
            <div style={{ width: "100%" }} className="absolute top-[0px] left-[0px] bg-secondary-contrast shadow-[0px_1px_14px_rgba(0,_0,_0,_0.12),_0px_5px_8px_rgba(0,_0,_0,_0.14),_0px_3px_5px_-1px_rgba(0,_0,_0,_0.2)] w-[360px] h-12 overflow-hidden flex flex-row p-4 box-border items-center justify-between text-smi text-secondary-contrast">
                <img
                    className="relative w-[104px] h-[19px] cursor-pointer"
                    alt=""
                    src="/logo.svg"
                    onClick={onLogoClick}
                />
                <div className="w-[136px] h-[42px] flex flex-row items-center justify-center">
                    <div className="flex flex-row items-start justify-start gap-[8px]">
                        <div className="rounded-borderradius bg-secondary-main shadow-[0px_1px_5px_rgba(0,_0,_0,_0.12),_0px_2px_2px_rgba(0,_0,_0,_0.14),_0px_3px_1px_-2px_rgba(0,_0,_0,_0.2)] overflow-hidden flex flex-col py-1 px-2.5 items-center justify-center">
                            <div className="flex flex-row items-center justify-center gap-[8px]">
                                <img
                                    className="relative w-4 h-[18px] hidden"
                                    alt=""
                                    src="/masked-icon2.svg"
                                />
                                <b className="relative tracking-[0.46px] leading-[22px] capitalize">
                                    Sign Up
                                </b>
                                <img
                                    className="relative w-4 h-[18px] hidden"
                                    alt=""
                                    src="/masked-icon3.svg"
                                />
                            </div>
                        </div>
                        <div className="rounded-borderradius overflow-hidden flex flex-col py-1 px-[5px] items-center justify-center text-secondary-main">
                            <div className="flex flex-row items-center justify-center gap-[8px]">
                                <img
                                    className="relative w-4 h-[18px] hidden"
                                    alt=""
                                    src="/masked-icon4.svg"
                                />
                                <b className="relative tracking-[0.46px] leading-[22px] capitalize">
                                    Log in
                                </b>
                                <img
                                    className="relative w-4 h-[18px] hidden"
                                    alt=""
                                    src="/masked-icon5.svg"
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default LandingPageMobile;
