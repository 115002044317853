import React from 'react';
import {useNavigate,useSearchParams} from 'react-router-dom';
import axios from 'axios';

const VerifyEmail = () => {
    const [searchParams, setSearchParams] = useSearchParams();
    const navigate = useNavigate();
    const token =  searchParams.get('token');
    
    axios.post( process.env.REACT_APP_BACKEND_URL + "/api/auth/verifyEmail", {token: token}).then(res=>{
        // console.log(res.data);
        const token = res.data.token;
        localStorage.setItem('token', token);
        alert("Email Verified Successfully");
        navigate('/', { replace: true });
    }).catch(err=>{
        console.log(err);
        alert("Email Verification Failed");
    })
  
    return <div>Verifying Email...</div>;
}

export default VerifyEmail;