import React,{useState, useRef} from 'react';
import styled from 'styled-components';
import NavBar from '../components/NavBar';
import Footer from '../components/Footer';
import TripForm from '../components/TripForm';
import axios from "axios"; 
import {useNavigate} from 'react-router-dom';
import ReactLoading from 'react-loading';
import ReCAPTCHA from "react-google-recaptcha";
import { RECAPTCHA_SITEKEY } from "../constants";

const PageContainer = styled.div`
top: -11px;
left: -16px;
width: 100%;
height: 1750px;
border-radius: 2px;
background-image: url(./bg.jpeg);
background-position: center center;
background-size: cover;
background-repeat: no-repeat;`

const DataContainer = styled.div`
min-height: 150px;

`
const BoldText = styled.div`
color: #030303;
font-size: 32px;
font-family: "Poppins";
font-weight: 600;
line-height: 42px;
text-align: center;
`
const SmallText = styled.div`
color: #030303;
font-size: 20px;
font-family: "Poppins";
line-height: 30px;
text-align: center;
`

const FormContainer = styled.form`
width: 90%;
display: flex;
justify-content: space-between;
align-items: center;
  `


const FormText = styled.div`
  color: #5d5d5b;
  font-size: 14px;
  font-family: "Poppins";
  font-weight: 500;
  line-height: 22px;
  min-width: 100px;
`

const InputContainer = styled.div`
	position:relative;
	width: 130px;
  padding: 6px;
`
const InputLabel = styled.label`
	position:absolute;
	top:0px;
	left:0px;	
    color: #5d5d5b;
  font-size: 14px;
  font-family: "Poppins";
  font-weight: 500;
  line-height: 22px;
  min-width: 180px;
    pointer-event:none;
	transition: all 0.5s ease-in-out;
  
`
const FormInput = styled.input`
	border:0;
  background:transparent;
  width:100%;
  padding:8px 0 5px 0;
  color: #5d5d5b;
  font-size: 14px;
  font-family: "Poppins";
  font-weight: 500;
  line-height: 22px;
  width: 150px;
    &:focus {
         border:none;	
 outline:none;
    }
  
`

const VerticalDivider = styled.div`
  width: 1px;
  height: 48px;
  background-color: #dedede;
  border-radius: 2px;
`

const HorizontalDivider = styled.div`
  width: 60px;
  height: 1px;
  background-color: #dedede;
  border-radius: 2px;
`
const SubmitButton = styled.button`
 top: 370px;
  left: 1026px;
  width: 179px;
  height: 38px;
  padding: 0px 8px;
  border: 0;
  box-sizing: border-box;
  border-radius: 2px;
  box-shadow: -2px 2px 0px rgba(0,0,0,0.25);
  background-color: #4cd964;
  color: #030303;
  font-size: 14px;
  font-family: "Poppins";
  line-height: 18px;
  outline: none;
`
const FormWrapper = styled.div`
width: 1000px;
max-height: 80px;
height: 70px;
background-color: #f0f0f0;
border-radius: 100px;
display: flex;
justify-content: center;
align-items: center;
`

const FormContainerMobile = styled.form`
width: 90%;
display: flex;
justify-content: space-between;
align-items: center;
flex-direction: column;
  `

const HomePage = () => {
  let [state, setState] = useState({});
  let [isLoading, setisLoading] = useState(false);
  const navigate = useNavigate();
  const reCaptchaRef = useRef();
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());

  const isSmallDevice = () => {
    return window.innerWidth < 800 
  }

  const handleSubmit = event => {
    event.preventDefault();
    if (! event.target.checkValidity()) {
      alert("Please Fill the form.");
      return
    }
    const data = {
      from: state.from,
      to: state.to,
      city_or_address: state.city_or_address,
      interests: state.interests
    }
    setisLoading(true);
    axios.post(`${process.env.REACT_APP_BACKEND_URL}/generateItinerary`, { data })
      .then(res=>{
        console.log(res.data)
        navigate("/itinerary?it=" + res.data)
      }).catch(err=>{
        console.log(err)
        alert("Error in creating itinerary. Please try again.")
        setisLoading(false);
      })
  }
  const handleChange = event =>{
    state[event.target.name] = event.target.value
    setState(state)
  }

  const submitWithCaptcha = (event) => {
    event.preventDefault();

     reCaptchaRef.current.executeAsync().then((token) => {
      // console.log(token);
      handleSubmit(event);
    });
  };

  const captchaOnChange = (value) => {
    // console.log("Captcha value:", value);
  };

  if (isSmallDevice()) {

    return <PageContainer>
      <NavBar />
      <DataContainer>
        <div style={{minHeight: "100px"}}></div>
        <BoldText>
        Discover a world made for you
        </BoldText>
        <SmallText>
        Explore 1,480,086 Itineraries around the world or create your own.
        </SmallText>
        <div style={{minHeight: "140px"}}></div>
        <div style={{  display: 'flex', justifyContent: 'center'}}>
          <FormWrapper style={{maxHeight: "280px", height:"260px"}}>
        <FormContainerMobile id='main-form' onSubmit={submitWithCaptcha}>
          	<InputContainer>		
              <FormInput type="text" required={true} placeholder='Where do you want to go?' name='city_or_address' onChange={handleChange}/>
              {/* <InputLabel>Where do you want to go?</InputLabel> */}
            </InputContainer>
            <HorizontalDivider></HorizontalDivider>
            <InputContainer>		
              <FormInput type="date" minDate={startDate} min={startDate} required={true} name='from' placeholder='Starting When?' onChange={handleChange}/>
              {/* <InputLabel>Starting When?</InputLabel> */}
            </InputContainer>
            <HorizontalDivider></HorizontalDivider>
            <InputContainer>		
              <FormInput type="date" required={true} name='to' placeholder='Until When?' onChange={handleChange}/>
              {/* <InputLabel>Until When?</InputLabel> */}
            </InputContainer>
            <HorizontalDivider></HorizontalDivider>
            <InputContainer>		
              <FormInput type="text" required={true} name='interests' placeholder='Preferred Activities' onChange={handleChange}/>
              {/* <InputLabel>Preferred Activities</InputLabel> */}
            </InputContainer>
            <ReCAPTCHA
              sitekey={RECAPTCHA_SITEKEY}
              size="invisible"
              onChange={captchaOnChange}
              ref={reCaptchaRef}
            />
            {isLoading? <ReactLoading type="spin" color="blue"  />  : <SubmitButton type='submit'>
              Create Itinerary
            </SubmitButton>}
        </FormContainerMobile>
        </FormWrapper>
        </div>

      </DataContainer>
      <div style={{height: "73%"}}></div>
      <Footer />
    </PageContainer>
  } else {
  return (
    <PageContainer>
      <NavBar />
      <DataContainer>
        <div style={{minHeight: "100px"}}></div>
        <BoldText>
        Discover a world made for you
        </BoldText>
        <SmallText>
        Explore 1,480,086 Itineraries around the world or create your own.
        </SmallText>
        <div style={{minHeight: "140px"}}></div>
        <div style={{  display: 'flex', justifyContent: 'center'}}>
          <FormWrapper>
        <FormContainer id='main-form' onSubmit={submitWithCaptcha}>
          	<InputContainer>		
              <FormInput type="text" required={true} placeholder='Where do you want to go?' name='city_or_address' onChange={handleChange}/>
              {/* <InputLabel>Where do you want to go?</InputLabel> */}
            </InputContainer>
            <VerticalDivider></VerticalDivider>
            <InputContainer>		
              <FormInput type="date" required={true} name='from' placeholder='Starting When?' onChange={handleChange}/>
              {/* <InputLabel>Starting When?</InputLabel> */}
            </InputContainer>
            <VerticalDivider></VerticalDivider>
            <InputContainer>		
              <FormInput type="date" required={true} name='to' placeholder='Until When?' onChange={handleChange}/>
              {/* <InputLabel>Until When?</InputLabel> */}
            </InputContainer>
            <VerticalDivider></VerticalDivider>
            <InputContainer>		
              <FormInput type="text" required={true} name='interests' placeholder='Preferred Activities' onChange={handleChange}/>
              {/* <InputLabel>Preferred Activities</InputLabel> */}
            </InputContainer>
            <ReCAPTCHA
              sitekey={RECAPTCHA_SITEKEY}
              size="invisible"
              onChange={captchaOnChange}
              ref={reCaptchaRef}
            />
            {isLoading? <ReactLoading type="spin" color="blue"  />  : <SubmitButton type='submit'>
              Create Itinerary
            </SubmitButton>}
        </FormContainer>
        </FormWrapper>
        </div>

      </DataContainer>
      <div style={{height: "73%"}}></div>
      <Footer />
    </PageContainer>
  );
  }
};

export default HomePage;
