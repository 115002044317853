import * as React from "react";
import { Modal, Tab, Tabs, Box, Button, Input, NativeSelect } from "@mui/material";
import PropTypes from 'prop-types';
import styled from "styled-components";
import { TwitterShareButton, FacebookShareButton } from "react-share";
import { AddToCalendarButton } from "add-to-calendar-button-react";
import { useSearchParams } from "react-router-dom";
import axios from "axios";
import jwt_decode from "jwt-decode";


function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

function CustomTabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                // <Box sx={{ p: 3 }}>
                children
                // </Box>
            )}
        </div>
    );
}

CustomTabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};

const ShareModal = ({ open, handleClose }) => {
    const url = window.location.href;
    const [value, setValue] = React.useState(0);
    const handleDayChange = (event, newValue) => {
        setValue(newValue);
    };
    const [searchParams, setSearchParams] = useSearchParams();
    const [isuserLoggedIn, setIsuserLoggedIn] = React.useState(false);
    const [user, setUser] = React.useState(null);
    const token = localStorage.getItem('token');
    // console.log(token);
    if (token && !isuserLoggedIn) {
      try {
        setIsuserLoggedIn(true);
        setUser(jwt_decode(token));
      } catch (error) {
        setIsuserLoggedIn(false);
        localStorage.removeItem('token');
      }
    }

    const onSendInvitationClick = (e) => {
        // console.log(e.currentTarget.dataset.id);
        const id = searchParams.get("it");
        // console.log(id);
        if (!id) {
            return;
        }
        const email = document.getElementsByName("email")[0].value;
        const role = document.getElementsByName("role")[0].value;
        axios.post(`${process.env.REACT_APP_BACKEND_URL}/api/itinerary/invite`, {
            id: id,
            email: email,
            role: role,
        },{
            headers: {
                Authorization: `Bearer ${token}`,
            },
        }).then((res) => {
            console.log(res);
            alert(res.data.message);
            handleClose();
        }
        ).catch((err) => {
            console.log(err);
            alert(err.response.data.message);
        }
        );

    }

    return (
        <Modal open={open} onClose={handleClose}>
            <div className="shadow-[0px_7px_9px_-4px_rgba(0,_0,_0,_0.2),_0px_14px_21px_2px_rgba(0,_0,_0,_0.14),_0px_5px_26px_4px_rgba(0,_0,_0,_0.12)] overflow-hidden bg-white flex flex-col gap-12 w-full pt-4 pb-8 px-6 rounded-lg" style={{ maxWidth: "600px", width:"85vw", height: "400px", margin: "auto", marginTop: "20vh" }}>
                <div className="flex flex-col gap-1 h-64 shrink-0 items-start">
                    <div className="self-stretch flex flex-row justify-between mb-3 items-center">
                        <div className="text-2xl font-['Quicksand'] font-bold leading-[32px] text-black/87">
                            Share itinerary
                        </div>
                        <div
                            onClick={handleClose}
                            style={{ cursor: "pointer" }}
                            className="shadow-[0px_2px_1px_-1px_rgba(0,_0,_0,_0.2),_0px_1px_1px_0px_rgba(0,_0,_0,_0.14),_0px_1px_3px_0px_rgba(0,_0,_0,_0.12)] overflow-hidden bg-[#fafafa] self-start flex flex-col justify-center w-12 shrink-0 h-12 items-center rounded-[100px]"
                            id="Fab"
                        >
                            <img
                                src="https://file.rendit.io/n/AVl6GGpu3bHvfm07NUxk.svg"
                                className="w-6"
                                id="CloseFilled"
                            />
                        </div>
                    </div>
                    <Tabs value={value} TabIndicatorProps={{ style: { background: "var(--primary-main, #D81B60)" } }} textColor="secondary" onChange={handleDayChange} aria-label="share tabs">
                        <Tab label={`Invite`} {...a11yProps(0)} className="font-['Quicksand']" style={{ color: "var(--primary-main, #D81B60)", }} />
                        <Tab label={`Publish`} {...a11yProps(1)} className="font-['Quicksand']" style={{ color: "var(--primary-main, #D81B60)", }} />

                    </Tabs>
                    <CustomTabPanel value={value} index={0}>

                        <div className="font-['Quicksand'] tracking-[0.15] leading-[24px] text-black/87 mb-4 ml-3" style={{ marginTop: "20px" }}>
                            Invite your loved ones to collaborate on the itinerary with you!
                        </div>
                        <div className="flex flex-row justify-between w-3/5 items-start mb-px ml-3">
                            <div
                                className="text-xs font-['Quicksand'] tracking-[0.15] leading-[12px] text-black/60"
                                id="Label"
                            >
                                Email
                            </div>
                            <div
                                className="text-xs font-['Quicksand'] tracking-[0.15] leading-[12px] text-black/60"
                                id="Label1"
                                style={{ paddingLeft: "282px" }}
                            >
                                Role
                            </div>
                        </div>
                        <div className="self-stretch flex flex-row justify-between items-start ml-3 mr-20">
                            <div
                                className="font-['Quicksand'] tracking-[0.15] leading-[24px] text-black/38"
                                id="email container"
                                style={{}}
                            >
                                <Input name="email" placeholder="email@domain.com" style={{ width: "300px", }} />
                            </div>
                            <div className="flex flex-row gap-4 items-start">
                                <div
                                    className="font-['Quicksand'] tracking-[0.15] leading-[24px] text-black/38"
                                    id="Placeholder1"
                                >
                                    <NativeSelect
                                        defaultValue={0}
                                        inputProps={{
                                            name: 'role',
                                            id: 'uncontrolled-native',
                                        }}
                                    >
                                        <option value={0}>Planner/Traveler</option>
                                        {/* <option value={0}>Viewer</option> */}
                                    </NativeSelect>
                                </div>

                            </div>
                        </div>
                        <div className="self-stretch flex flex-row gap-3 items-start mb-4 ml-3 mr-20">
                            {/* <div
                                className="border-solid w-3/5 h-px border-t border-b-0 border-black/42 border-x-0"
                                id="Underline"
                            />
                            <div
                                className="border-solid w-40 shrink-0 h-px border-t border-b-0 border-black/42 border-x-0"
                                id="Default1"
                            /> */}
                        </div>
                        <button
                            className="shadow-[0px_3px_1px_-2px_rgba(0,_0,_0,_0.2),_0px_2px_2px_0px_rgba(0,_0,_0,_0.14),_0px_1px_5px_0px_rgba(0,_0,_0,_0.12)] overflow-hidden bg-[#d81b60] flex flex-col ml-3 h-8 shrink-0 items-center py-1 rounded"
                            id="Button4"
                            style={{ cursor: "pointer" }}
                        >
                            <button
                                className="text-sm font-['Quicksand'] font-bold tracking-[0.4] leading-[24px] capitalize text-white mx-4"
                                id="Button3"
                                style={{ cursor: "pointer" }}
                                onClick={onSendInvitationClick}
                            >
                                Send invitation
                            </button>
                        </button>
                        <div className="flex flex-row gap-2 items-start ml-3 mr-[145px]" style={{ marginTop: "50px" }}>
                            <img
                                src="https://file.rendit.io/n/xvJCiwJcZYubDKilzpNF.svg"
                                className="w-6 shrink-0"
                                id="LightbulbFilled"
                            />
                            <div className="flex flex-col w-[359px] items-start">
                                <div className="text-xs font-['Quicksand'] tracking-[0.4] leading-[19.9px] text-[#424242]">
                                    Planner can make changes on the itinerary and invite others
                                </div>
                                <div className="text-xs font-['Quicksand'] tracking-[0.4] leading-[19.9px] text-[#424242]">
                                    Traveler can only make changes on the itinerary
                                </div>
                            </div>

                        </div>
                    </CustomTabPanel>
                    <CustomTabPanel value={value} index={1}>
                    <div className="flex flex-row gap-12 items-start mb-3  mt-3 ml-2" style={{width: "300px"}}>
                            <AddToCalendarButton
                                name="My Itinerary"
                                description={`Itinerary I created on TripDaddy! \n ${url}`}
                                size="7"

                                startDate= {`${new Date().getFullYear()}-${String(new Date().getMonth() + 1).padStart(2, '0')}-${String(new Date().getDate()).padStart(2, '0')}`}
                                options={['Apple', 'Google', 'iCal']}
                                
                            ></AddToCalendarButton>
                        </div>
                        <div className="flex flex-row gap-12 items-start mb-3 ml-4">
                            <TwitterShareButton url={url} title="Check out this itinerary I created on TripDaddy!">
                                <div className="border-solid border-[#bdbdbd] bg-white flex flex-row gap-2 w-56 h-12 items-center px-2 border rounded-lg">
                                    <img
                                        src="https://file.rendit.io/n/p3cLbD4byEryZVhhziR0.svg"
                                        className="w-8 shrink-0"
                                        id="Twitter"
                                    />
                                    <div className="text-sm font-['Quicksand'] font-bold tracking-[0.4] leading-[24px] capitalize">
                                        Share via Twitter
                                    </div>
                                </div>
                            </TwitterShareButton>
                        </div>
                        <div className="flex flex-row gap-12 items-start mb-3 ml-4">
                            <FacebookShareButton url={url} quote="Check out this itinerary I created on TripDaddy!">
                                <div className="border-solid border-[#bdbdbd] bg-white flex flex-row gap-2 w-56 h-12 items-center px-2 border rounded-lg">
                                    <img
                                        src="https://file.rendit.io/n/azzUxlQIaQuN4AyHnPQK.svg"
                                        className="w-8 shrink-0"
                                        id="Facebook"
                                    />
                                    <div className="text-sm font-['Quicksand'] font-bold tracking-[0.4] leading-[24px] capitalize">
                                        Share via Facebook
                                    </div>
                                </div>
                            </FacebookShareButton>
                        </div>
                        



                    </CustomTabPanel>
                </div>


            </div >


        </Modal >
    );
};

export default ShareModal;