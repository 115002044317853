import * as React from 'react';
import GooglePlacesAutocomplete from 'react-google-places-autocomplete';
import { useJsApiLoader } from '@react-google-maps/api';
import axios from 'axios';

const diff_degree = 4.5;
const required_libraries = ["places"];

const isSmallScreen = () => {
    return window.innerWidth < 768;
}


const SearchField = ({ placeholder, onChange, isLoaded, center_coords, setPlaceId }) => {
    const [value, setValue] = React.useState(null);


    const test = (value) => {
        setValue(value);
        console.log(value);
        setPlaceId(value.value.place_id);
        onChange(value.value.place_id);
    }

    return <div style={{
        width: isSmallScreen() ? "220px" : "400px",
    }}>
        {isLoaded && window.google && window.google.maps ? <GooglePlacesAutocomplete
            // apiKey={process.env.REACT_APP_MAPS_API_KEY}
            minLengthAutocomplete={3}
            selectProps={{
                value,
                onChange: (value) => {
                    // setValue(value);
                    test(value);
                    // onChange(value);
                },
                placeholder,

            }}
            autocompletionRequest={
                {
                    location: center_coords,
                    radius: 100 * 1000,
                    // types: ["establishment", "lodging"],
                }
            }
            onLoadFailed={(error) => {
                console.log(error);
                alert(error.message)
            }
            }

        /> : <div>Loading...</div>}

    </div>
}

const Overview = React.forwardRef(function ({ itinerary, trip_data, setTripData, showDetails, setShowDetails }, ref) {
    const { isLoaded } = useJsApiLoader({
        id: 'google-map-script',
        googleMapsApiKey: process.env.REACT_APP_MAPS_API_KEY,
        libraries: required_libraries
    });
    const [place_id, setPlaceId] = React.useState(null);

    const [airport_place_id, setAirportPlaceId] = React.useState(null);

    const toggleShowDetails = () => {
        setShowDetails(!showDetails);
    }

    const get_details = async (place_id) => {
        axios.post(process.env.REACT_APP_BACKEND_URL + '/api/map/get_details_from_place_id', { "place_id": place_id }, {
            headers: {
                'Content-Type': 'application/json',
            }
        }).then((response) => {

            // console.log(response.data);
            setTripData({ ...trip_data, staying_at: response.data })
        }
        ).catch((error) => {
            console.log(error);
            alert(error.message)
        });

    }

    const get_airport_details = async (place_id) => {
        axios.post(process.env.REACT_APP_BACKEND_URL + '/api/map/get_details_from_place_id', { "place_id": place_id }, {
            headers: {
                'Content-Type': 'application/json',
            }
        }).then((response) => {
            console.log(response.data);
            let airport_data = {
                candidates: [
                    response.data.place_details
                ],
                images: response.data.photos,
                status: "OK"

            }
            console.log(airport_data);
            setTripData({ ...trip_data, airport: airport_data })
        }
        ).catch((error) => {
            console.log(error);
            alert(error.message)
        });
    }



    const totalActivities = itinerary.reduce((acc, day) => {
        return acc + day.activities.length;
    }, 0);
    const no_of_days = itinerary.length;
    var center_coords;
    if (itinerary[0].activities[0].place_details && itinerary[0].activities[0].place_details.candidates && itinerary[0].activities[0].place_details.candidates.length > 0) {
        center_coords = itinerary[0].activities[0].place_details.candidates[0].geometry.location;
    } else {
        center_coords = {
            lat: 0,
            lng: 0,
        };
    }


    const onHotelChange = (place_id) => {
        // setTripData({ ...trip_data, staying_at: e.target.value })
        console.log(place_id)
        get_details(place_id);
    }

    const onAirportChange = (place_id) => {
        // setTripData({ ...trip_data, staying_at: e.target.value })
        // alert(place_id)
        get_airport_details(place_id);
    }


    if (isSmallScreen()) {

        return <div id='overview-container' ref={ref} style={{
            top: "660px",
            left: "0",
            position: "absolute",
            width: "100%",
            zIndex: "10", fontFamily: "Quicksand",
        }} className="rounded-xl bg-tripdaddy-amber-50 flex flex-col items-start justify-start p-4 gap-[16px] text-[32px] font-caveat">
            <div style={{
                fontFamily: "Caveat",

            }} className="relative w-[149px] h-8">
                <b style={{
                    color: "rgba(0, 0, 0, 0.87)",
                    fontFamily: "Caveat",
                    fontSize: "32px",
                    fontStyle: "normal",
                    fontWeight: "700",
                    lineHeight: "120%",
                    letterSpacing: "-1.5px"
                }} className="absolute top-[0px] left-[0px] tracking-[-1.5px] leading-[120%]">
                    An overview:
                </b>
            </div>
            <div className="flex flex-col items-start justify-start gap-[16px] text-base font-typography-body2">
                <div style={{
                    whiteSpace: "pre-wrap",
                }} className="flex flex-col items-start justify-start gap-[8px]">
                    <div className="relative text-xs tracking-[0.4px] leading-[166%] inline-block w-[313px]">
                        <p>
                            {trip_data.overview ? trip_data.overview : "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged."}
                            {trip_data.detailed_overview && !showDetails ?
                                <span style={{
                                    color: "#D81B60",
                                    cursor: "pointer",
                                }} onClick={toggleShowDetails}>  ...more</span>
                                : <></>}
                        </p>
                        <p>
                            {trip_data.detailed_overview && showDetails ?
                                trip_data.detailed_overview

                                :
                                ""
                            }
                            {

                                trip_data.detailed_overview && showDetails ?
                                    <span style={{
                                        color: "#D81B60",
                                        cursor: "pointer",
                                    }} onClick={toggleShowDetails}>  ...less</span>
                                    :
                                    <></>
                            }
                        </p>
                    </div>
                    <div className="flex flex-col items-start justify-start gap-[8px]">
                        <div className="flex flex-col items-start justify-center gap-[8px]">
                            <div className="flex flex-row items-center justify-start gap-[8px]">
                                <svg xmlns="http://www.w3.org/2000/svg" width="17" height="16" viewBox="0 0 17 16" fill="none">
                                    <path d="M13.834 7.99996C13.834 7.26663 14.434 6.66663 15.1673 6.66663V3.99996C15.1673 3.26663 14.5673 2.66663 13.834 2.66663H3.16732C2.43398 2.66663 1.84065 3.26663 1.84065 3.99996V6.66663C2.57398 6.66663 3.16732 7.26663 3.16732 7.99996C3.16732 8.73329 2.57398 9.33329 1.83398 9.33329V12C1.83398 12.7333 2.43398 13.3333 3.16732 13.3333H13.834C14.5673 13.3333 15.1673 12.7333 15.1673 12V9.33329C14.434 9.33329 13.834 8.73329 13.834 7.99996ZM10.8873 11.2L8.50065 9.66663L6.11398 11.2L6.83398 8.45329L4.64065 6.65996L7.46732 6.49329L8.50065 3.86663L9.52732 6.49996L12.354 6.66663L10.1607 8.45996L10.8873 11.2Z" fill="#424242" />
                                </svg>
                                <div className="relative tracking-[0.15px] leading-[150%] font-semibold">
                                    Activities:
                                </div>
                            </div>
                            <div className="flex flex-row items-center justify-start">
                                <div className="relative tracking-[0.15px] leading-[150%]">
                                    {totalActivities}  (Changeable to your liking)
                                </div>
                            </div>
                        </div>
                        <div className="flex flex-col items-start justify-center gap-[8px]">
                            <div className="flex flex-row items-center justify-start gap-[8px]">
                                <svg xmlns="http://www.w3.org/2000/svg" width="17" height="16" viewBox="0 0 17 16" fill="none">
                                    <path d="M13.834 1.99996H13.1673V0.666626H11.834V1.99996H5.16732V0.666626H3.83398V1.99996H3.16732C2.43398 1.99996 1.83398 2.59996 1.83398 3.33329V14C1.83398 14.7333 2.43398 15.3333 3.16732 15.3333H13.834C14.5673 15.3333 15.1673 14.7333 15.1673 14V3.33329C15.1673 2.59996 14.5673 1.99996 13.834 1.99996ZM13.834 14H3.16732V5.33329H13.834V14Z" fill="#424242" />
                                </svg>
                                <div className="relative tracking-[0.15px] leading-[150%] font-semibold">
                                    Duration:
                                </div>
                            </div>
                            <div className="flex flex-row items-center justify-start">
                                <div className="relative tracking-[0.15px] leading-[150%]">
                                    {no_of_days} days
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="flex flex-col items-start justify-center gap-[8px]">
                        <div className="flex flex-row items-center justify-center gap-[8px]">
                            <svg xmlns="http://www.w3.org/2000/svg" width="17" height="16" viewBox="0 0 17 16" fill="none">
                                <path d="M10.5 7.33337V3.33337L8.5 1.33337L6.5 3.33337V4.66671H2.5V14H14.5V7.33337H10.5ZM5.16667 12.6667H3.83333V11.3334H5.16667V12.6667ZM5.16667 10H3.83333V8.66671H5.16667V10ZM5.16667 7.33337H3.83333V6.00004H5.16667V7.33337ZM9.16667 12.6667H7.83333V11.3334H9.16667V12.6667ZM9.16667 10H7.83333V8.66671H9.16667V10ZM9.16667 7.33337H7.83333V6.00004H9.16667V7.33337ZM9.16667 4.66671H7.83333V3.33337H9.16667V4.66671ZM13.1667 12.6667H11.8333V11.3334H13.1667V12.6667ZM13.1667 10H11.8333V8.66671H13.1667V10Z" fill="#424242" />
                            </svg>
                            <div className="relative tracking-[0.15px] leading-[150%] font-semibold">
                                Staying at:

                            </div>
                            {
                                trip_data.staying_at ?
                                    <div className="flex flex-row items-start justify-start text-secondary-main">
                                        <div onClick={() => {
                                            window.open("https://www.google.com/maps/search/?api=1&query=" + trip_data.staying_at.place_details.name + "&query_place_id=" + trip_data.staying_at.place_details.place_id, "_blank");
                                        }} className="flex flex-row items-center justify-start gap-[8px]">
                                            <div className="relative tracking-[0.15px] leading-[150%]">
                                                {trip_data.staying_at.place_details.name}
                                            </div>
                                            <svg xmlns="http://www.w3.org/2000/svg" width="17" height="16" viewBox="0 0 17 16" fill="none">
                                                <path d="M8.49935 1.33337C10.706 1.33337 12.4993 3.10671 12.4993 5.30004C12.4993 8.27337 8.49935 12.6667 8.49935 12.6667C8.49935 12.6667 4.49935 8.27337 4.49935 5.30004C4.49935 3.10671 6.29268 1.33337 8.49935 1.33337ZM8.49935 4.00004C8.14573 4.00004 7.80659 4.14052 7.55654 4.39056C7.30649 4.64061 7.16602 4.97975 7.16602 5.33337C7.16602 5.687 7.30649 6.02613 7.55654 6.27618C7.80659 6.52623 8.14573 6.66671 8.49935 6.66671C8.85297 6.66671 9.19211 6.52623 9.44216 6.27618C9.69221 6.02613 9.83268 5.687 9.83268 5.33337C9.83268 4.97975 9.69221 4.64061 9.44216 4.39056C9.19211 4.14052 8.85297 4.00004 8.49935 4.00004ZM13.8327 12.6667C13.8327 14.14 11.446 15.3334 8.49935 15.3334C5.55268 15.3334 3.16602 14.14 3.16602 12.6667C3.16602 11.8067 3.97935 11.04 5.23935 10.5534L5.66602 11.16C4.94602 11.46 4.49935 11.8734 4.49935 12.3334C4.49935 13.2534 6.29268 14 8.49935 14C10.706 14 12.4993 13.2534 12.4993 12.3334C12.4993 11.8734 12.0527 11.46 11.3327 11.16L11.7593 10.5534C13.0193 11.04 13.8327 11.8067 13.8327 12.6667Z" fill="#D81B60" />
                                            </svg>
                                        </div>
                                    </div>
                                    :
                                    <SearchField placeholder="Enter your hotel ..." onChange={onHotelChange} isLoaded={isLoaded} center_coords={center_coords} setPlaceId={setPlaceId} />
                            }
                        </div>


                    </div>

                    <div className="flex flex-row items-center justify-center gap-[8px]">
                        <div className="flex flex-row items-center justify-center gap-[8px]">
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path fill="#424242" d="M2.5 19h19v2h-19zm7.18-5.73l4.35 1.16l5.31 1.42c.8.21 1.62-.26 1.84-1.06c.21-.79-.26-1.62-1.06-1.84l-5.31-1.42l-2.76-9.03l-1.93-.5v8.28L5.15 8.95l-.93-2.32l-1.45-.39v5.17l1.6.43z" /></svg>
                        
                            <b className="relative tracking-[0.15px] leading-[160%]">
                                Arriving at:
                            </b>
                        </div>

                        {
                            trip_data.airport ? <div className="flex flex-row items-start justify-start text-base text-secondary-main">
                                <div className="flex flex-row items-center justify-start gap-[8px]">
                                    <div style={{
                                        cursor: "pointer",
                                    }} onClick={() => {
                                        window.open("https://www.google.com/maps/search/?api=1&query=" + trip_data.airport.candidates[0].name + "&query_place_id=" + trip_data.airport.candidates[0].place_id, "_blank");
                                    }} className="relative tracking-[0.15px] leading-[150%]">
                                        {trip_data.airport.candidates[0].name}
                                    </div>
                                </div>
                            </div>
                                :
                                // <input className="relative tracking-[0.15px] leading-[150%] text-base text-secondary-main" type="text" placeholder="Enter your hotel name" onChange={onHotelChange} />
                                <SearchField placeholder="Enter your airport ..." onChange={onAirportChange} isLoaded={isLoaded} center_coords={center_coords} setPlaceId={setAirportPlaceId} />
                        }
                    </div>

                </div>
                <div className="flex flex-row items-start justify-start text-5xl text-primary-main font-caveat">
                    <div className="flex flex-col items-start justify-start gap-[16px]">
                        {itinerary.map((day, index) => {


                            return <div className="rounded-borderradius flex flex-col items-start justify-start gap-[8px]">
                                <b className="relative tracking-[-1.5px] leading-[120%]">
                                    Day {index + 1}:
                                </b>
                                <div style={{
                                    fontFamily: "Quicksand",
                                }} className="flex flex-row items-center justify-center gap-[8px] text-xs text-text-primary font-typography-body2">
                                    <b className="relative tracking-[0.4px] leading-[166%]">
                                        {day.theme}
                                    </b>
                                    <div className="relative tracking-[0.4px] leading-[166%]">
                                        |
                                    </div>
                                    <div className="relative tracking-[0.4px] leading-[166%]">
                                        {day.activities.length} Activities
                                    </div>
                                </div>
                            </div>
                        })}


                    </div>
                </div>
            </div>
        </div>;

    }


    return <div id='overview-container' ref={ref} style={{
        zIndex: 10,
        height: "auto"

    }} className="absolute top-[700px]  rounded-xl bg-tripdaddy-amber-50 flex flex-col items-start justify-start p-4 gap-[16px] text-[36px] font-caveat">
        <div className="relative w-[149px] h-8">
            <b style={{
                color: "rgba(0, 0, 0, 0.87)",
                fontFamily: "Caveat",
                fontSize: "32px",
                fontStyle: "normal",
                fontWeight: "700",
                lineHeight: "120%",
                letterSpacing: "-1.5px"
            }} className="absolute top-[0px] left-[0px] tracking-[-1.5px] leading-[120%]">
                An overview:
            </b>
        </div>

        <div className="flex flex-row items-start justify-start gap-[16px] text-xl font-typography-h6">
            <div style={{
                maxWidth: "50%",
                whiteSpace: "pre-wrap",
            }} className="flex flex-col items-start justify-start gap-[16px]">
                <div className="relative text-base tracking-[0.15px] leading-[150%] inline-block ">
                    <p>
                        {trip_data.overview ? trip_data.overview : "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged."}
                        {trip_data.detailed_overview && !showDetails ?
                            <span style={{
                                color: "#D81B60",
                                cursor: "pointer",
                            }} onClick={toggleShowDetails}>  ...more</span>
                            : <></>}
                    </p>
                    <p>
                        {trip_data.detailed_overview && showDetails ?
                            trip_data.detailed_overview

                            :
                            ""
                        }
                        {

                            trip_data.detailed_overview && showDetails ?
                                <span style={{
                                    color: "#D81B60",
                                    cursor: "pointer",
                                }} onClick={toggleShowDetails}>  ...less</span>
                                :
                                <></>
                        }
                    </p>
                </div>
                <div className="flex flex-row items-start justify-start gap-[24px]">
                    <div className="flex flex-row items-center justify-start gap-[8px]">
                        <div className="flex flex-row items-center justify-start gap-[8px]">
                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                <path d="M20 12C20 10.9 20.9 10 22 10V6C22 4.9 21.1 4 20 4H4C2.9 4 2.01 4.9 2.01 6V10C3.11 10 4 10.9 4 12C4 13.1 3.11 14 2 14V18C2 19.1 2.9 20 4 20H20C21.1 20 22 19.1 22 18V14C20.9 14 20 13.1 20 12ZM15.58 16.8L12 14.5L8.42 16.8L9.5 12.68L6.21 9.99L10.45 9.74L12 5.8L13.54 9.75L17.78 10L14.49 12.69L15.58 16.8Z" fill="#424242" />
                            </svg>
                            <b className="relative tracking-[0.15px] leading-[160%]">
                                Activities:
                            </b>
                        </div>
                        <div className="flex flex-row items-center justify-start text-base">
                            <div className="relative tracking-[0.15px] leading-[150%]">
                                {totalActivities} (Changeable to your liking)
                            </div>
                        </div>
                    </div>
                    <div className="flex flex-row items-center justify-center gap-[8px]">
                        <div className="flex flex-row items-center justify-start gap-[8px]">
                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                <path d="M20 3H19V1H17V3H7V1H5V3H4C2.9 3 2 3.9 2 5V21C2 22.1 2.9 23 4 23H20C21.1 23 22 22.1 22 21V5C22 3.9 21.1 3 20 3ZM20 21H4V8H20V21Z" fill="#424242" />
                            </svg>
                            <b className="relative tracking-[0.15px] leading-[160%]">
                                Duration:
                            </b>
                        </div>
                        <div className="flex flex-row items-center justify-start text-base">
                            <div className="relative tracking-[0.15px] leading-[150%]">
                                {no_of_days} days
                            </div>
                        </div>
                    </div>
                </div>
                <div className="flex flex-row items-center justify-center gap-[8px]">
                    <div className="flex flex-row items-center justify-center gap-[8px]">
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                            <path d="M15 11V5L12 2L9 5V7H3V21H21V11H15ZM7 19H5V17H7V19ZM7 15H5V13H7V15ZM7 11H5V9H7V11ZM13 19H11V17H13V19ZM13 15H11V13H13V15ZM13 11H11V9H13V11ZM13 7H11V5H13V7ZM19 19H17V17H19V19ZM19 15H17V13H19V15Z" fill="#424242" />
                        </svg>
                        <b className="relative tracking-[0.15px] leading-[160%]">
                            Staying at:
                        </b>
                    </div>

                    {
                        trip_data.staying_at ? <div className="flex flex-row items-start justify-start text-base text-secondary-main">
                            <div className="flex flex-row items-center justify-start gap-[8px]">
                                <div style={{
                                    cursor: "pointer",
                                }} onClick={() => {
                                    window.open("https://www.google.com/maps/search/?api=1&query=" + trip_data.staying_at.place_details.name + "&query_place_id=" + trip_data.staying_at.place_details.place_id, "_blank");
                                }} className="relative tracking-[0.15px] leading-[150%]">
                                    {trip_data.staying_at.place_details.name}
                                </div>
                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                    <path d="M12 2C15.31 2 18 4.66 18 7.95C18 12.41 12 19 12 19C12 19 6 12.41 6 7.95C6 4.66 8.69 2 12 2ZM12 6C11.4696 6 10.9609 6.21071 10.5858 6.58579C10.2107 6.96086 10 7.46957 10 8C10 8.53043 10.2107 9.03914 10.5858 9.41421C10.9609 9.78929 11.4696 10 12 10C12.5304 10 13.0391 9.78929 13.4142 9.41421C13.7893 9.03914 14 8.53043 14 8C14 7.46957 13.7893 6.96086 13.4142 6.58579C13.0391 6.21071 12.5304 6 12 6ZM20 19C20 21.21 16.42 23 12 23C7.58 23 4 21.21 4 19C4 17.71 5.22 16.56 7.11 15.83L7.75 16.74C6.67 17.19 6 17.81 6 18.5C6 19.88 8.69 21 12 21C15.31 21 18 19.88 18 18.5C18 17.81 17.33 17.19 16.25 16.74L16.89 15.83C18.78 16.56 20 17.71 20 19Z" fill="#D81B60" />
                                </svg>
                            </div>
                        </div>
                            :
                            // <input className="relative tracking-[0.15px] leading-[150%] text-base text-secondary-main" type="text" placeholder="Enter your hotel name" onChange={onHotelChange} />
                            <SearchField placeholder="Enter your hotel ..." onChange={onHotelChange} isLoaded={isLoaded} center_coords={center_coords} setPlaceId={setPlaceId} />
                    }
                </div>
                <div className="flex flex-row items-center justify-center gap-[8px]">
                    <div className="flex flex-row items-center justify-center gap-[8px]">
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path fill="#424242" d="M2.5 19h19v2h-19zm7.18-5.73l4.35 1.16l5.31 1.42c.8.21 1.62-.26 1.84-1.06c.21-.79-.26-1.62-1.06-1.84l-5.31-1.42l-2.76-9.03l-1.93-.5v8.28L5.15 8.95l-.93-2.32l-1.45-.39v5.17l1.6.43z" /></svg>
                        <b className="relative tracking-[0.15px] leading-[160%]">
                            Arriving at:
                        </b>
                    </div>

                    {
                        trip_data.airport ? <div className="flex flex-row items-start justify-start text-base text-secondary-main">
                            <div className="flex flex-row items-center justify-start gap-[8px]">
                                <div style={{
                                    cursor: "pointer",
                                }} onClick={() => {
                                    window.open("https://www.google.com/maps/search/?api=1&query=" + trip_data.airport.candidates[0].name + "&query_place_id=" + trip_data.airport.candidates[0].place_id, "_blank");
                                }} className="relative tracking-[0.15px] leading-[150%]">
                                    {trip_data.airport.candidates[0].name}
                                </div>
                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                    <path d="M12 2C15.31 2 18 4.66 18 7.95C18 12.41 12 19 12 19C12 19 6 12.41 6 7.95C6 4.66 8.69 2 12 2ZM12 6C11.4696 6 10.9609 6.21071 10.5858 6.58579C10.2107 6.96086 10 7.46957 10 8C10 8.53043 10.2107 9.03914 10.5858 9.41421C10.9609 9.78929 11.4696 10 12 10C12.5304 10 13.0391 9.78929 13.4142 9.41421C13.7893 9.03914 14 8.53043 14 8C14 7.46957 13.7893 6.96086 13.4142 6.58579C13.0391 6.21071 12.5304 6 12 6ZM20 19C20 21.21 16.42 23 12 23C7.58 23 4 21.21 4 19C4 17.71 5.22 16.56 7.11 15.83L7.75 16.74C6.67 17.19 6 17.81 6 18.5C6 19.88 8.69 21 12 21C15.31 21 18 19.88 18 18.5C18 17.81 17.33 17.19 16.25 16.74L16.89 15.83C18.78 16.56 20 17.71 20 19Z" fill="#D81B60" />
                                </svg>
                            </div>
                        </div>
                            :
                            // <input className="relative tracking-[0.15px] leading-[150%] text-base text-secondary-main" type="text" placeholder="Enter your hotel name" onChange={onHotelChange} />
                            <SearchField placeholder="Enter your airport ..." onChange={onAirportChange} isLoaded={isLoaded} center_coords={center_coords} setPlaceId={setAirportPlaceId} />
                    }
                </div>
            </div>
            <div style={{
                flexDirection: "column",
            }} className="flex flex-row items-start justify-start text-5xl text-primary-main font-caveat">
                {itinerary.map((day, index) => {


                    return <div className="flex flex-col items-start justify-start gap-[16px]">
                        <div className="rounded-borderradius flex flex-row items-start justify-start gap-[8px]">
                            <b className="relative tracking-[-1.5px] leading-[120%]">
                                Day {index + 1}:
                            </b>
                            <div className="flex flex-row items-center justify-center gap-[8px] text-base text-text-primary font-typography-h6">
                                <b style={{ fontSize: "18px" }} className="relative  tracking-[0.15px] leading-[160%]">
                                    {day.theme}
                                </b>
                                <div className="relative tracking-[0.15px] leading-[150%]">
                                    |
                                </div>
                                <div className="relative tracking-[0.15px] leading-[150%]">
                                    {day.activities.length} Activities
                                </div>
                            </div>
                        </div>

                    </div>
                })}
            </div>
        </div>
    </div>;
})

export default Overview;