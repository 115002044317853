import * as React from "react";
import SelectInput from "../pages/mobile/components/SelectInput";
import axios from "axios";

function initMap(origin, destination) {
    const directionsRenderer = new google.maps.DirectionsRenderer();
    const directionsService = new google.maps.DirectionsService();
    const map = new google.maps.Map(document.getElementById("directions-map"), {
        zoom: 14,
        center: origin,
    });

    directionsRenderer.setMap(map);
    calculateAndDisplayRoute(directionsService, directionsRenderer, origin, destination);
    // document.getElementById("mode").addEventListener("change", () => {
    //   calculateAndDisplayRoute(directionsService, directionsRenderer);
    // });
}

function calculateAndDisplayRoute(directionsService, directionsRenderer, origin, destination) {
    const selectedMode = "DRIVING";

    directionsService
        .route({
            origin: origin,
            destination: destination,
            // Note that Javascript allows us to access the constant
            // using square brackets and a string value as its
            // "property."
            travelMode: google.maps.TravelMode[selectedMode],
        })
        .then((response) => {
            directionsRenderer.setDirections(response);
        })
        .catch((e) => window.alert("Directions request failed due to " + status));
}

window.initMap = initMap;

export default function TransportInfoModal({ open, data, handleClose }) {
    const [origin, setOrigin] = React.useState(data.from);
    const [destination, setDestination] = React.useState(data.to);
    const [walking, setWalking] = React.useState(null);
    const [driving, setDriving] = React.useState(null);
    const [transit, setTransit] = React.useState(null);
    const [directionLink, setDirectionLink] = React.useState(null);

    const origincoord = data.locations.filter((location) => location.poi === origin)[0].location.geometry.location;
    const destinationcoord = data.locations.filter((location) => location.poi === destination)[0].location.geometry.location;
    // console.log(origincoord);
    const getDirectionLink = () => {
        const link = "https://www.google.com/maps/dir/?api=1&origin=" +origin +" ," + data.locations.filter((location) => location.poi === origin)[0].address + "&destination=" +destination + ", "+ data.locations.filter((location) => location.poi === destination)[0].address + "&travelmode=driving";
        setDirectionLink(link);
    }

    React.useEffect(() => {
        initMap(origincoord, destinationcoord);
        get_distances();
        getDirectionLink();
    }
        , [origin, destination]);

    const get_distances = async () => {
        axios.post(process.env.REACT_APP_BACKEND_URL + "/api/distance", {
            origins: [origin + ", " + data.locations.filter((location) => location.poi === origin)[0].location.formatted_address],
            destinations: [destination + ", " + data.locations.filter((location) => location.poi === destination)[0].location.formatted_address],
            mode: "driving"
        }).then((response) => {
            // console.log(response.data.rows[0].elements[0]);
            setDriving(response.data.rows[0].elements[0]);
        })
        axios.post(process.env.REACT_APP_BACKEND_URL + "/api/distance", {
            origins: [origin + ", " + data.locations.filter((location) => location.poi === origin)[0].location.formatted_address],
            destinations: [destination + ", " + data.locations.filter((location) => location.poi === destination)[0].location.formatted_address],
            mode: "walking"
        }).then((response) => {
            // console.log(response.data.rows[0].elements[0]);
            setWalking(response.data.rows[0].elements[0]);
        })
        axios.post(process.env.REACT_APP_BACKEND_URL + "/api/distance", {
            origins: [origin + ", " + data.locations.filter((location) => location.poi === origin)[0].location.formatted_address],
            destinations: [destination + ", " + data.locations.filter((location) => location.poi === destination)[0].location.formatted_address],
            mode: "transit"
        }).then((response) => {
            // console.log(response.data.rows[0].elements[0]);
            setTransit(response.data.rows[0].elements[0]);
        })
    }

    return (<div style={{ width: "591px" }} className="shadow-[0px_7px_9px_-4px_rgba(0,_0,_0,_0.2),_0px_14px_21px_2px_rgba(0,_0,_0,_0.14),_0px_5px_26px_4px_rgba(0,_0,_0,_0.12)] overflow-hidden bg-white flex flex-row justify-center gap-8 w-full items-center rounded-lg">
        <div style={{ height: "425px" }} className="flex flex-col gap-10 w-1/2 items-start">
            <b style={{ fontSize: "24px" }} class="relative tracking-[0.15px] leading-[160%]">Transport information</b>
            <div
                style={{ height: "300px" }}
                id="directions-map"
                className=" bg-50%_50% bg-blend-normal bg-no-repeat self-stretch relative flex flex-col justify-end ml-4 items-end pt-56 pb-20 px-24"
            >
            </div>
        </div>
        <div className="flex flex-col gap-4 w-2/5 h-[436px] my-6">
            <div
                onClick={handleClose}
                style={{ cursor: "pointer" }}
                id="Fab"
                className="shadow-[0px_2px_1px_-1px_rgba(0,_0,_0,_0.2),_0px_1px_1px_0px_rgba(0,_0,_0,_0.14),_0px_1px_3px_0px_rgba(0,_0,_0,_0.12)] overflow-hidden bg-[#fafafa] self-end flex flex-col justify-center mb-2 w-12 h-12 shrink-0 items-center rounded-[100px]"
            >
                <img
                    src="https://file.rendit.io/n/C2rHfrU4Wn5kzPNZqAJ5.svg"
                    id="CloseFilled"
                    className="w-6"
                />
            </div>
            <div className="flex flex-row mr-4 gap-2 items-center">
                <SelectInput label={"From"} defaultValue={data.from} options={data.locations} onChange={(value) => {
                    setOrigin(value);
                }} />
            </div>
            <div className="flex flex-row gap-2 items-center mb-6 mr-4">
                <SelectInput label={"To"} defaultValue={data.to} options={data.locations} onChange={(value) => {
                    setDestination(value);
                }} />

            </div>
            <div className="text-secondary-main overflow-hidden flex flex-row p-2 items-center justify-start gap-[32px] border-[1px] border-solid border-tripdaddy-grey-400">
                <div className="flex flex-row items-center justify-start">
                    <svg xmlns="http://www.w3.org/2000/svg" width="17" height="16" viewBox="0 0 17 16" fill="none">
                        <path d="M3.83333 7.33325L4.83333 4.33325H12.1667L13.1667 7.33325M12.1667 10.6666C11.9015 10.6666 11.6471 10.5612 11.4596 10.3737C11.272 10.1862 11.1667 9.9318 11.1667 9.66658C11.1667 9.40137 11.272 9.14701 11.4596 8.95948C11.6471 8.77194 11.9015 8.66658 12.1667 8.66658C12.4319 8.66658 12.6862 8.77194 12.8738 8.95948C13.0613 9.14701 13.1667 9.40137 13.1667 9.66658C13.1667 9.9318 13.0613 10.1862 12.8738 10.3737C12.6862 10.5612 12.4319 10.6666 12.1667 10.6666ZM4.83333 10.6666C4.56812 10.6666 4.31376 10.5612 4.12623 10.3737C3.93869 10.1862 3.83333 9.9318 3.83333 9.66658C3.83333 9.40137 3.93869 9.14701 4.12623 8.95948C4.31376 8.77194 4.56812 8.66658 4.83333 8.66658C5.09855 8.66658 5.3529 8.77194 5.54044 8.95948C5.72798 9.14701 5.83333 9.40137 5.83333 9.66658C5.83333 9.9318 5.72798 10.1862 5.54044 10.3737C5.3529 10.5612 5.09855 10.6666 4.83333 10.6666ZM13.1133 3.99992C12.98 3.61325 12.6067 3.33325 12.1667 3.33325H4.83333C4.39333 3.33325 4.02 3.61325 3.88667 3.99992L2.5 7.99992V13.3333C2.5 13.5101 2.57024 13.6796 2.69526 13.8047C2.82029 13.9297 2.98986 13.9999 3.16667 13.9999H3.83333C4.01014 13.9999 4.17971 13.9297 4.30474 13.8047C4.42976 13.6796 4.5 13.5101 4.5 13.3333V12.6666H12.5V13.3333C12.5 13.5101 12.5702 13.6796 12.6953 13.8047C12.8203 13.9297 12.9899 13.9999 13.1667 13.9999H13.8333C14.0101 13.9999 14.1797 13.9297 14.3047 13.8047C14.4298 13.6796 14.5 13.5101 14.5 13.3333V7.99992L13.1133 3.99992Z" fill="#424242" />
                    </svg>
                    <div className="relative tracking-[0.4px] leading-[166%]">
                        {driving && driving.distance  ? driving.distance.text : "Loading..."}
                    </div>
                </div>
                <div className="flex flex-row items-center justify-start">
                    <svg xmlns="http://www.w3.org/2000/svg" width="17" height="16" viewBox="0 0 17 16" fill="none">
                        <path d="M12.5 14.6666L12.4933 10.6666L9.83333 7.99992L12.4933 5.32659L12.5 1.33325H4.5V5.33325L7.16667 7.99992L4.5 10.6599V14.6666H12.5ZM5.83333 4.99992V2.66659H11.1667V4.99992L8.5 7.66659L5.83333 4.99992Z" fill="#424242" />
                    </svg>
                    <div className="relative tracking-[0.4px] leading-[166%]">
                        {driving && driving.duration ? driving.duration.text : "Loading..."}
                    </div>
                </div>
            </div>
            <div className="text-secondary-main overflow-hidden flex flex-row p-2 items-center justify-start gap-[32px] border-[1px] border-solid border-tripdaddy-grey-400">
                <div className="flex flex-row items-center justify-start">
                    <svg xmlns="http://www.w3.org/2000/svg" width="17" height="16" viewBox="0 0 17 16" fill="none">
                        <path d="M12.5003 7.33325H9.16699V3.99992H12.5003M11.5003 11.3333C11.2351 11.3333 10.9808 11.2279 10.7932 11.0404C10.6057 10.8528 10.5003 10.5985 10.5003 10.3333C10.5003 10.068 10.6057 9.81368 10.7932 9.62615C10.9808 9.43861 11.2351 9.33325 11.5003 9.33325C11.7655 9.33325 12.0199 9.43861 12.2074 9.62615C12.395 9.81368 12.5003 10.068 12.5003 10.3333C12.5003 10.5985 12.395 10.8528 12.2074 11.0404C12.0199 11.2279 11.7655 11.3333 11.5003 11.3333ZM7.83366 7.33325H4.50033V3.99992H7.83366M5.50033 11.3333C5.23511 11.3333 4.98076 11.2279 4.79322 11.0404C4.60568 10.8528 4.50033 10.5985 4.50033 10.3333C4.50033 10.068 4.60568 9.81368 4.79322 9.62615C4.98076 9.43861 5.23511 9.33325 5.50033 9.33325C5.76554 9.33325 6.0199 9.43861 6.20743 9.62615C6.39497 9.81368 6.50033 10.068 6.50033 10.3333C6.50033 10.5985 6.39497 10.8528 6.20743 11.0404C6.0199 11.2279 5.76554 11.3333 5.50033 11.3333ZM8.50033 1.33325C5.55366 1.33325 3.16699 1.66659 3.16699 3.99992V10.3333C3.16699 10.9521 3.41282 11.5456 3.85041 11.9832C4.28799 12.4208 4.88149 12.6666 5.50033 12.6666L4.50033 13.6666V13.9999H12.5003V13.6666L11.5003 12.6666C12.1192 12.6666 12.7127 12.4208 13.1502 11.9832C13.5878 11.5456 13.8337 10.9521 13.8337 10.3333V3.99992C13.8337 1.66659 11.447 1.33325 8.50033 1.33325Z" fill="#424242" />
                    </svg>
                    <div className="relative tracking-[0.4px] leading-[166%]">
                        {transit && transit.distance ? transit.distance.text : "Loading..."}
                    </div>
                </div>
                <div className="flex flex-row items-center justify-start">
                    <svg xmlns="http://www.w3.org/2000/svg" width="17" height="16" viewBox="0 0 17 16" fill="none">
                        <path d="M12.5 14.6666L12.4933 10.6666L9.83333 7.99992L12.4933 5.32659L12.5 1.33325H4.5V5.33325L7.16667 7.99992L4.5 10.6599V14.6666H12.5ZM5.83333 4.99992V2.66659H11.1667V4.99992L8.5 7.66659L5.83333 4.99992Z" fill="#424242" />
                    </svg>
                    <div className="relative tracking-[0.4px] leading-[166%]">
                        {transit && transit.duration ? transit.duration.text : "Loading..."}
                    </div>
                </div>
            </div>
            <div style={{ width: "220px" }} className="text-secondary-main overflow-hidden flex flex-row p-2 items-center justify-start gap-[32px] border-[1px] border-solid border-tripdaddy-grey-400">
                <div className="flex flex-row items-center justify-start">
                    <svg xmlns="http://www.w3.org/2000/svg" width="17" height="16" viewBox="0 0 17 16" fill="none">
                        <path d="M9.91333 6.66663H13.1667V5.46663H10.7533L9.42 3.24663C9.22 2.9133 8.86 2.68663 8.44667 2.68663C8.32667 2.68663 8.22 2.70663 8.11333 2.73997L4.5 3.86663V7.3333H5.7V4.88663L7.10667 4.44663L4.5 14.6666H5.7L7.61333 9.25997L9.16667 11.3333V14.6666H10.3667V10.3933L8.70667 7.36663L9.19333 5.4533M9.83333 2.5333C10.5 2.5333 11.0333 1.99997 11.0333 1.3333C11.0333 0.666634 10.5 0.133301 9.83333 0.133301C9.16667 0.133301 8.63333 0.666634 8.63333 1.3333C8.63333 1.99997 9.16667 2.5333 9.83333 2.5333Z" fill="#424242" />
                    </svg>
                    <div className="relative tracking-[0.4px] leading-[166%]">
                        {walking && walking.distance ? walking.distance.text : "Loading..."}
                    </div>
                </div>
                <div className="flex flex-row items-center justify-start">
                    <svg xmlns="http://www.w3.org/2000/svg" width="17" height="16" viewBox="0 0 17 16" fill="none">
                        <path d="M12.5 14.6666L12.4933 10.6666L9.83333 7.99992L12.4933 5.32659L12.5 1.33325H4.5V5.33325L7.16667 7.99992L4.5 10.6599V14.6666H12.5ZM5.83333 4.99992V2.66659H11.1667V4.99992L8.5 7.66659L5.83333 4.99992Z" fill="#424242" />
                    </svg>
                    <div className="relative tracking-[0.4px] leading-[166%]">
                        {walking && walking.duration ? walking.duration.text : "Loading..."}
                    </div>
                </div>
            </div>
            <div style={{ cursor: "pointer" }} onClick={() => {
                window.open(directionLink, "_blank");
            }} className="flex flex-row items-center justify-center gap-[8px] text-secondary-main">
                <img
                    className="relative w-4 h-[18px] hidden"
                    alt=""
                    src="/masked-icon26.svg"
                />
                <b className="relative tracking-[0.46px] leading-[22px] capitalize">
                    See directions
                </b>
                <svg xmlns="http://www.w3.org/2000/svg" width="19" height="18" viewBox="0 0 19 18" fill="none">
                    <path d="M16.7827 8.46742L10.0327 1.71742C9.74023 1.42492 9.26774 1.42492 8.97524 1.71742L2.22523 8.46742C1.93273 8.75992 1.93273 9.23242 2.22523 9.52492L8.97524 16.2749C9.26774 16.5674 9.74023 16.5674 10.0327 16.2749L16.7827 9.52492C17.0752 9.23992 17.0752 8.76742 16.7827 8.46742ZM11.0002 10.8749V8.99992H8.00023V11.2499H6.50023V8.24992C6.50023 7.83742 6.83773 7.49992 7.25023 7.49992H11.0002V5.62492L13.6252 8.24992L11.0002 10.8749Z" fill="#0057B2" />
                </svg>
            </div>
        </div>
    </div>
    );


}