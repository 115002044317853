import React, { useState, useEffect } from 'react';

const ImageComponent = ({ images, classes, style, onClick, index, length }) => {

    const [currentImage, setCurrentImage] = useState(images ? images[0] : {
        url: '/placeholder.jpg',
    });
    useEffect(() => {
        setCurrentImage(images ? images[0] : {
            url: '/placeholder.jpg',
        });
    }, [length]);

    const [imageLoadError, setImageLoadError] = useState(false);
    useEffect(() => {
        setImageLoadError(false);
    }, [images]);

    const handleImageLoadError = () => {
        // alert('Image failed to load');
        setImageLoadError(true);
        if (images.length > 1 &&  currentImage !== images[images.length - 1]) {
            loadNextImage();
        }
    };

    const loadNextImage = () => {
        // find the location of current image and set the next inex image if it is not the last one
        const currentImageIndex = images.findIndex((image) => image === currentImage);
        if (currentImageIndex === -1) {
            console.log('Current image not found');
            return;
        }
        if (currentImageIndex === images.length - 1) {
            console.log('Current image is the last one');
            return;
        }
        const nextImage = images[currentImageIndex + 1];
        // const nextImage = images.find((image) => image !== currentImage);

        if (nextImage) {
            setCurrentImage(nextImage);
            setImageLoadError(false);
        } else {
            console.log('All images failed to load');
        }
    };

    return imageLoadError ? <img
        src="/placeholder.jpg"
        className={classes}
        style={style}
        data-index={index}
        onClick={onClick}
    /> : <img
        src={currentImage.url}
        // alt="Image"
        className={classes}
        style={style}
        data-index={index}
        onError={handleImageLoadError}
        onLoad={() => console.log('Image loaded successfully')}
        onClick={onClick}
    />
        ;
};

export default ImageComponent;
