import React, { useCallback, useState, useEffect } from "react";
import MainHeader from "../components/Header";
import jwt_decode from 'jwt-decode';
import { useNavigate } from "react-router-dom";
import "@fontsource/quicksand/700.css";
import axios from "axios";
import MobileNavbar from "./mobile/Navbar";
import Footer from "../components/Footer";
import { Pagination } from "@mui/material";

const MyTrips = () => {
  const [isuserLoggedIn, setIsuserLoggedIn] = useState(false);
  const [user, setUser] = useState(null);
  const token = localStorage.getItem('token');
  if (token && !isuserLoggedIn) {
    try {

      setIsuserLoggedIn(true);
      setUser(jwt_decode(token));
    } catch (error) {
      setIsuserLoggedIn(false);
      localStorage.removeItem('token');
    }

  }
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  // React.useEffect(() => {
  //   if(!isuserLoggedIn){
  //     navigate('/login');
  //   }
  // }, [isuserLoggedIn]);

  const getSavedItineraries = () => {
    const token = localStorage.getItem('token');
    if (!token) {
      navigate('/login');
    }
    setLoading(true);
    axios.get(`${process.env.REACT_APP_BACKEND_URL}/api/itinerary/saved`, {
      headers: {
        'Authorization': `Bearer ${token}`
      }
    }).then((response) => {
      console.log(response.data);
      setSavedItineraries(response.data);
      setLoading(false);
    }).catch((error) => {
      if (error.response && error.response.status === 401) {
        localStorage.removeItem('token');
        navigate('/login');
      }
      console.log(error);
      setError(error);
      setLoading(false);
    })
  }

  // let testsavedItineraries = [
  //   {
  //     id: "test",
  //     name: "Enchanting Adventures Through Paris",
  //     days: 4,
  //     activities: 4,
  //     collaborators: 2,
  //     image: "https://file.rendit.io/n/xIoHi5vBeJ96Jgjazlvo.png",
  //   }
  // ]

  const [savedItineraries, setSavedItineraries] = useState(null);

  const [page, setPage] = useState(0);
  const [itineraries, setItineraries] = useState(null);
  const itinerariesPerPage = 10;
  const noOfPages = savedItineraries ? Math.ceil(savedItineraries.length / itinerariesPerPage) : 0;
  const onPageClick = (e, page) => {
    setPage(page-1);
  }
  React.useEffect(() => {
    if (!savedItineraries) {
      return;
    }
    const start = page * itinerariesPerPage;
    const end = start + itinerariesPerPage;
    setItineraries(savedItineraries.slice(start, end));
  }, [page, savedItineraries]);

  const onDeleteClick = (e) => {
    console.log(e.currentTarget.dataset.id);
    const id = e.currentTarget.dataset.id;
    console.log(id);
    if (!id) {
      return;
    }

    if (!window.confirm("Are you sure you want to delete this itinerary?")) {
      return;
    }

    axios.post(`${process.env.REACT_APP_BACKEND_URL}/api/deleteSavedItinerary`, {
      id: id
    }, {
      headers: {
        'Authorization': `Bearer ${token}`
      }
    }).then((response) => {
      console.log(response.data);
      getSavedItineraries();
      alert("Itinerary deleted successfully");
    }
    ).catch((error) => {
      alert("Error deleting itinerary");
      console.log(error);
    }
    )

  }
  const [selectedItinerary, setSelectedItinerary] = useState(0);
  React.useEffect(() => {
    getSavedItineraries();
  }, []);
  if (loading) {
    return <div>Loading.....</div>
  }
  const isSmallDevice = () => { return window.innerWidth < 600 };


  if (isSmallDevice()) {



    const onLeftClick = () => {
      if (selectedItinerary === 0) {
        return;
      }
      setSelectedItinerary(selectedItinerary - 1);
    }

    const onRightClick = () => {
      if (selectedItinerary === savedItineraries.length - 1) {
        return;
      }
      setSelectedItinerary(selectedItinerary + 1);
    }

    const itinerary = savedItineraries[selectedItinerary];

    return (
      <div className="relative bg-primary-contrast w-full  text-left text-5xl text-tripdaddy-pink-800 font-typography-h6">
        <div className="absolute top-[64px] left-[16px] flex flex-row items-center justify-start text-base text-text-secondary">
          <div
            className="flex flex-row items-center justify-start gap-[8px] cursor-pointer text-xl"
          >
            <div className="h-4 hidden flex-row items-start justify-start">
              <img
                className="self-stretch relative max-h-full w-4 overflow-hidden shrink-0"
                alt=""
                src="/starsharp.svg"
              />
            </div>
            <div className="flex flex-col items-start justify-start">
              <b className="relative tracking-[0.15px] leading-[160%]">Home</b>
            </div>
          </div>
          <div className="w-[23px] flex flex-col items-center justify-start">
            <div className="relative tracking-[0.15px] leading-[150%] inline-block w-[7px] h-6 shrink-0">
              /
            </div>
          </div>
          <div className="hidden flex-row items-center justify-start gap-[8px]">
            <div className="h-4 hidden flex-row items-start justify-start">
              <img
                className="self-stretch relative max-h-full w-4 overflow-hidden shrink-0"
                alt=""
                src="/starsharp1.svg"
              />
            </div>
            <div className="flex flex-col items-start justify-start">
              <div className="relative tracking-[0.15px] leading-[150%]">
                Link
              </div>
            </div>
          </div>
          <div className="w-[23px] hidden flex-col items-center justify-start">
            <div className="relative tracking-[0.15px] leading-[150%] inline-block w-[7px] h-6 shrink-0">
              /
            </div>
          </div>
          <div className="flex flex-row items-center justify-start gap-[8px] text-xl text-primary-main">
            <div className="h-4 hidden flex-row items-start justify-start">
              <img
                className="self-stretch relative max-h-full w-4 overflow-hidden shrink-0"
                alt=""
                src="/starsharp2.svg"
              />
            </div>
            <div className="flex flex-col items-start justify-start">
              <b className="relative tracking-[0.15px] leading-[160%]">
                itineraries
              </b>
            </div>
          </div>
        </div>
        <div className="absolute top-[576px] left-[calc(50%_-_164px)] w-[328px]  ">
          {
            itineraries && itineraries.map((itinerary, index) => {
              return <div key={index} style={{
                paddingBottom: "40px"
              }} className=" flex flex-row items-start justify-start gap-[56px]">
                <div className="rounded-borderradius bg-tripdaddy-amber-50 shadow-[0px_1px_5px_rgba(0,_0,_0,_0.12),_0px_2px_2px_rgba(0,_0,_0,_0.14),_0px_3px_1px_-2px_rgba(0,_0,_0,_0.2)] w-[328px] flex flex-col p-4 box-border items-start justify-start gap-[24px]">
                  <img
                    className="relative rounded-borderradius w-[300px] h-[189px] overflow-hidden shrink-0 object-cover"
                    alt=""
                    src={itinerary.image.url}
                  />
                  <div className="w-[299px] flex flex-col items-start justify-start">
                    <div className="w-[312px] flex flex-col items-start justify-start gap-[8px]">
                      <b className="relative leading-[133.4%] inline-block w-[312px]">
                        {itinerary.name}
                      </b>
                      <div className="flex flex-col items-start justify-start gap-[16px] text-base text-text-primary">
                        <div className="flex flex-row items-center justify-start gap-[8px]">
                          <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                            <path d="M13.334 2.00008H12.6673V0.666748H11.334V2.00008H4.66732V0.666748H3.33398V2.00008H2.66732C1.93398 2.00008 1.33398 2.60008 1.33398 3.33341V14.0001C1.33398 14.7334 1.93398 15.3334 2.66732 15.3334H13.334C14.0673 15.3334 14.6673 14.7334 14.6673 14.0001V3.33341C14.6673 2.60008 14.0673 2.00008 13.334 2.00008ZM13.334 14.0001H2.66732V5.33341H13.334V14.0001Z" fill="black" fillOpacity-opacity="0.56" />
                          </svg>
                          <div className="relative tracking-[0.15px] leading-[150%]">
                            {itinerary.days} days
                          </div>
                        </div>
                        <div className="flex flex-row items-center justify-start gap-[8px]">
                          <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                            <path d="M8.00065 1.33325C5.42065 1.33325 3.33398 3.41992 3.33398 5.99992C3.33398 9.49992 8.00065 14.6666 8.00065 14.6666C8.00065 14.6666 12.6673 9.49992 12.6673 5.99992C12.6673 3.41992 10.5807 1.33325 8.00065 1.33325ZM8.00065 7.66659C7.08065 7.66659 6.33398 6.91992 6.33398 5.99992C6.33398 5.07992 7.08065 4.33325 8.00065 4.33325C8.92065 4.33325 9.66732 5.07992 9.66732 5.99992C9.66732 6.91992 8.92065 7.66659 8.00065 7.66659Z" fill="black" fillOpacity-opacity="0.56" />
                          </svg>
                          <div className="relative tracking-[0.15px] leading-[150%]">
                            {itinerary.activities} activities
                          </div>
                        </div>
                        <div className="flex flex-row items-center justify-start gap-[8px]">
                          <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                            <path d="M10.666 7.33337C11.7727 7.33337 12.6593 6.44004 12.6593 5.33337C12.6593 4.22671 11.7727 3.33337 10.666 3.33337C9.55935 3.33337 8.66602 4.22671 8.66602 5.33337C8.66602 6.44004 9.55935 7.33337 10.666 7.33337ZM5.33268 7.33337C6.43935 7.33337 7.32601 6.44004 7.32601 5.33337C7.32601 4.22671 6.43935 3.33337 5.33268 3.33337C4.22602 3.33337 3.33268 4.22671 3.33268 5.33337C3.33268 6.44004 4.22602 7.33337 5.33268 7.33337ZM5.33268 8.66671C3.77935 8.66671 0.666016 9.44671 0.666016 11V12.6667H9.99935V11C9.99935 9.44671 6.88602 8.66671 5.33268 8.66671ZM10.666 8.66671C10.4727 8.66671 10.2527 8.68004 10.0193 8.70004C10.7927 9.26004 11.3327 10.0134 11.3327 11V12.6667H15.3327V11C15.3327 9.44671 12.2193 8.66671 10.666 8.66671Z" fill="black" fill-fillOpacity="0.56" />
                          </svg>
                          <div className="relative tracking-[0.15px] leading-[150%]">
                            {itinerary.collaborators} collaborators
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="relative w-[184px] h-[42px] text-mini text-primary-contrast">
                    <div className="absolute top-[0px] left-[104px] rounded-borderradius bg-primary-main shadow-[0px_1px_5px_rgba(0,_0,_0,_0.12),_0px_2px_2px_rgba(0,_0,_0,_0.14),_0px_3px_1px_-2px_rgba(0,_0,_0,_0.2)] overflow-hidden flex flex-col py-2 px-[22px] items-center justify-center">
                      <div id="ViewButton"
                        onClick={() => navigate("/itinerary?it=" + itinerary.id)} style={{ color: "white" }} className="flex flex-row items-center justify-center gap-[8px]">
                        <img
                          className="relative w-[18px] h-[22px] hidden"
                          alt=""
                          src="/masked-icon.svg"
                        />
                        <b className="relative tracking-[0.46px] leading-[26px] capitalize">
                          View
                        </b>
                        <img
                          className="relative w-[18px] h-[22px] hidden"
                          alt=""
                          src="/masked-icon1.svg"
                        />
                      </div>
                    </div>
                    <div onClick={onDeleteClick}
                      data-id={itinerary.id} className="absolute top-[0px] left-[0px] rounded-borderradius overflow-hidden flex flex-col py-2 px-[22px] items-center justify-center text-text-primary border-[1px] border-solid border-text-primary">
                      <div className="flex flex-row items-center justify-center gap-[8px]">
                        <img
                          className="relative w-[18px] h-[22px] hidden"
                          alt=""
                          src="/masked-icon2.svg"
                        />
                        <b className="relative tracking-[0.46px] leading-[26px] capitalize">
                          Delete
                        </b>
                        <img
                          className="relative w-[18px] h-[22px] hidden"
                          alt=""
                          src="/masked-icon3.svg"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            })
          }


          <div style={{
            justifyContent: "center",
            display: "flex",
            alignItems: "center",
            width: "110%",
            position: "relative",
            left: "-5%"
          }} className="  h-8">
            <Pagination count={noOfPages}  onChange={onPageClick} color="secondary" />
            {/* <button disabled={selectedItinerary === 0}  onClick={onLeftClick} style={{width: "40px", height: "32px"}} className=" items-center absolute top-[0px] left-[calc(50%_-_51px)] shadow-[0px_1px_5px_rgba(0,_0,_0,_0.12),_0px_2px_2px_rgba(0,_0,_0,_0.14),_0px_3px_1px_-2px_rgba(0,_0,_0,_0.2)] flex flex-row items-start justify-start">
              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                <path d="M20 11H7.83L13.42 5.41L12 4L4 12L12 20L13.41 18.59L7.83 13H20V11Z" fill="#212121" />
              </svg>
            </button>
            <button disabled={selectedItinerary===savedItineraries.length - 1} onClick={onRightClick} style={{width: "40px", height: "32px", alignItems: "flex-end", flexDirection: "column", justifyContent: "center"}} className="items-center absolute top-[0px] left-[calc(50%_+_3px)] shadow-[0px_1px_8px_rgba(0,_0,_0,_0.12),_0px_3px_4px_rgba(0,_0,_0,_0.14),_0px_3px_3px_-2px_rgba(0,_0,_0,_0.2)] flex flex-row items-start justify-start">
              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                <path d="M12 4L10.59 5.41L16.17 11H4V13H16.17L10.59 18.59L12 20L20 12L12 4Z" fill="#212121" />
              </svg>
            </button> */}
          </div>
          <div style={{
            width: "100%",
            height: "50px"
          }}></div>

        </div>

        <div className="absolute top-[112px] left-[calc(50%_-_180px)] w-[360px] h-[400px]">
          <img
            className="absolute top-[393px] left-[calc(50%_-_180px)] w-[360px] h-6"
            alt=""
            src="/shape.svg"
          />
          <div style={{ color: "var(--trip-daddy-pink-800, #AD1457)" }} className="absolute top-[calc(50%_-_200px)] left-[calc(50%_-_180px)] rounded-tl-[59px] rounded-tr-[50px] rounded-b-none w-[360px] h-[400px] overflow-hidden bg-[url('/public/saved_trips_banner.png')] bg-cover bg-no-repeat bg-[top]">
            <div className="absolute top-[306px] left-[0px] rounded-tl-none rounded-tr-borderradius rounded-b-none bg-cornsilk flex flex-col py-boundvariablesdata px-boundvariablesdata4 items-start justify-start">
              <b className="relative leading-[133.4%]">Saved Itineraries</b>
            </div>
          </div>
        </div>
        <MobileNavbar />
        <Footer />
      </div>
    );
  }

  return (
    <div class="flex flex-col w-full" id="NewRootRoot">
      <div
        class="overflow-hidden bg-white flex flex-col pb-10 items-start"
        id="ItinerariesSignedIn"
      >
        <MainHeader />
        <Footer />
        {/* <div
      class="shadow-[0px_3px_5px_-1px_rgba(0,_0,_0,_0.2),_0px_5px_8px_0px_rgba(0,_0,_0,_0.14),_0px_1px_14px_0px_rgba(0,_0,_0,_0.12)] overflow-hidden bg-white self-stretch flex flex-row justify-between mb-10 h-20 shrink-0 items-center pt-3 px-4"
      id="Header"
    >
      <img
        src="https://file.rendit.io/n/6tdgouocA29BsxfRu20k.svg"
        class=""
        id="Logo"
      />
      <div class="self-start flex flex-row mt-1 gap-8 items-start">
        <button
          class="text-xl font-['Quicksand'] font-bold tracking-[0.15] leading-[32px] text-black/60 mt-1"
          id="Button9"
        >
          Home
        </button>
        <div class="flex flex-row gap-4 w-56 shrink-0 items-start">
          <div class="flex flex-col mt-1 gap-2 w-3/5 items-center">
            <button
              class="text-xl font-['Quicksand'] font-bold tracking-[0.15] leading-[32px] text-[#d81b60]"
              id="Button10"
            >
              Itineraries
            </button>
            <div
              class="border-solid border-[#d81b60] self-stretch h-px shrink-0 border-t-2 border-b-0 border-x-0"
              id="Line"
            />
          </div>
          <button
            class="flex flex-row gap-2 w-16 shrink-0 items-center"
            id="Buttons"
          >
            <div
              class="bg-[#bdbdbd] self-start flex flex-col justify-center pl-1 w-10 shrink-0 h-10 items-start rounded-[100px]"
              id="Avatar"
            >
              <div class="text-center text-xl font-['Quicksand'] font-medium tracking-[0.14] leading-[20px] text-white">
                OP
              </div>
            </div>
            <img
              src="https://file.rendit.io/n/OdlUjwWQlmCyfIWiUvwC.svg"
              class="w-6 shrink-0"
              id="NotificationsFilled"
            />
          </button>
        </div>
      </div>
    </div> */}
        <div class="flex flex-row gap-2 items-start mb-4 ml-[205px] mt-[100px]">
          <div
            class="text-xl font-['Quicksand'] font-bold tracking-[0.15] leading-[32px] text-black/60 mr-px"
            id="Link1"
          >
            Home
          </div>
          <div class="font-['Quicksand'] tracking-[0.15] leading-[24px] text-black/60 self-center">
            /
          </div>
          <div
            class="text-xl font-['Quicksand'] font-bold tracking-[0.15] leading-[32px] text-[#d81b60]"
            id="Link4"
          >
            itineraries
          </div>
        </div>
        <img
          src="https://file.rendit.io/n/ismDoKD5NKrB8CMBdbz6.png"
          class="self-center"
          id="ItineraryHero"
        />
        <img
          src="https://file.rendit.io/n/PfWW4QTkbL2bXNlRxVFR.svg"
          class="self-center mb-20"
          id="Shape"
        />
        {itineraries && itineraries.map((itinerary) => {
          return <div id='single-it-container' style={{ width: "100vw" }}>
            <div style={{ paddingBottom: "16px", marginLeft: "220px" }} class="shadow-[0px_3px_1px_-2px_rgba(0,_0,_0,_0.2),_0px_2px_2px_0px_rgba(0,_0,_0,_0.14),_0px_1px_5px_0px_rgba(0,_0,_0,_0.12)] bg-[#fff8e1] flex flex-row gap-6 w-1/2 items-center mb-10 ml-[205px] pt-4 px-4 rounded">
              <img style={{ width: "212px", height: "189px" }}
                src={itinerary.image.url}
                class="mb-4"
                id="Image1"
              />
              <div class="self-start flex flex-col justify-between gap-4 w-3/5">
                <div class="flex flex-col gap-2 items-start">
                  <div class="text-2xl font-['Quicksand'] font-bold leading-[32px] text-[#ad1457]">
                    {itinerary.name}
                  </div>
                  <div class="flex flex-row gap-2 w-20 items-center">
                    <img
                      src="https://file.rendit.io/n/auxiRY8YZh8k1Mur5Jhv.svg"
                      class="w-4 shrink-0"
                      id="CalendarTodayFilled"
                    />
                    <div class="font-['Quicksand'] tracking-[0.15] leading-[24px] text-black/87 self-start">
                      {itinerary.days} days
                    </div>
                  </div>
                </div>
                <div class="self-start flex flex-row gap-2 items-center">
                  <img
                    src="https://file.rendit.io/n/003tLrEYyN1uSzwmmfXF.svg"
                    class="w-4 shrink-0"
                    id="LocationOnFilled"
                  />
                  <div class="font-['Quicksand'] tracking-[0.15] leading-[24px] text-black/87 self-start">
                    {itinerary.activities} activities
                  </div>
                </div>
                <div class="flex flex-row justify-between items-start">
                  <div class="flex flex-row gap-2 items-center">
                    <img
                      src="https://file.rendit.io/n/CIr75PqeF8iON50q90zG.svg"
                      class="w-4 shrink-0"
                      id="PeopleFilled"
                    />
                    <div class="font-['Quicksand'] tracking-[0.15] leading-[24px] text-black/87 self-start">
                      {itinerary.collaborators} collaborators
                    </div>
                  </div>
                  <div class="self-end flex flex-row mt-6 gap-2 w-2/5 items-start">
                    <div id="DeleteButton"
                      onClick={onDeleteClick}
                      data-id={itinerary.id} style={{ display: "flex", position: "relative", cursor: "pointer" }} className=" top-[0px] left-[0px] rounded-borderradius overflow-hidden flex flex-col py-2 px-[22px] items-center justify-center text-text-primary border-[1px] border-solid border-text-primary">
                      <div className="flex flex-row items-center justify-center gap-[8px]">
                        <img
                          className="relative w-[18px] h-[22px] hidden"
                          alt=""
                          src="/masked-icon6.svg"
                        />
                        <b className="relative tracking-[0.46px] leading-[26px] capitalize">
                          Delete
                        </b>
                        <img
                          className="relative w-[18px] h-[22px] hidden"
                          alt=""
                          src="/masked-icon7.svg"
                        />
                      </div>
                    </div>
                    <div id="ViewButton"
                      onClick={() => navigate("/itinerary?it=" + itinerary.id)} style={{ display: "flex", color: "white", cursor: "pointer" }} className=" top-[0px] left-[104px] rounded-borderradius bg-primary-main shadow-[0px_1px_5px_rgba(0,_0,_0,_0.12),_0px_2px_2px_rgba(0,_0,_0,_0.14),_0px_3px_1px_-2px_rgba(0,_0,_0,_0.2)] overflow-hidden flex flex-col py-2 px-[22px] items-center justify-center">
                      <div className="flex flex-row items-center justify-center gap-[8px]">
                        <img
                          className="relative w-[18px] h-[22px] hidden"
                          alt=""
                          src="/masked-icon4.svg"
                        />
                        <b className="relative tracking-[0.46px] leading-[26px] capitalize">
                          View
                        </b>
                        <img
                          className="relative w-[18px] h-[22px] hidden"
                          alt=""
                          src="/masked-icon5.svg"
                        />
                      </div>
                    </div>

                  </div>
                </div>
              </div>
            </div>
          </div>
        })}
        <div style={{ width: "100vw" }} class="">
          <div style={{}} class="text-xs font-['Quicksand'] text-center ">
            Total: {itineraries? itineraries.length : 0} / {savedItineraries ? savedItineraries.length : 0} results
          </div>
        </div>
        <div style={{
            justifyContent: "center",
            display: "flex",
            alignItems: "center",
            width: "110%",
            position: "relative",
            left: "-5%",
            paddingTop: "20px"
          }} className="  h-8">
            <Pagination count={noOfPages}  onChange={onPageClick} color="secondary" />
            {/* <button disabled={selectedItinerary === 0}  onClick={onLeftClick} style={{width: "40px", height: "32px"}} className=" items-center absolute top-[0px] left-[calc(50%_-_51px)] shadow-[0px_1px_5px_rgba(0,_0,_0,_0.12),_0px_2px_2px_rgba(0,_0,_0,_0.14),_0px_3px_1px_-2px_rgba(0,_0,_0,_0.2)] flex flex-row items-start justify-start">
              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                <path d="M20 11H7.83L13.42 5.41L12 4L4 12L12 20L13.41 18.59L7.83 13H20V11Z" fill="#212121" />
              </svg>
            </button>
            <button disabled={selectedItinerary===savedItineraries.length - 1} onClick={onRightClick} style={{width: "40px", height: "32px", alignItems: "flex-end", flexDirection: "column", justifyContent: "center"}} className="items-center absolute top-[0px] left-[calc(50%_+_3px)] shadow-[0px_1px_8px_rgba(0,_0,_0,_0.12),_0px_3px_4px_rgba(0,_0,_0,_0.14),_0px_3px_3px_-2px_rgba(0,_0,_0,_0.2)] flex flex-row items-start justify-start">
              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                <path d="M12 4L10.59 5.41L16.17 11H4V13H16.17L10.59 18.59L12 20L20 12L12 4Z" fill="#212121" />
              </svg>
            </button> */}
          </div>
          <div style={{
            width: "100%",
            height: "50px"
          }}></div>
      </div>

    </div>


  );
};

export default MyTrips;
