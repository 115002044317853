import { Button, Icon } from "@mui/material";
import styled from "styled-components";
import Header from "../components/Header";
import ShareIcon from '@mui/icons-material/Share';
import BookmarkIcon from '@mui/icons-material/Bookmark';
import BookmarkBorderOutlinedIcon from '@mui/icons-material/BookmarkBorderOutlined';
import { useState, } from "react";
import PropTypes from 'prop-types';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Tabs from '@mui/material/Tabs';
import { useLocation, useNavigate } from "react-router-dom";
import axios from "axios";
import jwt_decode from "jwt-decode";
import { useEffect } from "react";
import { testData } from "../test_data";
import { useSearchParams } from "react-router-dom";
import * as React from 'react';
import AddIcon from '@mui/icons-material/Add';
import "@fontsource/quicksand/700.css";
import "@fontsource/quicksand/400.css";
import MapComponent from "../components/Map";
import ShareModal from "../components/ShareModal";
import ActivitiesModal from "../components/ActivitiesModal";
import TrashIcon from '@mui/icons-material/Delete';
import CreateIcon from '@mui/icons-material/Create';
import MobileNavbar from "./mobile/Navbar";
import PortalPopup from "./mobile/components/PortalPopup";
import TransportInformationModal from "./mobile/components/TransportInformationModal";
import getTravelInfo from "../util/getTravelInfo";
import TransportInfoModal from "../components/TransportInfoModal";
// import { ReactSortable } from "react-drag-reorder";
import { ReactSortable } from "react-sortablejs";
import posthog from 'posthog-js';
import Footer from "../components/Footer";
// import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
// import { Carousel } from 'react-responsive-carousel';
import { Splide, SplideSlide } from '@splidejs/react-splide';
import '@splidejs/react-splide/css';
import Overview from "../components/Overview";
import ImageComponent from "../components/ActivityImage";
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';


posthog.init('phc_iT5IAG9SMBpsMnSOZF6sC7KARPKhcOtdf8rXvzFWVl4', { api_host: 'https://app.posthog.com' })


const ShapeIcon = styled.img`
  position: absolute;
  top: 574px;
  left: 0px;
  width: 1239px;
  height: 38px;
`;
const EnchantingAdventuresThrough = styled.b`
  position: relative;
  line-height: 116.7%;
  display: inline-block;
  width: 551px;
`;
const Duration4Days = styled.b`
  position: relative;
  font-size: 20px;
  letter-spacing: 0.15px;
  line-height: 160%;
  display: inline-block;
  color: rgba(0, 0, 0, 0.87);
  width: 162px;
`;
const EnchantingAdventuresThroughParent = styled.div`
  position: absolute;
  top: 304px;
  left: 0px;
  border-radius: 0px 4px 0px 0px;
  background-color: rgba(255, 248, 225, 0.9);
  display: flex;
  flex-direction: column;
  padding: 16px 8px;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 24px;
`;
const ItineraryHero = styled.main`
  position: absolute;
  top: 0px;
  left: 0px;
  border-radius: 100px 100px 0px 0px;
  width: 1240px;
  height: 574px;
  overflow: hidden;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: top;
  text-align: left;
  font-size: 48px;
  color: #ad1457;
  font-family: Quicksand;
`;
const ItineraryHeroSection = styled.div`
  position: absolute;
  top: 0px;
  left: 0px;
  width: 1240px;
  height: 612px;
`;
const Button1 = styled(Button)`
  margin: 0 !important;
  position: absolute;
  top: 14px;
  left: 1050px;
  z-index: 0;
`;
const Button11 = styled(Button)`
  margin: 0 !important;
  position: absolute;
  top: 14px;
  left: 932px;
  z-index: 1;
`;
const StarsharpIcon = styled.img`
  position: relative;
  width: 24px;
  height: 24px;
  overflow: hidden;
  flex-shrink: 0;
`;
const Icon1 = styled.div`
  display: none;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
`;
const Button2 = styled.b`
  position: relative;
  letter-spacing: 0.15px;
  line-height: 160%;
`;
const Container2 = styled.div`
  display: flex;
  flex-direction: row;
  padding: 9px 16px;
  align-items: center;
  justify-content: center;
  gap: 8px;
  z-index: 0;
`;
const Line = styled.div`
  position: absolute;
  margin: 0 !important;
  width: calc(100% + 2px);
  right: -1px;
  bottom: -1px;
  left: -1px;
  border-top: 2px solid #d81b60;
  box-sizing: border-box;
  height: 2px;
  z-index: 1;
`;
const StyledTab = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
  font-size: 20px;
  color: #d81b60;
`;
const Container3 = styled.div`
  display: flex;
  flex-direction: row;
  padding: 9px 16px;
  align-items: center;
  justify-content: center;
  gap: 8px;
`;
const Tab1 = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: 20px;
`;
const Button6 = styled.b`
  position: relative;
  letter-spacing: 0.4px;
  line-height: 24px;
  text-transform: capitalize;
`;
const Tab4 = styled.div`
  display: none;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;
const DayTitle = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
`;
const Scroll1 = styled.div`
  position: absolute;
  top: calc(50% + 3.5px);
  left: 4px;
  border-radius: 4px;
  background-color: #bdbdbd;
  width: 7px;
  height: 88px;
  transform: rotate(-90deg);
  transform-origin: 0 0;
`;
const ScrollVertical = styled.div`
  align-self: stretch;
  position: relative;
  background-color: #eee;
  height: 15px;
  display: none;
`;
// const MyStledTabs = styled.div`
//   width: 707px;
//   overflow: hidden;
//   display: flex;
//   flex-direction: column;
//   align-items: flex-start;
//   justify-content: flex-start;
//   gap: 56px;
// `;
const DayTabs = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  z-index: 2;
`;
const Controls = styled.div`
  width: 1190px;
  height: 70px;
  display: flex;
  flex-direction: column;
  padding: 10px;
  box-sizing: border-box;
  align-items: flex-start;
  justify-content: space-between;
  position: relative;
`;
const DayHeading = styled.h1`
  margin: 0;
  position: relative;
  font-size: inherit;
  letter-spacing: 0.25px;
  line-height: 123.5%;
  font-weight: 700;
  font-family: inherit;
  display: inline-block;
  width: 100%;
  flex-shrink: 0;
`;
const Location1 = styled.b`
  position: relative;
  line-height: 133.4%;
`;
const Title1 = styled.div`
  background-color: #ffe082;
  display: flex;
  flex-direction: row;
  padding: 8px;
  align-items: center;
  justify-content: center;
`;
const MapWithPins = styled.img`
  position: relative;
  width: 938px;
  height: 295px;
`;
const Map1 = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 8px;
  font-size: 24px;
`;
const MaskedIcon = styled.img`
  position: relative;
  width: 18px;
  height: 22px;
  display: none;
`;
const Button13 = styled.b`
  position: relative;
  letter-spacing: 0.46px;
  line-height: 26px;
  text-transform: capitalize;
`;
const MaskedIcon1 = styled.img`
  position: relative;
  width: 24px;
  height: 24px;
`;
const Base = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 8px;
`;
const Button12 = styled.div`
  border-radius: 4px;
  background-color: #d81b60;
  box-shadow: 0px 1px 5px rgba(0, 0, 0, 0.12), 0px 2px 2px rgba(0, 0, 0, 0.14),
    0px 3px 1px -2px rgba(0, 0, 0, 0.2);
  overflow: hidden;
  display: flex;
  flex-direction: column;
  padding: 8px 22px;
  align-items: center;
  justify-content: center;
  font-size: 15px;
  color: #fff;
  cursor: pointer;
`;
const TitleParent = styled.div`
  width: 815px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`;
const ActivityImage = styled.img`
  position: relative;
  border-radius: 4px;
  width: 285px;
  height: 190px;
  object-fit: cover;
`;
const ActivityTitle = styled.b`
  position: relative;
  line-height: 133.4%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
`;
const ActivityDescription = styled.div`
  position: relative;
  font-size: 20px;
  letter-spacing: 0.15px;
  line-height: 150%;
  display: flex;
  align-items: center;
  width: 100%;
  // height: 98px;
  flex-shrink: 0;
`;
const Text1 = styled.div`
  // position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  // height: 169px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 8px;
`;
const Description = styled.div`
  position: relative;
  width: 450px;
  min-height: 184px;
`;
const ActivityCardBig = styled.div`
  border-radius: 4px;
  background-color: #fafafa;
  box-shadow: 0px 1px 10px rgba(0, 0, 0, 0.12), 0px 4px 5px rgba(0, 0, 0, 0.14),
    0px 2px 4px -1px rgba(0, 0, 0, 0.2);
  width: 775px;
  min-height: 220px;
  display: flex;
  flex-direction: row;
  padding: 16px;
  box-sizing: border-box;
  align-items: center;
  justify-content: flex-start;
  gap: 16px;
`;
const ActivityDescription1 = styled.div`
  position: relative;
  font-size: 20px;
  letter-spacing: 0.15px;
  line-height: 150%;
  display: flex;
  align-items: center;
  width: 333px;
  height: 129px;
  flex-shrink: 0;
`;
const ActivityCardBig2 = styled.div`
  border-radius: 4px;
  background-color: #fafafa;
  box-shadow: 0px 1px 10px rgba(0, 0, 0, 0.12), 0px 4px 5px rgba(0, 0, 0, 0.14),
    0px 2px 4px -1px rgba(0, 0, 0, 0.2);
  width: 775px;
  height: 226px;
  display: flex;
  flex-direction: row;
  padding: 16px;
  box-sizing: border-box;
  align-items: flex-end;
  justify-content: flex-start;
  gap: 16px;
`;
const ActivityCards = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: flex-start;
  gap: 40px;
`;
const Actvities = styled.section`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 8px;
  text-align: left;
  font-size: 24px;
  color: rgba(0, 0, 0, 0.87);
  font-family: Quicksand;
`;
const DayDetails = styled.div`
  border-radius: 20px;
  border: 2px solid #d81b60;
  box-sizing: border-box;
  width: 1190px;

  display: flex;
  flex-direction: column;
  padding: 16px;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 24px;
  font-size: 34px;
  color: rgba(0, 0, 0, 0.87);
`;
const ItineraryDetailsContainer = styled.div`
  position: absolute;
  top: 642px;
  left: 8px;

  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
`;
const Container = styled.main`
  position: absolute;
  top: 138px;
  left: 100px;
  width: 1240px;
 
  text-align: left;
  font-size: 14px;
  color: rgba(0, 0, 0, 0.6);
  font-family: Quicksand;
`;
const LogoSmallIcon = styled.img`
  position: relative;
  width: 220px;
  height: 47px;
  overflow: hidden;
  flex-shrink: 0;
`;
const Button14 = styled.div`
  border-radius: 4px;
  background-color: #0057b2;
  box-shadow: 0px 1px 5px rgba(0, 0, 0, 0.12), 0px 2px 2px rgba(0, 0, 0, 0.14),
    0px 3px 1px -2px rgba(0, 0, 0, 0.2);
  overflow: hidden;
  display: flex;
  flex-direction: column;
  padding: 8px 22px;
  align-items: center;
  justify-content: center;
`;
const Button16 = styled.div`
  border-radius: 4px;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  padding: 8px 11px;
  align-items: center;
  justify-content: center;
  color: #0057b2;
`;
const Buttons = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 8px;
`;
const Actions = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
`;

const ItineraryPageMapOnHorizonRoot = styled.div`
  position: relative;
  background-color: #fff;
  width: 100%;
  
  overflow: hidden;
  text-align: left;
  font-size: 15px;
  color: #fff;
  font-family: Quicksand;
`;



function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function sum(arr) {
  return arr.reduce(function (a, b) {
    return a + b;
  }, 0);
}


function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

Array.prototype.move = function (from, to) {
  this.splice(to, 0, this.splice(from, 1)[0]);
};


const ViewItinerary = () => {
  const [value, setValue] = useState(0);
  const handleDayChange = (event, newValue) => {
    setValue(newValue);
  };
  const location = useLocation();
  const navigate = useNavigate();
  // var trip_data;
  // var itinerary;
  let [isLoading, setisLoading] = useState(false);
  let [itinerary, setItinerary] = useState();
  let [trip_data, setTripData] = useState();
  let [state, setState] = useState({ current_page: 1 });
  const [searchParams, setSearchParams] = useSearchParams();
  const [shareModalOpen, setShareModalOpen] = React.useState(false);
  const [saveModalOpen, setSaveModalOpen] = React.useState(false);
  const [ActivitiesModalOpen, setActivitiesModalOpen] = React.useState(false);
  const [invite, setInvite] = React.useState(null);
  const [error, setError] = React.useState(null);
  const [ischanged, setIschanged] = useState(false);
  const [transportModalOpen, setTransportModalOpen] = React.useState(false);
  const [transportModalData, setTransportModalData] = React.useState(null);
  const [distanceMatrix, setDistanceMatrix] = React.useState(null);
  const [carouselImages, setCarouselImages] = React.useState(null);
  const [carousalOpen, setCarousalOpen] = React.useState(false);
  const [showDetails, setShowDetails] = React.useState(false);


  const openActivitiesModal = () => setActivitiesModalOpen(true);
  const closeActivitiesModal = () => setActivitiesModalOpen(false);

  const closeShareModal = () => setShareModalOpen(false);
  const openShareModal = () => setShareModalOpen(true);

  const openTransportModal = (event) => {
    console.log(event.currentTarget.dataset.index);

    var start;
    start = event.currentTarget.dataset.index ? parseInt(event.currentTarget.dataset.index) : 0;
    if (trip_data.staying_at) {
      start = start + 1;
    }
    if (trip_data.airport) {
      start = start + 1;
    }
    if (event.currentTarget.dataset.index === "airport") {
      start = 0;
    }
    if (event.currentTarget.dataset.index === "stay") {
      start = 1;
    }
    console.log(start);

    getTransportModalData(start);
    setTransportModalOpen(true);
  }
  const closeTransportModal = () => {
    setTransportModalOpen(false);
  }



  const [autosave, setAutosave] = useState(false);

  const [isuserLoggedIn, setIsuserLoggedIn] = React.useState(false);
  const [user, setUser] = React.useState(null);
  const token = localStorage.getItem('token');
  if (token && !isuserLoggedIn) {
    try {
      setIsuserLoggedIn(true);
      setUser(jwt_decode(token));
    } catch (error) {
      setIsuserLoggedIn(false);
      localStorage.removeItem('token');
    }
  }

  const setChangedPos = (it) => {
    setItinerary([...it]);
  };

  const acceptRequest = async () => {
    const invitetoken = searchParams.get('token');
    const itinerary_id = searchParams.get('it');
    setisLoading(true);
    if (!invitetoken || !itinerary_id) {
      setError('No invite token found.');
      setisLoading(false);
      alert('No invite token found.');
      navigate('/');
      return
    }
    if (!token) {
      setError('You must be logged in to accept an invite.');
      setisLoading(false);
      alert('You must be logged in to accept an invite.');
      navigate('/login');
      return
    }
    try {
      const response = await axios.post(process.env.REACT_APP_BACKEND_URL + '/api/itinerary/acceptinvite', { token: invitetoken, itinerary_id: itinerary_id }, {
        headers: {
          'Authorization': 'Bearer ' + token,
          'Content-Type': 'application/json',
        }
      });
      setInvite(response.data);
      setisLoading(false);
      navigate('/itinerary?it=' + itinerary_id);
    } catch (error) {
      alert(error.response.data.message);
      setError(error);
      setisLoading(false);
    }
    finally {
      setisLoading(false);
      setIschanged(true);
    }

  }

  const handleStayDelete = (event) => {
    event.preventDefault();
    if (!confirm("Are you sure you want to delete this Hotel?")) {
      return;
    }
    let trip_data_new = trip_data;
    trip_data_new.staying_at = undefined;
    setTripData({ ...trip_data_new });
    console.log(trip_data)
    posthog.capture('hotel deleted', {
      'day': value + 1,
      'itinerary_id': searchParams.get("it")
    });
  }

  const handleAirportDelete = (event) => {
    event.preventDefault();
    if (!confirm("Are you sure you want to delete this Airport?")) {
      return;
    }
    let trip_data_new = trip_data;
    trip_data_new.airport = undefined;
    setTripData({ ...trip_data_new });
    console.log(trip_data)
    posthog.capture('airport deleted', {
      'day': value + 1,
      'itinerary_id': searchParams.get("it")
    });
  }





  const checkInvite = async () => {
    var isnewuser = searchParams.get('new');
    const invitetoken = searchParams.get('token');
    const itinerary_id = searchParams.get('it');

    const this_url = window.location.href;

    if (!isuserLoggedIn && invitetoken && itinerary_id) {
      // confirm("test")
      isnewuser = parseInt(isnewuser) === 1 ? true : false;
      console.log(isnewuser)
      if (isnewuser) {
        navigate('/register?redirect=1&token=' + invitetoken + '&it=' + itinerary_id);
        return;
      } else {
        if (!isuserLoggedIn) {
          navigate('/login?redirect=1&token=' + invitetoken + '&it=' + itinerary_id);
          return;
        }
      }
    }

    if (invitetoken && itinerary_id) {
      await acceptRequest();
    }
  }
  // checkInvite();

  React.useEffect(() => {
    checkInvite();
  }, []);

  const getTransportModalData = (start) => {
    if (!itinerary) {
      return null;
    }
    if (!start) {
      start = 0;
    } else {
      start = parseInt(start)
    }
    const day = itinerary[value];
    const activities = day.activities;
    var locations;
    const places = activities.map((activity) => {
      return { "poi": activity.point_of_interest, "location": activity.place_details.candidates[0], "address": activity.place_details.candidates[0].formatted_address };
    })
    if (trip_data.staying_at) {
      locations = [{ "poi": trip_data.staying_at.place_details.name, "location": trip_data.staying_at.place_details, "address": trip_data.staying_at.place_details.formatted_address }].concat(places);
    } else {
      locations = places;
    }
    if (trip_data && trip_data.airport) {
      locations = [{ "poi": trip_data.airport.candidates[0].name, "location": trip_data.airport.candidates[0], "address": trip_data.airport.candidates[0].formatted_address }].concat(locations);
    } else {
      locations = places;
    }



    console.log(locations);
    const from = locations[start].poi;
    const to = locations[start + 1].poi;
    return setTransportModalData({ from, to, locations });
  }



  const addActivityAction = (activity) => {
    console.log(activity)
    activity.activity = activity.activity_name;
    activity.activity_name = null;
    let new_itinerary = itinerary;
    new_itinerary[value].activities.push(activity);
    setItinerary(new_itinerary);
    // location.state.itinerary = new_itinerary;
    setActivitiesModalOpen(false);
    posthog.capture('activity added', {
      'activity': activity.activity_name,
      'day': value + 1,
      'itinerary_id': searchParams.get("it")
    });

    if (autosave) {
      console.log("Auto saving")
      axios.post(`${process.env.REACT_APP_BACKEND_URL}/saveItinerary`, {
        trip_data: JSON.parse(JSON.stringify(trip_data, getCircularReplacer())),
        itinerary: JSON.parse(JSON.stringify(itinerary, getCircularReplacer())),
        user_id: user.id,
        id: searchParams.get("it")
      }, { headers: { "Authorization": "Bearer " + token, "Content-Type": "application/json" } })
        .then(res => {
          // alert("Itinerary saved successfully");
          // if (searchParams.get("it") !== res.data.id) {
          //   navigate("/itinerary?it=" + res.data.id)
          //   return
          // }
          // navigate("/mytrips")
          setIsSaveDone(!isSaveDone);

        }).catch(err => {
          console.log(err)
          // alert("Error in saving itinerary. Please try again.")
        })
    }

    // console.log(new_itinerary)
  }

  const getHeroImageURL = () => {
    if (trip_data && trip_data.place_details && trip_data.place_details.candidates && trip_data.place_details.candidates[0] && trip_data.place_details.candidates[0].photos && trip_data.place_details.candidates[0].photos[0] && trip_data.place_details.candidates[0].photos[0].photo_reference) {
      console.log(trip_data.place_details.candidates[0].photos[0])
      return process.env.REACT_APP_BACKEND_URL + "/api/map/get_photo/" + trip_data.place_details.candidates[0].photos[0].photo_reference
    }

    return trip_data.city_images ? trip_data.city_images[0].url : '/demo-it-hero.png'
  }

  const nextClick = (event) => {
    state.current_page = state.current_page + 1;
    setState({ ...state });
  }

  const getCircularReplacer = () => {
    const seen = new WeakSet();
    return (key, value) => {
      if (typeof value === "object" && value !== null) {
        if (seen.has(value)) {
          return;
        }
        seen.add(value);
      }
      return value;
    };
  };

  const deleteActivity = (event) => {
    event.preventDefault();
    event.stopPropagation();
    const id = event.currentTarget.id;
    let new_itinerary = itinerary;
    if (!confirm("Are you sure you want to delete this activity?")) {
      return;
    }
    new_itinerary = new_itinerary.map((day) => {
      day.activities = day.activities.filter((activity) => {
        return activity.id !== id;
      })
      return day;
    })
    setItinerary([...new_itinerary])
    posthog.capture('activity deleted', {
      'activity': event.currentTarget.dataset.activity,
      'day': value + 1,
      'itinerary_id': searchParams.get("it")
    });

  }

  const hangleActivityDetailCLick = (event) => {
    event.preventDefault();
    event.stopPropagation();
    const id = event.currentTarget.id;
    navigate("/activityDetails?it=" + searchParams.get("it") + "&activity=" + id)
  }




  const refresh_flight = (event) => {
    event.preventDefault();
    setisLoading(true);
    const dest_airport = event.currentTarget.dataset.dest_airport;
    axios.get(`${process.env.REACT_APP_BACKEND_URL}/api/flight/refresh_prices?to=${dest_airport}`, {
      headers: {
        'Authorization': `Bearer ${token}`
      }
    }
    ).then((response) => {
      console.log(response.data);
      // console.log("refreshed flight prices")

      let new_trip_data = trip_data;
      new_trip_data.cheapest_travel_date = response.data;
      setTripData({ ...new_trip_data });

      // saveTripData(new_trip_data);

      setisLoading(false);
      alert("Flight prices refreshed successfully");
    }
    ).catch((error) => {
      console.log(error);
      alert("Error in refreshing flight prices. Please try again.")
      // setError(error);
      setisLoading(false);
    })

  }

  const handleGetDirectionsClick = (activities, trip_data) => {
    var origin = activities[0].place_details.candidates[0].name + ", " + trip_data.city_or_address;
    var destination = activities[activities.length - 1].place_details.candidates[0].name + ", " + trip_data.city_or_address;
    var waypoints = "";
    if (trip_data.airport) {
      origin = trip_data.airport.candidates[0].name + ", " + trip_data.airport.candidates[0].formatted_address;
      if (trip_data.staying_at) {
        waypoints += trip_data.staying_at.place_details.name + ", " + trip_data.staying_at.place_details.formatted_address + "|";
      }
      waypoints += activities.slice(0, activities.length - 1).map((activity) => {
        return activity.place_details.candidates[0].name + ", " + trip_data.city_or_address;
      }).join("|");
    } else {
      if (trip_data.staying_at) {
        waypoints += trip_data.staying_at.place_details.name + ", " + trip_data.staying_at.place_details.formatted_address + "|";
      }
      waypoints += activities.slice(1, activities.length - 1).map((activity) => {
        return activity.place_details.candidates[0].name + ", " + trip_data.city_or_address;
      }).join("|");

    }
    var url = `https://www.google.com/maps/dir/?api=1&origin=${origin}&waypoints=${waypoints}&destination=${destination}&travelmode=driving&dir_action=navigate`;
    console.log(url);
    window.open(url, "_blank");
  }


  // check periodically if itinerary is changed 

  const handleSave = (event) => {
    event.preventDefault();
    if (!isuserLoggedIn) {
      alert("Please login to save itinerary");
      navigate('/login');
      return;
    }
    axios.post(`${process.env.REACT_APP_BACKEND_URL}/saveItinerary`, {
      trip_data: JSON.parse(JSON.stringify(trip_data, getCircularReplacer())),
      itinerary: JSON.parse(JSON.stringify(itinerary, getCircularReplacer())),
      user_id: user.id,
      id: searchParams.get("it")
    }, { headers: { "Authorization": "Bearer " + token, "Content-Type": "application/json" } })
      .then(res => {
        alert("Itinerary saved successfully");
        if (searchParams.get("it") !== res.data.id) {
          // navigate("/itinerary?it=" + res.data.id)
          window.location = "/itinerary?it=" + res.data.id;
          return
        }
      }).catch(err => {
        if (err.response && err.response.status === 401) {
          alert("You are not authorized to save this itinerary. Please login to save this itinerary.")
          localStorage.removeItem('token');
          navigate("/login")
          return
        }
        console.log(err)
        alert("Error in saving itinerary. Please try again.")
      })
  }
  const [savedItineraries, setSavedItineraries] = useState([]);
  const getSavedItineraries = () => {
    const token = localStorage.getItem('token');
    if (!token) {
      navigate('/login');
    }
    axios.get(`${process.env.REACT_APP_BACKEND_URL}/api/itinerary/saved`, {
      headers: {
        'Authorization': `Bearer ${token}`
      }
    }).then((response) => {
      console.log(response.data);
      setSavedItineraries(response.data);
    }).catch((error) => {
      console.log(error);
      setError(error);
    })
  }

  const [isSaveDone, setIsSaveDone] = useState(false);
  const [overviewHeight, setOverviewHeight] = useState(0);
  const overviewRef = React.useRef(null);
  useEffect(() => {
    if (overviewRef.current) {
      setOverviewHeight(overviewRef.current.clientHeight);
    }
  }, [overviewRef.current, showDetails]);

  // useEffect(() => {
  //   if (isuserLoggedIn) {
  //     getSavedItineraries();
  //   }
  // }, [])

  useEffect(() => {
    if (trip_data && trip_data.is_saved) {

      setAutosave(true);
    }
  }, [trip_data])

  useEffect(() => {
    console.log('autosave :' + autosave)
    if (autosave) {
      console.log("Auto saving")
      axios.post(`${process.env.REACT_APP_BACKEND_URL}/saveItinerary`, {
        trip_data: JSON.parse(JSON.stringify(trip_data, getCircularReplacer())),
        itinerary: JSON.parse(JSON.stringify(itinerary, getCircularReplacer())),
        user_id: user.id,
        id: searchParams.get("it")
      }, { headers: { "Authorization": "Bearer " + token, "Content-Type": "application/json" } })
        .then(res => {
          setIsSaveDone(!isSaveDone);
        }).catch(err => {
          console.log(err)
        })
    }

  }, [itinerary, trip_data])

  useEffect(() => {
    if (itinerary) {
      var locations = itinerary[value].activities.map((activity) => {
        return activity.point_of_interest + " " + activity.place_details && activity.place_details.candidates && activity.place_details.candidates[0] ? activity.place_details.candidates[0].formatted_address : "";
      })
      if (trip_data && trip_data.airport) {
        locations = [trip_data.airport.candidates[0].name + " " + trip_data.airport.candidates[0].formatted_address].concat(locations);
      }

      // console.log("locations",locations)
      getTravelInfo(locations).then((res) => {
        console.log(res)
        setDistanceMatrix(res);
      })
    }
  }, [itinerary, value])



  const prevClick = (event) => {
    state.current_page = state.current_page - 1;
    setState({ ...state });
  }



  const handleShare = (event) => {
    event.preventDefault();
    navigator.clipboard.writeText(window.location.href);
    alert("Link copied to clipboard");
  }

  const getItineraryDetails = () => {
    axios.post(`${process.env.REACT_APP_BACKEND_URL}/getSavedItinerary`, { id: searchParams.get("it") }, { headers: { "Authorization": "Bearer " + token, "Content-Type": "application/json" } })
      .then(res => {
        console.log(res.data)
        setItinerary(res.data.itinerary);
        if (!(res.data.trip_data && res.data.trip_data.airport && res.data.trip_data.airport.candidates && res.data.trip_data.airport.candidates[0])) {
          let d = res.data.trip_data
          d.airport = null;
          if (!res.data.trip_data.cheapest_travel_date) {

            d.cheapest_travel_date = {}
            setTripData(d);
          } else {
            setTripData(d);
          }
        } else {
          if (!res.data.trip_data.cheapest_travel_date) {
            let d = res.data.trip_data
            d.cheapest_travel_date = {}
            setTripData(d);
          } else {
            setTripData(res.data.trip_data);
          }

        }

      }).catch(err => {
        console.log(err);
        posthog.capture('itinerary view error', {
          'itinerary_id': searchParams.get("it"),
          'error': err
        });
        if (err.response && err.response.status === 401) {
          alert("You are not authorized to view this itinerary. Please login to view this itinerary.")
          navigate("/login")
          return
        }

        alert("Error in creating itinerary. Please try again.")
        setisLoading(false);
      })

  }

  useEffect(() => {
    const handleEsc = (event) => {
      if (event.key === 'Escape') {
        console.log('Close')
        setCarousalOpen(false);
      }
    };
    window.addEventListener('keydown', handleEsc);

    return () => {
      window.removeEventListener('keydown', handleEsc);
    };
  }, []);

  useEffect(() => {
    if (!searchParams.get("token")) {
      getItineraryDetails();
    }
  }, [ischanged])

  const showTravelDate = (leg) => {
    if (!leg || !leg.departureDateTime || !leg.departureDateTime.month || !leg.departureDateTime.day || !leg.departureDateTime.year) {
      return "N/A";
    }
    const date = leg.departureDateTime.month + "/" + leg.departureDateTime.day + "/" + leg.departureDateTime.year
    const d = new Date(date);
    var monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
    var year = d.getFullYear();
    var day = d.getDate();
    var month = d.getMonth();

    var formattedDate = monthNames[month] + " " + " " + day + ", " + year;
    return formattedDate;
  }

  const handleAddDay = (event) => {
    event.preventDefault();
    let new_itinerary = itinerary;
    console.log(new_itinerary)
    new_itinerary.push({ "day": "Day " + (new_itinerary.length + 1), "activities": [], theme: "Day " + (new_itinerary.length + 1) });
    setItinerary(new_itinerary);
    setOverviewHeight(overviewHeight + 80);
    setValue(new_itinerary.length - 1);
  }



  const openCarousal = (e) => {
    e.preventDefault();
    const index = e.currentTarget.dataset.index;

    if (!index) {
      return;
    }

    if (index === "stay") {
      const images = trip_data.staying_at.photos;
      setCarouselImages(images);
      setCarousalOpen(true);
      return;
    }
    if (index === "airport") {
      const images = trip_data.airport.images;
      setCarouselImages(images);
      setCarousalOpen(true);
      return;
    }
    const images = itinerary[value].activities[index].images;
    setCarouselImages(images);
    console.log(images);

    setCarousalOpen(true);
  }

  // try {
  //   if (!itinerary || !trip_data) {
  //     setItinerary(location.state.itinerary);
  //     setTripData(location.state.trip_data);
  //   }
  // } catch (error) {
  //   window.location = "/"
  // }
  if (!itinerary || !trip_data) {
    return <div>Loading......</div>
  }
  let data = itinerary[state.current_page - 1];

  const isSmallDevice = window.innerWidth <= 600;
  // console.log(trip_data)

  if (carousalOpen) {
    const im = carouselImages ? carouselImages : trip_data.city_images;
    const images = im.map((im) => {
      return im.url;
    }
    )
    return (<>
      <div style={{
        position: "absolute",
        top: "25px",
        left: "30px",
        color: "white",
        fontSize: "30px",
        height: "30px",
        width: "30px",
        cursor: "pointer",
        zIndex: "1000"
      }} onClick={() => {
        setCarousalOpen(false);
        setCarouselImages(null);
      }} >
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
          <path d="M19 6.41L17.59 5L12 10.59L6.41 5L5 6.41L10.59 12L5 17.59L6.41 19L12 13.41L17.59 19L19 17.59L13.41 12L19 6.41Z" fill="white" fill-opacity="0.87" />
        </svg>
      </div>
      <div style={{
        height: "100vh",
      }}>
        <Splide options={{
          rewind: true,
          height: "100vh",
        }}
          aria-label="Images"
        >
          {images.map((im) => {
            return <SplideSlide>
              <img style={{ maxHeight: "100vh", minWidth: "100vw" }} src={im} />
            </SplideSlide>
          })}

        </Splide>
      </div>
    </>
    )
    // return <><div style={{
    //   position: "absolute",
    //   top: "10px",
    //   left: "30px",
    //   color: "white",
    //   fontSize: "30px",
    //   height: "30px",
    //   width: "30px",
    //   cursor: "pointer",
    //   zIndex: "1000"
    // }} onClick={() => {
    //   setCarousalOpen(false);
    //   setCarouselImages(null);
    // }} >
    //   <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
    //     <path d="M19 6.41L17.59 5L12 10.59L6.41 5L5 6.41L10.59 12L5 17.59L6.41 19L12 13.41L17.59 19L19 17.59L13.41 12L19 6.41Z" fill="white" fill-opacity="0.87" />
    //   </svg>
    // </div>
    //   <Carousel
    //     swipeable={true}
    //     showThumbs={false}
    //     showStatus={false}
    //     useKeyboardArrows={true}
    //     emulateTouch={true}
    //     autoFocus={true}

    //   >
    //     {images.map((im) => {
    //       return <div style={{
    //         height: "100vh",
    //       }}>
    //         <img style={{
    //           // height: "100vh",
    //         }} src={im} />
    //       </div>
    //     })}

    //   </Carousel>
    // </>
  }

  if (isSmallDevice) {
    return (
      <>
        <div className=" justify-center relative bg-secondary-contrast w-full  overflow-hidden text-left text-xl text-text-primary font-typography-h5">
          <MobileNavbar />
          <div style={{ height: "40px" }} ></div>
          <div style={{ paddingLeft: "11px", marginTop: "20px", marginBottom: "20px" }} className=" top-[0px] left-[11px] flex flex-row items-center  text-base text-tripdaddy-grey-600">
            <div className="flex flex-row items-center justify-start gap-[8px] text-xl">
              <div className="h-4 hidden flex-row items-start justify-start">
                <img
                  className="self-stretch relative max-h-full w-4 overflow-hidden shrink-0"
                  alt=""
                  src="/starsharp10.svg"
                />
              </div>
              <div className="flex flex-col items-start justify-start">
                <b className="relative tracking-[0.15px] leading-[160%]">
                  Home
                </b>
              </div>
            </div>
            <div className="w-[23px] flex flex-col items-center justify-start">
              <div className="relative tracking-[0.15px] leading-[150%] inline-block w-[7px] h-6 shrink-0">
                /
              </div>
            </div>
            <div className="hidden flex-row items-center justify-start gap-[8px] text-text-secondary">
              <div className="h-4 hidden flex-row items-start justify-start">
                <img
                  className="self-stretch relative max-h-full w-4 overflow-hidden shrink-0"
                  alt=""
                  src="/starsharp11.svg"
                />
              </div>
              <div className="flex flex-col items-start justify-start">
                <div className="relative tracking-[0.15px] leading-[150%]">
                  Link
                </div>
              </div>
            </div>
            <div className="w-[23px] hidden flex-col items-center justify-start text-text-secondary">
              <div className="relative tracking-[0.15px] leading-[150%] inline-block w-[7px] h-6 shrink-0">
                /
              </div>
            </div>
            <div className="flex flex-row items-center justify-start gap-[8px] text-xl text-tripdaddy-pink-500">
              <div className="h-4 hidden flex-row items-start justify-start">
                <img
                  className="self-stretch relative max-h-full w-4 overflow-hidden shrink-0"
                  alt=""
                  src="/starsharp12.svg"
                />
              </div>
              <div className="flex flex-col items-start justify-start">
                <b className="relative tracking-[0.15px] leading-[160%]">
                  Itinerary
                </b>
              </div>
            </div>
          </div>
          <div className=" top-[48px] left-[calc(50%_-_180px)]  h-[400px]">

            <div style={{ backgroundImage: `url("${getHeroImageURL()}")` }} className=" top-[-1px] left-[calc(50%_-_180px)] rounded-t-31xl rounded-b-none  h-[400px] overflow-hidden bg-cover bg-no-repeat bg-[top]">
              <div style={{ top: "280px" }} className=" absolute top-[178px] left-[0px] rounded-tl-none rounded-tr-borderradius rounded-b-none bg-cornsilk flex flex-col p-4 items-start justify-start gap-[16px]">
                <b className="relative text-5xl leading-[133.4%] inline-block w-[277px]">
                  Trip to {trip_data.city_or_address}
                </b>
                <div className="flex flex-row items-start justify-start">
                  <div className="flex flex-row items-center justify-center">
                    <div className="flex flex-row items-center justify-start gap-[8px]">
                      <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                        <path d="M20 3H19V1H17V3H7V1H5V3H4C2.9 3 2 3.9 2 5V21C2 22.1 2.9 23 4 23H20C21.1 23 22 22.1 22 21V5C22 3.9 21.1 3 20 3ZM20 21H4V8H20V21Z" fill="#424242" />
                      </svg>
                      <b className="relative tracking-[0.15px] leading-[160%]">
                        {trip_data.days} days
                      </b>
                    </div>
                  </div>
                </div>
                <div className="flex flex-row items-center justify-start gap-[8px]">
                  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                    <path d="M12 2C8.13 2 5 5.13 5 9C5 14.25 12 22 12 22C12 22 19 14.25 19 9C19 5.13 15.87 2 12 2ZM12 11.5C10.62 11.5 9.5 10.38 9.5 9C9.5 7.62 10.62 6.5 12 6.5C13.38 6.5 14.5 7.62 14.5 9C14.5 10.38 13.38 11.5 12 11.5Z" fill="#424242" />
                  </svg>
                  <b className="relative tracking-[0.15px] leading-[160%] inline-block w-[131px] shrink-0">
                    {trip_data.city_or_address}
                  </b>
                </div>
              </div>
            </div>
            <img
              style={{ left: "0", maxWidth: "100vw" }}
              className="absolute top-[506px] left-[calc(50%_-_180px)]  h-6"
              alt=""
              src="/shape.svg"
            />
          </div>
          {<div
            id="PlanRoot"
            className="bg-[#fff8e1] flex flex-row justify-between h-16 items-center px-6 rounded-[20px]"
            style={{
              position: "absolute",
              top: "535px",
              width: "85%",
              borderBottomLeftRadius: "0px",
              borderBottomRightRadius: "0px",
              fontSize: "15px"
            }}
          >
            <div className="flex flex-row gap-2 items-start">
              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                <path d="M12 2C8.13 2 5 5.13 5 9C5 14.25 12 22 12 22C12 22 19 14.25 19 9C19 5.13 15.87 2 12 2ZM12 11.5C10.62 11.5 9.5 10.38 9.5 9C9.5 7.62 10.62 6.5 12 6.5C13.38 6.5 14.5 7.62 14.5 9C14.5 10.38 13.38 11.5 12 11.5Z" fill="#424242" />
              </svg>
              <div className="font-['Quicksand'] tracking-[0.15] leading-[24px] text-black/87">
                {trip_data.cheapest_travel_date.from ? trip_data.cheapest_travel_date.from : "N/A"} {"-"} {trip_data.cheapest_travel_date.to ? trip_data.cheapest_travel_date.to : "N/A"} {"-"} {trip_data.cheapest_travel_date.from ? trip_data.cheapest_travel_date.from : "N/A"}
              </div>
            </div>

            <div className="flex flex-row justify-between gap-2 items-start">

              <div className="font-['Quicksand'] tracking-[0.15] leading-[24px] text-black/87">
                <div className="flex flex-row gap-2 items-start" style={{
                  cursor: "pointer",
                }} onClick={refresh_flight} data-dest_airport={trip_data.cheapest_travel_date.to ? trip_data.cheapest_travel_date.to : ""} >
                  <img src="/refresh.png" className="w-6 shrink-0" />

                </div>
              </div>
            </div>

            <div className="flex flex-row gap-2 items-start">
              <img
                src="https://file.rendit.io/n/J9ImB7vZr33ULALG1vmm.svg"
                id="FamilyRestroomFilled"
                className="w-6 shrink-0"
              />
              <div className="font-['Quicksand'] tracking-[0.15] leading-[24px] text-black/87">
                {trip_data.cheapest_travel_date.travellers ? trip_data.cheapest_travel_date.travellers.adult : 1} Adults, {trip_data.cheapest_travel_date.travellers ? trip_data.cheapest_travel_date.travellers.child : 0} child
              </div>
            </div>

          </div>}
          {<div
            id="PlanRoot"
            className="bg-[#fff8e1] flex flex-row justify-between h-16 items-center px-6 rounded-[20px]"
            style={{
              position: "absolute",
              top: "585px",
              width: "85%",
              borderTopLeftRadius: "0px",
              borderTopRightRadius: "0px",
              fontSize: "15px"
            }}
          >

            <div className="flex flex-row justify-between gap-2 items-start">
              <img
                src="https://file.rendit.io/n/8Dh5uOqHupE3cLp1fBgo.svg"
                id="Cash"
                className="w-6 shrink-0"
              />
              <div className="font-['Quicksand'] tracking-[0.15] leading-[24px] text-black/87">
                ${trip_data.cheapest_travel_date.minPrice ? trip_data.cheapest_travel_date.minPrice.amount : "N/A"}
              </div>
              <div className="font-['Quicksand'] tracking-[0.15] leading-[24px] text-black/87">
                USD
              </div>
            </div>

            <div className="flex flex-row gap-6  items-start">
              <div className="flex flex-row gap-2  items-start">
                <img
                  src="https://file.rendit.io/n/laKWFp2fyO2WPsGyEFJY.svg"
                  id="DateRangeFilled"
                  className="w-6 shrink-0"
                />
                <div className="font-['Quicksand'] tracking-[0.15] leading-[24px] text-black/87">
                  {showTravelDate(trip_data.cheapest_travel_date.outboundLeg)} - {showTravelDate(trip_data.cheapest_travel_date.inboundLeg)}
                </div>
              </div>
            </div>


          </div>
          }

          <Overview showDetails={showDetails} setShowDetails={setShowDetails} ref={overviewRef} itinerary={itinerary} trip_data={trip_data} setTripData={setTripData} />


          <div style={{ marginTop: "40px", marginLeft: "10px", width: "100%", maxWidth: "100vw" }} className=" top-[0px] left-[8px] flex flex-col items-start justify-start text-sm text-text-secondary">
            {<div style={{
              height: (parseInt(overviewHeight) + 130) + "px",
            }}> </div>}
            <Tabs value={value} style={{ width: "100%", maxWidth: "100%" }} allowScrollButtonsMobile variant="scrollable" scrollButtons TabIndicatorProps={{ style: { background: "var(--primary-main, #D81B60)", } }} textColor="secondary" onChange={handleDayChange} aria-label="itinerary days">
              {itinerary.map((day, index) => {
                return <Tab label={`Day ${index + 1}`} {...a11yProps(index)} style={{ color: "var(--primary-main, #D81B60)", }} />
              })
              }
              <div style={{ width: "50px", alignItems: "center", display: "flex", flexDirection: "row", justifyContent: "flex-end" }}>
                <AddCircleOutlineIcon onClick={handleAddDay} style={{ cursor: "pointer", color: "var(--primary-main, #D81B60)", marginRight: "20px" }} />
              </div>
            </Tabs>
            <div className="overflow-hidden flex flex-col items-start justify-start gap-[56px]">

            </div>
          </div>
          <div className=" top-[64px] left-[calc(50%_-_180px)]  ">

            {itinerary.map((day, index) => {
              const locations = day.activities.length > 0 ? day.activities.map((activity) => {
                return activity.place_details.candidates[0] ? activity.place_details.candidates[0].geometry.location : { lat: 0, lng: 0 };
              }) : [trip_data.airport.candidates[0] ? trip_data.airport.candidates[0].geometry.location : { lat: 0, lng: 0 }];
              var activities = day.activities;
              activities.trip_data = trip_data;
              return <CustomTabPanel value={value} index={index}>
                <div className=" top-[490px] left-[20px] w-80  text-5xl">
                  <div className=" top-[64px] left-[0px] rounded-xl bg-secondary-contrast shadow-[0px_1px_8px_rgba(0,_0,_0,_0.12),_0px_3px_4px_rgba(0,_0,_0,_0.14),_0px_3px_3px_-2px_rgba(0,_0,_0,_0.2)] flex flex-col p-4 items-start justify-start gap-[16px] border-[2px] border-solid border-primary-main">
                    <div className="flex flex-row items-start justify-start">
                      <div className="flex flex-row items-start justify-start">
                        <b className="relative leading-[133.4%] inline-block w-[271px] shrink-0">
                          {day.theme}
                        </b>
                      </div>
                    </div>
                    {/* <div className="relative text-sm tracking-[0.17px] leading-[143%] inline-block w-72">
                  Your journey begins with the timeless beauty and rich culture of
                  the City of Light. Marvel at the grandeur of the Eiffel Tower,
                  take in the romantic Seine River, and start capturing the
                  essence of Paris, one iconic landmark at a time
                </div> */}
                    <div className="flex flex-col items-start justify-start gap-[16px] text-xl">

                      <div className="flex flex-col items-start justify-start gap-[8px]">

                        <div className="bg-tripdaddy-amber-200 flex flex-row py-0 px-boundvariablesdata1 items-center justify-center">
                          <b className="relative tracking-[0.15px] leading-[160%]">
                            Map:
                          </b>
                        </div>
                        <MapComponent activities={activities} locations={locations} width="274px"></MapComponent>

                      </div>
                      <div style={{
                        width: "100%"
                      }}
                        className="flex flex-col  gap-[8px]">
                        <div onClick={() => handleGetDirectionsClick(activities, trip_data)} data-index={"stay"} style={{ cursor: "pointer" }} className="rounded-borderradius overflow-hidden flex flex-col py-1 px-[5px] items-center justify-center text-smi text-secondary-main">
                          <div className="flex flex-row items-center justify-center gap-[8px]">
                            <img
                              className="relative w-4 h-[18px] hidden"
                              alt=""
                              src="/masked-icon8.svg"
                            />
                            <b className="relative tracking-[0.46px] leading-[22px] capitalize">
                              Get Directions
                            </b>
                            <img
                              className="relative w-4 h-[18px] hidden"
                              alt=""
                              src="/masked-icon9.svg"
                            />
                          </div>
                        </div>
                      </div>
                      {trip_data && trip_data.airport && ((value === 0) ) ? <div className="flex flex-col items-start justify-start gap-[8px]" >

                        <div className="bg-tripdaddy-amber-200 flex flex-row py-0 px-boundvariablesdata1 items-center justify-center">
                          <b className="relative tracking-[0.15px] leading-[160%]" style={{
                            color: "var(--text-primary, rgba(0, 0, 0, 0.87))",
                            fontFamily: "Quicksand",
                            fontSize: "24px",
                            fontStyle: "normal",
                            fontWeight: "700",
                            lineHeight: "133.4%"
                          }}>
                            Arriving At:
                          </b>
                        </div>
                        <div className="flex flex-col items-center justify-center gap-[8px] flex-item">
                          <div className="rounded-borderradius bg-tripdaddy-grey-50 shadow-[0px_1px_10px_rgba(0,_0,_0,_0.12),_0px_4px_5px_rgba(0,_0,_0,_0.14),_0px_2px_4px_-1px_rgba(0,_0,_0,_0.2)] flex flex-col p-2 items-center justify-start gap-[8px]">
                            <div className="relative w-[264px] h-[195px]">
                              {/* <img
                                className="absolute top-[0px] left-[0px] rounded-borderradius w-[264px] h-[195px] object-cover"
                                alt=""
                                data-index="airport"
                                onClick={openCarousal}
                                src={trip_data.airport.images ? trip_data.airport.images[0].url : "/placeholder.jpg"}
                              /> */}
                              <ImageComponent images={trip_data.airport.images} classes={"absolute top-[0px] left-[0px] rounded-borderradius w-[264px] h-[195px] object-cover"} index={"airport"} onClick={openCarousal} />
                              <div className="absolute top-[6px] left-[195px] w-16 h-[30px]">
                                <div style={{ left: "29px" }} id="airport-delete-icon" onClick={handleAirportDelete} className="absolute top-[0px] left-[34px] rounded-81xl bg-secondary-contrast overflow-hidden flex flex-col p-[5px] items-center justify-center border-[1px] border-solid border-bluegrey-50">
                                  <div className="flex flex-row items-start justify-start">
                                    <TrashIcon />
                                  </div>
                                </div>
                                {/* <div className="absolute top-[0px] left-[0px] rounded-81xl bg-secondary-contrast overflow-hidden flex flex-col p-[5px] items-center justify-center border-[1px] border-solid border-bluegrey-50">
                                  <div className="flex flex-row items-start justify-start">
                                  
                                  <CreateIcon />
                                  </div>
                                </div> */}
                              </div>
                            </div>
                            <div className="flex flex-col items-start justify-start">
                              <div className="flex flex-col items-start justify-start gap-[16px]">
                                <b className="relative tracking-[0.15px] leading-[160%]">
                                  {trip_data.airport.candidates[0].name}
                                </b>
                                <div className="self-stretch relative text-sm tracking-[0.17px] leading-[143%]">
                                  {trip_data.airport.candidates[0].formatted_address}
                                </div>
                                <div className="flex flex-row items-start justify-start text-smi text-secondary-main">
                                  {/* <div className="rounded-borderradius box-border w-[136px] overflow-hidden shrink-0 flex flex-col py-1 px-2.5 items-center justify-center border-[1px] border-solid border-secondary-outlinedborder">
                                    <div className="flex flex-row items-center justify-center gap-[8px]">
                                      <img
                                        className="relative w-4 h-[18px] hidden"
                                        alt=""
                                        src="/masked-icon6.svg"
                                      />
                                      <b className="relative tracking-[0.46px] leading-[22px] capitalize">
                                        Read more
                                      </b>
                                      <img
                                        className="relative w-4 h-[18px] hidden"
                                        alt=""
                                        src="/masked-icon7.svg"
                                      />
                                    </div>
                                  </div> */}
                                </div>
                              </div>
                            </div>
                          </div>
                          {distanceMatrix ?
                            <div className="flex flex-col items-center justify-center gap-[8px] text-sm">
                              <div className="overflow-hidden flex flex-row p-2 items-center justify-start gap-[16px]">
                                <div className="flex flex-row items-center justify-start">
                                  <svg xmlns="http://www.w3.org/2000/svg" width="13" height="11" viewBox="0 0 13 11" fill="none">
                                    <path d="M1.83333 4.33334L2.83333 1.33334H10.1667L11.1667 4.33334M10.1667 7.66668C9.90145 7.66668 9.6471 7.56132 9.45956 7.37378C9.27202 7.18625 9.16667 6.93189 9.16667 6.66668C9.16667 6.40146 9.27202 6.14711 9.45956 5.95957C9.6471 5.77203 9.90145 5.66668 10.1667 5.66668C10.4319 5.66668 10.6862 5.77203 10.8738 5.95957C11.0613 6.14711 11.1667 6.40146 11.1667 6.66668C11.1667 6.93189 11.0613 7.18625 10.8738 7.37378C10.6862 7.56132 10.4319 7.66668 10.1667 7.66668ZM2.83333 7.66668C2.56812 7.66668 2.31376 7.56132 2.12623 7.37378C1.93869 7.18625 1.83333 6.93189 1.83333 6.66668C1.83333 6.40146 1.93869 6.14711 2.12623 5.95957C2.31376 5.77203 2.56812 5.66668 2.83333 5.66668C3.09855 5.66668 3.3529 5.77203 3.54044 5.95957C3.72798 6.14711 3.83333 6.40146 3.83333 6.66668C3.83333 6.93189 3.72798 7.18625 3.54044 7.37378C3.3529 7.56132 3.09855 7.66668 2.83333 7.66668ZM11.1133 1.00001C10.98 0.613344 10.6067 0.333344 10.1667 0.333344H2.83333C2.39333 0.333344 2.02 0.613344 1.88667 1.00001L0.5 5.00001V10.3333C0.5 10.5102 0.570238 10.6797 0.695262 10.8047C0.820286 10.9298 0.989856 11 1.16667 11H1.83333C2.01014 11 2.17971 10.9298 2.30474 10.8047C2.42976 10.6797 2.5 10.5102 2.5 10.3333V9.66668H10.5V10.3333C10.5 10.5102 10.5702 10.6797 10.6953 10.8047C10.8203 10.9298 10.9899 11 11.1667 11H11.8333C12.0101 11 12.1797 10.9298 12.3047 10.8047C12.4298 10.6797 12.5 10.5102 12.5 10.3333V5.00001L11.1133 1.00001Z" fill="#424242" />
                                  </svg>
                                  <div className="relative tracking-[0.17px] leading-[143%]">
                                    {distanceMatrix.rows[index] && distanceMatrix.rows[index].elements[index + 1] && distanceMatrix.rows[index].elements[index + 1].distance ? distanceMatrix.rows[index].elements[index + 1].distance.text : "0 km"}
                                  </div>
                                </div>
                                <div className="flex flex-row items-center justify-start">
                                  <svg xmlns="http://www.w3.org/2000/svg" width="17" height="16" viewBox="0 0 17 16" fill="none">
                                    <path d="M12.5 14.6667L12.4933 10.6667L9.83333 8.00001L12.4933 5.32668L12.5 1.33334H4.5V5.33334L7.16667 8.00001L4.5 10.66V14.6667H12.5ZM5.83333 5.00001V2.66668H11.1667V5.00001L8.5 7.66668L5.83333 5.00001Z" fill="#424242" />
                                  </svg>
                                  <div className="relative tracking-[0.17px] leading-[143%]">
                                    {distanceMatrix.rows[index] && distanceMatrix.rows[index].elements[index + 1] && distanceMatrix.rows[index].elements[index + 1].duration ? distanceMatrix.rows[index].elements[index + 1].duration.text : "0 min"}
                                  </div>
                                </div>
                              </div>
                              <div onClick={openTransportModal} data-index={"airport"} style={{ cursor: "pointer" }} className="rounded-borderradius overflow-hidden flex flex-col py-1 px-[5px] items-center justify-center text-smi text-secondary-main">
                                <div className="flex flex-row items-center justify-center gap-[8px]">
                                  <img
                                    className="relative w-4 h-[18px] hidden"
                                    alt=""
                                    src="/masked-icon8.svg"
                                  />
                                  <b className="relative tracking-[0.46px] leading-[22px] capitalize">
                                    Detailed transport info
                                  </b>
                                  <img
                                    className="relative w-4 h-[18px] hidden"
                                    alt=""
                                    src="/masked-icon9.svg"
                                  />
                                </div>
                              </div>
                            </div>
                            : null}

                        </div>


                      </div> : null}
                      {trip_data.staying_at ? <div className="flex flex-col items-start justify-start gap-[8px]" >

                        <div className="bg-tripdaddy-amber-200 flex flex-row py-0 px-boundvariablesdata1 items-center justify-center">
                          <b className="relative tracking-[0.15px] leading-[160%]" style={{
                            color: "var(--text-primary, rgba(0, 0, 0, 0.87))",
                            fontFamily: "Quicksand",
                            fontSize: "24px",
                            fontStyle: "normal",
                            fontWeight: "700",
                            lineHeight: "133.4%"
                          }}>
                            Staying At:
                          </b>
                        </div>
                        <div className="flex flex-col items-center justify-center gap-[8px] flex-item">
                          <div className="rounded-borderradius bg-tripdaddy-grey-50 shadow-[0px_1px_10px_rgba(0,_0,_0,_0.12),_0px_4px_5px_rgba(0,_0,_0,_0.14),_0px_2px_4px_-1px_rgba(0,_0,_0,_0.2)] flex flex-col p-2 items-center justify-start gap-[8px]">
                            <div className="relative w-[264px] h-[195px]">
                              {/* <img
                                className="absolute top-[0px] left-[0px] rounded-borderradius w-[264px] h-[195px] object-cover"
                                alt=""
                                data-index="stay"
                                onClick={openCarousal}
                                src={trip_data.staying_at.photos[0].url ? trip_data.staying_at.photos[0].url : "/placeholder.jpg"}
                              /> */}
                              <ImageComponent images={trip_data.staying_at.photos} classes={"absolute top-[0px] left-[0px] rounded-borderradius w-[264px] h-[195px] object-cover"} index={"stay"} onClick={openCarousal} />
                              <div className="absolute top-[6px] left-[195px] w-16 h-[30px]">
                                <div style={{ left: "29px" }} id="stay-delete-icon" onClick={handleStayDelete} className="absolute top-[0px] left-[34px] rounded-81xl bg-secondary-contrast overflow-hidden flex flex-col p-[5px] items-center justify-center border-[1px] border-solid border-bluegrey-50">
                                  <div className="flex flex-row items-start justify-start">
                                    <TrashIcon />
                                  </div>
                                </div>
                                {/* <div className="absolute top-[0px] left-[0px] rounded-81xl bg-secondary-contrast overflow-hidden flex flex-col p-[5px] items-center justify-center border-[1px] border-solid border-bluegrey-50">
                                  <div className="flex flex-row items-start justify-start">
                                  
                                  <CreateIcon />
                                  </div>
                                </div> */}
                              </div>
                            </div>
                            <div className="flex flex-col items-start justify-start">
                              <div className="flex flex-col items-start justify-start gap-[16px]">
                                <b className="relative tracking-[0.15px] leading-[160%]">
                                  {trip_data.staying_at.place_details.name}
                                </b>
                                <div className="self-stretch relative text-sm tracking-[0.17px] leading-[143%]">
                                  {trip_data.staying_at.place_details.formatted_address}
                                </div>
                                <div className="flex flex-row items-start justify-start text-smi text-secondary-main">
                                  {/* <div className="rounded-borderradius box-border w-[136px] overflow-hidden shrink-0 flex flex-col py-1 px-2.5 items-center justify-center border-[1px] border-solid border-secondary-outlinedborder">
                                    <div className="flex flex-row items-center justify-center gap-[8px]">
                                      <img
                                        className="relative w-4 h-[18px] hidden"
                                        alt=""
                                        src="/masked-icon6.svg"
                                      />
                                      <b className="relative tracking-[0.46px] leading-[22px] capitalize">
                                        Read more
                                      </b>
                                      <img
                                        className="relative w-4 h-[18px] hidden"
                                        alt=""
                                        src="/masked-icon7.svg"
                                      />
                                    </div>
                                  </div> */}
                                </div>
                              </div>
                            </div>
                          </div>
                          {distanceMatrix ?
                            <div className="flex flex-col items-center justify-center gap-[8px] text-sm">
                              <div className="overflow-hidden flex flex-row p-2 items-center justify-start gap-[16px]">
                                <div className="flex flex-row items-center justify-start">
                                  <svg xmlns="http://www.w3.org/2000/svg" width="13" height="11" viewBox="0 0 13 11" fill="none">
                                    <path d="M1.83333 4.33334L2.83333 1.33334H10.1667L11.1667 4.33334M10.1667 7.66668C9.90145 7.66668 9.6471 7.56132 9.45956 7.37378C9.27202 7.18625 9.16667 6.93189 9.16667 6.66668C9.16667 6.40146 9.27202 6.14711 9.45956 5.95957C9.6471 5.77203 9.90145 5.66668 10.1667 5.66668C10.4319 5.66668 10.6862 5.77203 10.8738 5.95957C11.0613 6.14711 11.1667 6.40146 11.1667 6.66668C11.1667 6.93189 11.0613 7.18625 10.8738 7.37378C10.6862 7.56132 10.4319 7.66668 10.1667 7.66668ZM2.83333 7.66668C2.56812 7.66668 2.31376 7.56132 2.12623 7.37378C1.93869 7.18625 1.83333 6.93189 1.83333 6.66668C1.83333 6.40146 1.93869 6.14711 2.12623 5.95957C2.31376 5.77203 2.56812 5.66668 2.83333 5.66668C3.09855 5.66668 3.3529 5.77203 3.54044 5.95957C3.72798 6.14711 3.83333 6.40146 3.83333 6.66668C3.83333 6.93189 3.72798 7.18625 3.54044 7.37378C3.3529 7.56132 3.09855 7.66668 2.83333 7.66668ZM11.1133 1.00001C10.98 0.613344 10.6067 0.333344 10.1667 0.333344H2.83333C2.39333 0.333344 2.02 0.613344 1.88667 1.00001L0.5 5.00001V10.3333C0.5 10.5102 0.570238 10.6797 0.695262 10.8047C0.820286 10.9298 0.989856 11 1.16667 11H1.83333C2.01014 11 2.17971 10.9298 2.30474 10.8047C2.42976 10.6797 2.5 10.5102 2.5 10.3333V9.66668H10.5V10.3333C10.5 10.5102 10.5702 10.6797 10.6953 10.8047C10.8203 10.9298 10.9899 11 11.1667 11H11.8333C12.0101 11 12.1797 10.9298 12.3047 10.8047C12.4298 10.6797 12.5 10.5102 12.5 10.3333V5.00001L11.1133 1.00001Z" fill="#424242" />
                                  </svg>
                                  <div className="relative tracking-[0.17px] leading-[143%]">
                                    {distanceMatrix.rows[index] && distanceMatrix.rows[index].elements[index + 1] && distanceMatrix.rows[index].elements[index + 1].distance ? distanceMatrix.rows[index].elements[index + 1].distance.text : "0 km"}
                                  </div>
                                </div>
                                <div className="flex flex-row items-center justify-start">
                                  <svg xmlns="http://www.w3.org/2000/svg" width="17" height="16" viewBox="0 0 17 16" fill="none">
                                    <path d="M12.5 14.6667L12.4933 10.6667L9.83333 8.00001L12.4933 5.32668L12.5 1.33334H4.5V5.33334L7.16667 8.00001L4.5 10.66V14.6667H12.5ZM5.83333 5.00001V2.66668H11.1667V5.00001L8.5 7.66668L5.83333 5.00001Z" fill="#424242" />
                                  </svg>
                                  <div className="relative tracking-[0.17px] leading-[143%]">
                                    {distanceMatrix.rows[index] && distanceMatrix.rows[index].elements[index + 1] && distanceMatrix.rows[index].elements[index + 1].duration ? distanceMatrix.rows[index].elements[index + 1].duration.text : "0 min"}
                                  </div>
                                </div>
                              </div>
                              <div onClick={openTransportModal} data-index={"stay"} style={{ cursor: "pointer" }} className="rounded-borderradius overflow-hidden flex flex-col py-1 px-[5px] items-center justify-center text-smi text-secondary-main">
                                <div className="flex flex-row items-center justify-center gap-[8px]">
                                  <img
                                    className="relative w-4 h-[18px] hidden"
                                    alt=""
                                    src="/masked-icon8.svg"
                                  />
                                  <b className="relative tracking-[0.46px] leading-[22px] capitalize">
                                    Detailed transport info
                                  </b>
                                  <img
                                    className="relative w-4 h-[18px] hidden"
                                    alt=""
                                    src="/masked-icon9.svg"
                                  />
                                </div>
                              </div>
                            </div>
                            : null}

                        </div>


                      </div> : null}
                      <div className="flex flex-col items-start justify-start gap-[8px]">
                        <div className="w-72 flex flex-row items-center justify-between">
                          <div className="bg-tripdaddy-amber-200 flex flex-row py-0 px-boundvariablesdata1 items-center justify-center">
                            <b className="relative tracking-[0.15px] leading-[160%]">
                              My Options:
                            </b>
                          </div>
                          <div onClick={openActivitiesModal} className="rounded-borderradius overflow-hidden flex flex-col py-1 px-2.5 items-center justify-center text-smi text-info-main border-[1px] border-solid border-info-outlinedborder">
                            <div className="flex flex-row items-center justify-center gap-[8px]">
                              <img
                                className="relative w-4 h-[18px] hidden"
                                alt=""
                                src="/masked-icon4.svg"
                              />
                              <b className="relative tracking-[0.46px] leading-[22px] capitalize">
                                Add Activity
                              </b>
                              <AddIcon />
                            </div>
                          </div>
                        </div>
                        <div className="flex flex-col items-start justify-start gap-[40px]">
                          <ReactSortable list={day.activities} setList={(s) => {
                            const newItinerary = itinerary;
                            newItinerary[value].activities = s;
                            setChangedPos(newItinerary);
                          }} id="draggable">
                            {day.activities.map((activity, index) => {
                              return <div key={index} className="flex flex-col items-center justify-center gap-[8px] flex-item">
                                <div className="rounded-borderradius bg-tripdaddy-grey-50 shadow-[0px_1px_10px_rgba(0,_0,_0,_0.12),_0px_4px_5px_rgba(0,_0,_0,_0.14),_0px_2px_4px_-1px_rgba(0,_0,_0,_0.2)] flex flex-col p-2 items-center justify-start gap-[8px]">
                                  <div className="relative w-[264px] h-[195px]" data-index={index} onClick={openCarousal}>
                                    {/* <img
                                      className="absolute top-[0px] left-[0px] rounded-borderradius w-[264px] h-[195px] object-cover"
                                      alt=""
                                      src={activity.images[0].url ? activity.images[0].url : "/placeholder.jpg"}
                                    /> */}
                                    <ImageComponent key={index} length={day.activities.length} images={activity.images} classes={"absolute top-[0px] left-[0px] rounded-borderradius w-[264px] h-[195px] object-cover"} index={index} onClick={openCarousal} />
                                    <div className="absolute top-[6px] left-[195px] w-16 h-[30px]">
                                      <div className="activity-no" style={{
                                        position: "absolute",
                                        top: "0px",
                                        left: "-180px",
                                        color: "#AD1457",
                                        fontWeight: "bold",
                                        fontSize: "20px",
                                      }}>
                                        {index + 1}
                                      </div>
                                      <div style={{ left: "29px" }} id={activity.id} target={activity.id} onClick={deleteActivity} className="absolute top-[0px] left-[34px] rounded-81xl bg-secondary-contrast overflow-hidden flex flex-col p-[5px] items-center justify-center border-[1px] border-solid border-bluegrey-50">
                                        <div className="flex flex-row items-start justify-start">
                                          <TrashIcon />
                                        </div>
                                      </div>
                                      {/* <div className="absolute top-[0px] left-[0px] rounded-81xl bg-secondary-contrast overflow-hidden flex flex-col p-[5px] items-center justify-center border-[1px] border-solid border-bluegrey-50">
                                  <div className="flex flex-row items-start justify-start">
                                  
                                  <CreateIcon />
                                  </div>
                                </div> */}
                                    </div>
                                  </div>
                                  <div className="flex flex-col items-start justify-start">
                                    <div className="flex flex-col items-start justify-start gap-[16px]">
                                      <b className="relative tracking-[0.15px] leading-[160%]">
                                        {activity.activity}
                                      </b>
                                      <div className="self-stretch relative text-sm tracking-[0.17px] leading-[143%]">
                                        {activity.summary}
                                      </div>
                                      <div className="flex flex-row items-start justify-start text-smi text-secondary-main">
                                        {/* <div className="rounded-borderradius box-border w-[136px] overflow-hidden shrink-0 flex flex-col py-1 px-2.5 items-center justify-center border-[1px] border-solid border-secondary-outlinedborder">
                                    <div className="flex flex-row items-center justify-center gap-[8px]">
                                      <img
                                        className="relative w-4 h-[18px] hidden"
                                        alt=""
                                        src="/masked-icon6.svg"
                                      />
                                      <b className="relative tracking-[0.46px] leading-[22px] capitalize">
                                        Read more
                                      </b>
                                      <img
                                        className="relative w-4 h-[18px] hidden"
                                        alt=""
                                        src="/masked-icon7.svg"
                                      />
                                    </div>
                                  </div> */}
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                {distanceMatrix ?
                                  <div className="flex flex-col items-center justify-center gap-[8px] text-sm">
                                    <div className="overflow-hidden flex flex-row p-2 items-center justify-start gap-[16px]">
                                      <div className="flex flex-row items-center justify-start">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="13" height="11" viewBox="0 0 13 11" fill="none">
                                          <path d="M1.83333 4.33334L2.83333 1.33334H10.1667L11.1667 4.33334M10.1667 7.66668C9.90145 7.66668 9.6471 7.56132 9.45956 7.37378C9.27202 7.18625 9.16667 6.93189 9.16667 6.66668C9.16667 6.40146 9.27202 6.14711 9.45956 5.95957C9.6471 5.77203 9.90145 5.66668 10.1667 5.66668C10.4319 5.66668 10.6862 5.77203 10.8738 5.95957C11.0613 6.14711 11.1667 6.40146 11.1667 6.66668C11.1667 6.93189 11.0613 7.18625 10.8738 7.37378C10.6862 7.56132 10.4319 7.66668 10.1667 7.66668ZM2.83333 7.66668C2.56812 7.66668 2.31376 7.56132 2.12623 7.37378C1.93869 7.18625 1.83333 6.93189 1.83333 6.66668C1.83333 6.40146 1.93869 6.14711 2.12623 5.95957C2.31376 5.77203 2.56812 5.66668 2.83333 5.66668C3.09855 5.66668 3.3529 5.77203 3.54044 5.95957C3.72798 6.14711 3.83333 6.40146 3.83333 6.66668C3.83333 6.93189 3.72798 7.18625 3.54044 7.37378C3.3529 7.56132 3.09855 7.66668 2.83333 7.66668ZM11.1133 1.00001C10.98 0.613344 10.6067 0.333344 10.1667 0.333344H2.83333C2.39333 0.333344 2.02 0.613344 1.88667 1.00001L0.5 5.00001V10.3333C0.5 10.5102 0.570238 10.6797 0.695262 10.8047C0.820286 10.9298 0.989856 11 1.16667 11H1.83333C2.01014 11 2.17971 10.9298 2.30474 10.8047C2.42976 10.6797 2.5 10.5102 2.5 10.3333V9.66668H10.5V10.3333C10.5 10.5102 10.5702 10.6797 10.6953 10.8047C10.8203 10.9298 10.9899 11 11.1667 11H11.8333C12.0101 11 12.1797 10.9298 12.3047 10.8047C12.4298 10.6797 12.5 10.5102 12.5 10.3333V5.00001L11.1133 1.00001Z" fill="#424242" />
                                        </svg>
                                        <div className="relative tracking-[0.17px] leading-[143%]">
                                          {distanceMatrix.rows[index] && distanceMatrix.rows[index].elements[index + 1] && distanceMatrix.rows[index].elements[index + 1].distance ? distanceMatrix.rows[index].elements[index + 1].distance.text : "0 km"}
                                        </div>
                                      </div>
                                      <div className="flex flex-row items-center justify-start">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="17" height="16" viewBox="0 0 17 16" fill="none">
                                          <path d="M12.5 14.6667L12.4933 10.6667L9.83333 8.00001L12.4933 5.32668L12.5 1.33334H4.5V5.33334L7.16667 8.00001L4.5 10.66V14.6667H12.5ZM5.83333 5.00001V2.66668H11.1667V5.00001L8.5 7.66668L5.83333 5.00001Z" fill="#424242" />
                                        </svg>
                                        <div className="relative tracking-[0.17px] leading-[143%]">
                                          {distanceMatrix.rows[index] && distanceMatrix.rows[index].elements[index + 1] && distanceMatrix.rows[index].elements[index + 1].duration ? distanceMatrix.rows[index].elements[index + 1].duration.text : "0 min"}
                                        </div>
                                      </div>
                                    </div>
                                    <div onClick={openTransportModal} data-index={index} style={{ cursor: "pointer" }} className="rounded-borderradius overflow-hidden flex flex-col py-1 px-[5px] items-center justify-center text-smi text-secondary-main">
                                      <div className="flex flex-row items-center justify-center gap-[8px]">
                                        <img
                                          className="relative w-4 h-[18px] hidden"
                                          alt=""
                                          src="/masked-icon8.svg"
                                        />
                                        <b className="relative tracking-[0.46px] leading-[22px] capitalize">
                                          Detailed transport info
                                        </b>
                                        <img
                                          className="relative w-4 h-[18px] hidden"
                                          alt=""
                                          src="/masked-icon9.svg"
                                        />
                                      </div>
                                    </div>
                                  </div>
                                  : null}
                              </div>
                            })}
                          </ReactSortable>

                        </div>
                      </div>
                    </div>
                  </div>

                </div>
              </CustomTabPanel>
            })}
            <div style={{ margin: "20px" }} className="rounded-xl bg-secondary-contrast shadow-[0px_1px_8px_rgba(0,_0,_0,_0.12),_0px_3px_4px_rgba(0,_0,_0,_0.14),_0px_3px_3px_-2px_rgba(0,_0,_0,_0.2)] flex flex-col p-4 items-start justify-start gap-[8px] border-[2px] border-solid border-tripdaddy-amber-500">
              <div className="flex flex-col items-start justify-center gap-[8px]">
                <b className="relative tracking-[0.15px] leading-[160%]">
                  Your Adventure Awaits!
                </b>
                <div className="flex flex-row py-0 px-px items-start justify-start gap-[11px] text-smi text-info-main">
                  <div className="rounded-borderradius overflow-hidden flex flex-col py-1 px-2.5 items-center justify-center border-[1px] border-solid border-info-outlinedborder">
                    {trip_data.is_saved ?
                      <div onClick={handleSave} className="flex flex-row items-center justify-center gap-[8px]">
                        <img
                          className="relative w-4 h-[18px] hidden"
                          alt=""
                          src="/masked-icon.svg"
                        />
                        <b className="relative tracking-[0.46px] leading-[22px] capitalize">
                          Saved
                        </b>
                        <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none">
                          <path d="M12.75 2.25H5.25C4.425 2.25 3.7575 2.925 3.7575 3.75L3.75 15.75L9 13.5L14.25 15.75V3.75C14.25 2.925 13.575 2.25 12.75 2.25Z" fill="#0288D1" />
                        </svg>
                      </div>
                      :
                      <div onClick={handleSave} className="flex flex-row items-center justify-center gap-[8px]">
                        <img
                          className="relative w-4 h-[18px] hidden"
                          alt=""
                          src="/masked-icon.svg"
                        />
                        <b className="relative tracking-[0.46px] leading-[22px] capitalize">
                          Save
                        </b>
                        <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 24 24"> <g> <path fill="none" d="M0 0h24v24H0z" /> <path d="M5 2h14a1 1 0 0 1 1 1v19.143a.5.5 0 0 1-.766.424L12 18.03l-7.234 4.536A.5.5 0 0 1 4 22.143V3a1 1 0 0 1 1-1zm13 2H6v15.432l6-3.761 6 3.761V4z" fill="#0288D1" /> </g> </svg>
                      </div>
                    }
                  </div>
                  <div onClick={openShareModal} className="rounded-borderradius overflow-hidden flex flex-col py-1 px-2.5 items-center justify-center border-[1px] border-solid border-info-outlinedborder">
                    <div className="flex flex-row items-center justify-center gap-[8px]">
                      <img
                        className="relative w-4 h-[18px] hidden"
                        alt=""
                        src="/masked-icon2.svg"
                      />
                      <b className="relative tracking-[0.46px] leading-[22px] capitalize">
                        Share
                      </b>
                      <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none">
                        <path d="M13.5 12.06C12.93 12.06 12.42 12.285 12.03 12.6375L6.6825 9.525C6.72 9.3525 6.75 9.18 6.75 9C6.75 8.82 6.72 8.6475 6.6825 8.475L11.97 5.3925C12.375 5.7675 12.9075 6 13.5 6C14.745 6 15.75 4.995 15.75 3.75C15.75 2.505 14.745 1.5 13.5 1.5C12.255 1.5 11.25 2.505 11.25 3.75C11.25 3.93 11.28 4.1025 11.3175 4.275L6.03 7.3575C5.625 6.9825 5.0925 6.75 4.5 6.75C3.255 6.75 2.25 7.755 2.25 9C2.25 10.245 3.255 11.25 4.5 11.25C5.0925 11.25 5.625 11.0175 6.03 10.6425L11.37 13.7625C11.3325 13.92 11.31 14.085 11.31 14.25C11.31 15.4575 12.2925 16.44 13.5 16.44C14.7075 16.44 15.69 15.4575 15.69 14.25C15.69 13.0425 14.7075 12.06 13.5 12.06Z" fill="#0288D1" />
                      </svg>
                    </div>
                  </div>
                </div>
              </div>
              <div className="relative text-sm tracking-[0.17px] leading-[143%] text-black inline-block w-[296px]">
                <p className="m-0">
                  Your itinerary is just a starting point! Feel free to swap out
                  any activities for others that better suit your preferences
                  and interests.
                </p>
                <p className="m-0">
                  Share the itinerary with your loved ones so you can plan
                  together
                </p>
                <p className="m-0">It is all about making it uniquely yours</p>
              </div>
              <div className="flex flex-col items-start justify-start gap-[8px] text-base">
                <div className="flex flex-row items-center justify-center gap-[8px]">
                  <div className="flex flex-row items-center justify-start gap-[8px]">
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                      <path d="M13.333 7.99996C13.333 7.26663 13.933 6.66663 14.6663 6.66663V3.99996C14.6663 3.26663 14.0663 2.66663 13.333 2.66663H2.66634C1.93301 2.66663 1.33967 3.26663 1.33967 3.99996V6.66663C2.07301 6.66663 2.66634 7.26663 2.66634 7.99996C2.66634 8.73329 2.07301 9.33329 1.33301 9.33329V12C1.33301 12.7333 1.93301 13.3333 2.66634 13.3333H13.333C14.0663 13.3333 14.6663 12.7333 14.6663 12V9.33329C13.933 9.33329 13.333 8.73329 13.333 7.99996ZM10.3863 11.2L7.99967 9.66663L5.61301 11.2L6.33301 8.45329L4.13967 6.65996L6.96634 6.49329L7.99967 3.86663L9.02634 6.49996L11.853 6.66663L9.65967 8.45996L10.3863 11.2Z" fill="#424242" />
                    </svg>
                    <div className="relative tracking-[0.15px] leading-[150%] font-semibold">
                      Activities:
                    </div>
                  </div>
                  <div className="flex flex-col items-start justify-start">
                    <div className="relative tracking-[0.15px] leading-[150%]">
                      {sum(itinerary.map((day, index) => {
                        return day.activities.length
                      }))} (free to change them)
                    </div>
                  </div>
                </div>
                <div className="flex flex-row items-center justify-center gap-[8px]">
                  <div className="flex flex-row items-center justify-start gap-[8px]">
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                      <path d="M13.333 1.99996H12.6663V0.666626H11.333V1.99996H4.66634V0.666626H3.33301V1.99996H2.66634C1.93301 1.99996 1.33301 2.59996 1.33301 3.33329V14C1.33301 14.7333 1.93301 15.3333 2.66634 15.3333H13.333C14.0663 15.3333 14.6663 14.7333 14.6663 14V3.33329C14.6663 2.59996 14.0663 1.99996 13.333 1.99996ZM13.333 14H2.66634V5.33329H13.333V14Z" fill="#424242" />
                    </svg>
                    <div className="relative tracking-[0.15px] leading-[150%] font-semibold">
                      Duration:
                    </div>
                  </div>
                  <div className="flex flex-row items-center justify-start">
                    <div className="relative tracking-[0.15px] leading-[150%]">
                      {trip_data.days} days
                    </div>
                  </div>
                </div>
                <div className="flex flex-row items-center justify-start gap-[8px]">
                  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                    <path d="M7.99967 1.33337C5.41967 1.33337 3.33301 3.42004 3.33301 6.00004C3.33301 9.50004 7.99967 14.6667 7.99967 14.6667C7.99967 14.6667 12.6663 9.50004 12.6663 6.00004C12.6663 3.42004 10.5797 1.33337 7.99967 1.33337ZM7.99967 7.66671C7.07967 7.66671 6.33301 6.92004 6.33301 6.00004C6.33301 5.08004 7.07967 4.33337 7.99967 4.33337C8.91967 4.33337 9.66634 5.08004 9.66634 6.00004C9.66634 6.92004 8.91967 7.66671 7.99967 7.66671Z" fill="#424242" />
                  </svg>
                  <div className="relative tracking-[0.15px] leading-[150%] font-semibold inline-block w-[131px] shrink-0">
                    {trip_data.city_or_address}
                  </div>
                </div>
              </div>
            </div>

          </div>

          <div id="clear" style={{ clear: "both" }}></div>
        </div>
        <Footer />
        <ShareModal open={shareModalOpen} handleClose={closeShareModal} handleShare={handleShare} />
        <ActivitiesModal action={addActivityAction} trip_data={trip_data} itinerary={itinerary} open={ActivitiesModalOpen} handleClose={closeActivitiesModal} />
        {transportModalOpen && (
          <PortalPopup
            overlayColor="rgba(113, 113, 113, 0.3)"
            placement="Centered"
            onOutsideClick={closeTransportModal}
          >
            <TransportInformationModal data={transportModalData} handleClose={closeTransportModal} />
          </PortalPopup>
        )}
      </>
    );
  }


  return (
    <ItineraryPageMapOnHorizonRoot id="it-page">
      <Header>
      </Header>
      <Container id="main-container" style={{ height: "4200px", overflow: "scroll" }}>
        <ItineraryHeroSection>
          <ShapeIcon alt="" src="/shape.svg" />
          <ItineraryHero id="it-info" style={{ backgroundImage: `url("${getHeroImageURL()}")` }}>
            <EnchantingAdventuresThroughParent>
              <EnchantingAdventuresThrough>
                Trip to {trip_data.city_or_address}
              </EnchantingAdventuresThrough>
              <Duration4Days>Duration: {itinerary.length} days</Duration4Days>
            </EnchantingAdventuresThroughParent>
          </ItineraryHero>
        </ItineraryHeroSection>
        {<div
          id="PlanRoot"
          className="bg-[#fff8e1] flex flex-row justify-between h-16 items-center px-6 rounded-[20px]"
          style={{
            position: "absolute",
            top: "620px",
            width: "96%",
            zIndex: "10"
          }}
        >
          <div className="flex flex-row gap-2 items-start">
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
              <path d="M12 2C8.13 2 5 5.13 5 9C5 14.25 12 22 12 22C12 22 19 14.25 19 9C19 5.13 15.87 2 12 2ZM12 11.5C10.62 11.5 9.5 10.38 9.5 9C9.5 7.62 10.62 6.5 12 6.5C13.38 6.5 14.5 7.62 14.5 9C14.5 10.38 13.38 11.5 12 11.5Z" fill="#424242" />
            </svg>
            <div className="font-['Quicksand'] tracking-[0.15] leading-[24px] text-black/87">
              {trip_data.cheapest_travel_date.from ? trip_data.cheapest_travel_date.from : "N/A"} {"->"} {trip_data.cheapest_travel_date.to ? trip_data.cheapest_travel_date.to : "N/A"} {"->"} {trip_data.cheapest_travel_date.from ? trip_data.cheapest_travel_date.from : "N/A"}
            </div>
          </div>


          <div className="flex flex-row gap-2 items-start">
            <img
              src="https://file.rendit.io/n/J9ImB7vZr33ULALG1vmm.svg"
              id="FamilyRestroomFilled"
              className="w-6 shrink-0"
            />
            <div className="font-['Quicksand'] tracking-[0.15] leading-[24px] text-black/87">
              {trip_data.cheapest_travel_date.travellers ? trip_data.cheapest_travel_date.travellers.adult : 1} Adults, {trip_data.cheapest_travel_date.travellers ? trip_data.cheapest_travel_date.travellers.child : 0} child
            </div>
          </div>
          <div className="flex flex-row justify-between gap-2 items-start">
            <img
              src="https://file.rendit.io/n/8Dh5uOqHupE3cLp1fBgo.svg"
              id="Cash"
              className="w-6 shrink-0"
            />
            <div className="font-['Quicksand'] tracking-[0.15] leading-[24px] text-black/87">
              ${trip_data.cheapest_travel_date.minPrice ? trip_data.cheapest_travel_date.minPrice.amount : "N/A"}
            </div>
            <div className="font-['Quicksand'] tracking-[0.15] leading-[24px] text-black/87">
              USD
            </div>
          </div>
          <div className="flex flex-row gap-6 w-1/2 items-start">
            <div className="flex flex-row gap-2 w-1/2 items-start">
              <img
                src="https://file.rendit.io/n/laKWFp2fyO2WPsGyEFJY.svg"
                id="DateRangeFilled"
                className="w-6 shrink-0"
              />
              <div className="font-['Quicksand'] tracking-[0.15] leading-[24px] text-black/87">
                {showTravelDate(trip_data.cheapest_travel_date.outboundLeg)} - {showTravelDate(trip_data.cheapest_travel_date.inboundLeg)}
              </div>
            </div>

            {/* <div className="font-['Quicksand'] tracking-[0.15] leading-[24px] text-black/87">
              2024: Sep 1 - Sep 5
            </div> */}
          </div>
          <div className="flex flex-row gap-2 items-start" style={{
            cursor: "pointer",
          }} onClick={refresh_flight} data-dest_airport={trip_data.cheapest_travel_date.to ? trip_data.cheapest_travel_date.to : ""} >
            <img src="/refresh.png" className="w-6 shrink-0" />
            {/* <div style={{
              width: "50px",
            }}>

            </div> */}
          </div>
        </div>}

        <Overview showDetails={showDetails} setShowDetails={setShowDetails} ref={overviewRef} itinerary={itinerary} trip_data={trip_data} setTripData={setTripData} />

        <ItineraryDetailsContainer>
          {trip_data.cheapest_travel_date ? <div style={{ height: (parseInt(overviewHeight) + 80) + "px" }} ></div> : null}
          <Controls id="controls">
            <Box sx={{ width: '100%', marginLeft: "20px" }}>
              <Box id="allcontrolscontainer" sx={{ borderBottom: 0, borderColor: 'divider', display: "flex", flexDirection: "row", justifyContent: "space-between", fontFamily: "Quicksand" }}>
                <Tabs value={value} TabIndicatorProps={{ style: { background: "var(--primary-main, #D81B60)" } }} textColor="secondary" onChange={handleDayChange} aria-label="itinerary days">
                  {itinerary.map((day, index) => {
                    return <Tab label={`Day ${index + 1}`} {...a11yProps(index)} style={{ color: "var(--primary-main, #D81B60)", }} />
                  })
                  }
                  <div style={{ width: "50px", alignItems: "center", display: "flex", flexDirection: "row", justifyContent: "flex-end" }}>
                    <AddCircleOutlineIcon onClick={handleAddDay} style={{ cursor: "pointer", color: "var(--primary-main, #D81B60)", marginRight: "20px" }} />
                  </div>
                </Tabs>
                <div style={{ display: "flex", flexDirection: "row", width: "210px", justifyContent: "space-between" }}>
                  {trip_data.is_saved ? <Button
                    variant="outlined"
                    color="primary"
                    onClick={handleSave}
                    endIcon={<BookmarkIcon />}>
                    Saved
                  </Button>
                    :
                    <Button
                      variant="outlined"
                      color="primary"
                      onClick={handleSave}
                      endIcon={<BookmarkBorderOutlinedIcon />}>
                      Save
                    </Button>
                  }
                  <Button
                    variant="outlined"
                    onClick={openShareModal}
                    color="primary"
                    endIcon={<ShareIcon />}>
                    Share
                  </Button>
                </div>

              </Box>

            </Box>
          </Controls>
          {itinerary.map((day, index) => {
            const locations = day.activities.length > 0 ? day.activities.map((activity) => {
              return activity.place_details.candidates[0] ? activity.place_details.candidates[0].geometry.location : { lat: 0, lng: 0 };
            }) : [trip_data.airport.candidates[0] ? trip_data.airport.candidates[0].geometry.location : { lat: 0, lng: 0 }];
            var activities = day.activities;
            activities.trip_data = trip_data;
            return <CustomTabPanel value={value} index={index}>
              <DayDetails id="day-details">
                <DayTitle id="heading-and-button-container">
                  <DayHeading id="day-heading">
                    {day.theme}
                  </DayHeading>
                </DayTitle>

                <Map1 id="map-container">
                  <Title1>
                    <Location1 style={{
                      color: "var(--text-primary, rgba(0, 0, 0, 0.87))",
                      fontFamily: "Quicksand",
                      fontSize: "24px",
                      fontStyle: "normal",
                      fontWeight: "700",
                      lineHeight: "133.4%"
                    }}>Map:</Location1>
                  </Title1>
                  <MapComponent activities={activities} locations={locations}></MapComponent>
                  <div style={{
                    width: "100%",
                    alignItems: "center",

                  }}
                    className="flex flex-col text-sm gap-[8px]">
                    <div onClick={() => handleGetDirectionsClick(activities, trip_data)} style={{ cursor: "pointer" }} className="flex flex-row items-center  text-secondary-main">
                      <div className="flex flex-row items-center justify-center gap-[8px]">
                        <img
                          className="relative w-4 h-[18px] hidden"
                          alt=""
                          src="/masked-icon8.svg"
                        />
                        <b className="relative tracking-[0.46px] leading-[22px] capitalize">
                          Get Directions
                        </b>
                        <img
                          className="relative w-4 h-[18px] hidden"
                          alt=""
                          src="/masked-icon9.svg"
                        />
                      </div>
                    </div>
                  </div>

                </Map1>

                {trip_data.airport && ((value === 0) || (itinerary[value].activities.length == 0)) ? <Map1 id="airport_container">
                  <Title1>
                    <Location1 style={{
                      color: "var(--text-primary, rgba(0, 0, 0, 0.87))",
                      fontFamily: "Quicksand",
                      fontSize: "24px",
                      fontStyle: "normal",
                      fontWeight: "700",
                      lineHeight: "133.4%"

                    }}>Arriving At:</Location1>

                  </Title1>
                  <ActivityCardBig style={{

                  }} id="activity card big" className="darken-on-hover bigcard" >
                    {/* <ActivityImage
                      alt=""
                      src={trip_data.airport.images ? trip_data.airport.images[0].url : "/placeholder.png"}
                      style={{ cursor: "pointer" }}
                      data-index={"airport"}
                      onClick={openCarousal}
                    /> */}

                    <ImageComponent images={trip_data.airport.images} style={{
                      cursor: "pointer",
                      position: "relative",
                      borderRadius: "4px",
                      width: "285px",
                      height: "190px",
                      objectFit: "cover"

                    }} index={"airport"} onClick={openCarousal} />

                    <Description>
                      <Text1>
                        <ActivityTitle style={{
                          fontFamily: "Quicksand",
                          fontSize: "24px",
                          fontStyle: "normal",
                          fontWeight: "700",
                          lineHeight: "133.4%"
                        }}>
                          {trip_data.airport.candidates[0] ? trip_data.airport.candidates[0].name : "N/A"}
                          <div className="deleteicon" style={{ position: "relative", top: "-2px", right: "0px", cursor: "pointer", zIndex: "10" }} id="airport-delete-icon"
                            onClick={handleAirportDelete}  >
                            <TrashIcon />
                          </div>
                        </ActivityTitle>
                        <ActivityDescription style={{
                          fontFamily: "Quicksand"
                        }}>
                          {trip_data.airport.candidates[0] ? trip_data.airport.candidates[0].formatted_address : "N/A"}
                        </ActivityDescription>
                      </Text1>
                    </Description>
                  </ActivityCardBig>
                  {distanceMatrix ?
                    <div style={{ width: "100%" }} className="flex flex-col items-center justify-center gap-[8px] text-sm">
                      <div className="overflow-hidden flex flex-row p-2 items-center justify-start gap-[16px]">
                        <div className="flex flex-row items-center justify-start">
                          <svg xmlns="http://www.w3.org/2000/svg" width="13" height="11" viewBox="0 0 13 11" fill="none">
                            <path d="M1.83333 4.33334L2.83333 1.33334H10.1667L11.1667 4.33334M10.1667 7.66668C9.90145 7.66668 9.6471 7.56132 9.45956 7.37378C9.27202 7.18625 9.16667 6.93189 9.16667 6.66668C9.16667 6.40146 9.27202 6.14711 9.45956 5.95957C9.6471 5.77203 9.90145 5.66668 10.1667 5.66668C10.4319 5.66668 10.6862 5.77203 10.8738 5.95957C11.0613 6.14711 11.1667 6.40146 11.1667 6.66668C11.1667 6.93189 11.0613 7.18625 10.8738 7.37378C10.6862 7.56132 10.4319 7.66668 10.1667 7.66668ZM2.83333 7.66668C2.56812 7.66668 2.31376 7.56132 2.12623 7.37378C1.93869 7.18625 1.83333 6.93189 1.83333 6.66668C1.83333 6.40146 1.93869 6.14711 2.12623 5.95957C2.31376 5.77203 2.56812 5.66668 2.83333 5.66668C3.09855 5.66668 3.3529 5.77203 3.54044 5.95957C3.72798 6.14711 3.83333 6.40146 3.83333 6.66668C3.83333 6.93189 3.72798 7.18625 3.54044 7.37378C3.3529 7.56132 3.09855 7.66668 2.83333 7.66668ZM11.1133 1.00001C10.98 0.613344 10.6067 0.333344 10.1667 0.333344H2.83333C2.39333 0.333344 2.02 0.613344 1.88667 1.00001L0.5 5.00001V10.3333C0.5 10.5102 0.570238 10.6797 0.695262 10.8047C0.820286 10.9298 0.989856 11 1.16667 11H1.83333C2.01014 11 2.17971 10.9298 2.30474 10.8047C2.42976 10.6797 2.5 10.5102 2.5 10.3333V9.66668H10.5V10.3333C10.5 10.5102 10.5702 10.6797 10.6953 10.8047C10.8203 10.9298 10.9899 11 11.1667 11H11.8333C12.0101 11 12.1797 10.9298 12.3047 10.8047C12.4298 10.6797 12.5 10.5102 12.5 10.3333V5.00001L11.1133 1.00001Z" fill="#424242" />
                          </svg>
                          <div className="relative tracking-[0.17px] leading-[143%]">
                            {distanceMatrix.rows[index] && distanceMatrix.rows[index].elements[index + 1] && distanceMatrix.rows[index].elements[index + 1].distance ? distanceMatrix.rows[index].elements[index + 1].distance.text : "0 km"}
                          </div>
                        </div>
                        <div className="flex flex-row items-center justify-start">
                          <svg xmlns="http://www.w3.org/2000/svg" width="17" height="16" viewBox="0 0 17 16" fill="none">
                            <path d="M12.5 14.6667L12.4933 10.6667L9.83333 8.00001L12.4933 5.32668L12.5 1.33334H4.5V5.33334L7.16667 8.00001L4.5 10.66V14.6667H12.5ZM5.83333 5.00001V2.66668H11.1667V5.00001L8.5 7.66668L5.83333 5.00001Z" fill="#424242" />
                          </svg>
                          <div className="relative tracking-[0.17px] leading-[143%]">
                            {distanceMatrix.rows[index] && distanceMatrix.rows[index].elements[index + 1] && distanceMatrix.rows[index].elements[index + 1].duration ? distanceMatrix.rows[index].elements[index + 1].duration.text : "0 min"}
                          </div>
                        </div>
                        <div onClick={openTransportModal} data-index={"airport"} style={{ cursor: "pointer" }} className="flex flex-row items-center justify-start text-secondary-main">
                          <div className="flex flex-row items-center justify-center gap-[8px]">
                            <img
                              className="relative w-4 h-[18px] hidden"
                              alt=""
                              src="/masked-icon8.svg"
                            />
                            <b className="relative tracking-[0.46px] leading-[22px] capitalize">
                              Detailed transport info
                            </b>
                            <img
                              className="relative w-4 h-[18px] hidden"
                              alt=""
                              src="/masked-icon9.svg"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    : null}
                </Map1>
                  :
                  null
                }



                {trip_data.staying_at ? <Map1 id="stay_container">
                  <Title1>
                    <Location1>Staying At:</Location1>

                  </Title1>
                  <ActivityCardBig id="activity card big" className="darken-on-hover bigcard" >
                    {/* <ActivityImage
                      alt=""
                      src={trip_data.staying_at.photos[0].url}
                      style={{ cursor: "pointer" }}
                      data-index={"stay"}
                      onClick={openCarousal}
                    /> */}
                    <ImageComponent images={trip_data.staying_at.photos} style={{
                      cursor: "pointer",
                      position: "relative",
                      borderRadius: "4px",
                      width: "285px",
                      height: "190px",
                      objectFit: "cover"

                    }} index={"stay"} onClick={openCarousal} />

                    <Description>
                      <Text1>
                        <ActivityTitle style={{
                          fontFamily: "Quicksand",
                          fontSize: "24px",
                          fontStyle: "normal",
                          fontWeight: "700",
                          lineHeight: "133.4%"
                        }}>
                          {trip_data.staying_at.place_details.name}
                          <div className="deleteicon" style={{ position: "relative", top: "-2px", right: "0px", cursor: "pointer", zIndex: "10" }} id="stay-delete-icon"
                            onClick={handleStayDelete}  >
                            <TrashIcon />
                          </div>
                        </ActivityTitle>
                        <ActivityDescription style={{
                          fontFamily: "Quicksand"
                        }}>
                          {trip_data.staying_at.place_details.formatted_address}
                        </ActivityDescription>
                      </Text1>
                    </Description>
                  </ActivityCardBig>
                  {distanceMatrix ?
                    <div style={{ width: "100%" }} className="flex flex-col items-center justify-center gap-[8px] text-sm">
                      <div className="overflow-hidden flex flex-row p-2 items-center justify-start gap-[16px]">
                        <div className="flex flex-row items-center justify-start">
                          <svg xmlns="http://www.w3.org/2000/svg" width="13" height="11" viewBox="0 0 13 11" fill="none">
                            <path d="M1.83333 4.33334L2.83333 1.33334H10.1667L11.1667 4.33334M10.1667 7.66668C9.90145 7.66668 9.6471 7.56132 9.45956 7.37378C9.27202 7.18625 9.16667 6.93189 9.16667 6.66668C9.16667 6.40146 9.27202 6.14711 9.45956 5.95957C9.6471 5.77203 9.90145 5.66668 10.1667 5.66668C10.4319 5.66668 10.6862 5.77203 10.8738 5.95957C11.0613 6.14711 11.1667 6.40146 11.1667 6.66668C11.1667 6.93189 11.0613 7.18625 10.8738 7.37378C10.6862 7.56132 10.4319 7.66668 10.1667 7.66668ZM2.83333 7.66668C2.56812 7.66668 2.31376 7.56132 2.12623 7.37378C1.93869 7.18625 1.83333 6.93189 1.83333 6.66668C1.83333 6.40146 1.93869 6.14711 2.12623 5.95957C2.31376 5.77203 2.56812 5.66668 2.83333 5.66668C3.09855 5.66668 3.3529 5.77203 3.54044 5.95957C3.72798 6.14711 3.83333 6.40146 3.83333 6.66668C3.83333 6.93189 3.72798 7.18625 3.54044 7.37378C3.3529 7.56132 3.09855 7.66668 2.83333 7.66668ZM11.1133 1.00001C10.98 0.613344 10.6067 0.333344 10.1667 0.333344H2.83333C2.39333 0.333344 2.02 0.613344 1.88667 1.00001L0.5 5.00001V10.3333C0.5 10.5102 0.570238 10.6797 0.695262 10.8047C0.820286 10.9298 0.989856 11 1.16667 11H1.83333C2.01014 11 2.17971 10.9298 2.30474 10.8047C2.42976 10.6797 2.5 10.5102 2.5 10.3333V9.66668H10.5V10.3333C10.5 10.5102 10.5702 10.6797 10.6953 10.8047C10.8203 10.9298 10.9899 11 11.1667 11H11.8333C12.0101 11 12.1797 10.9298 12.3047 10.8047C12.4298 10.6797 12.5 10.5102 12.5 10.3333V5.00001L11.1133 1.00001Z" fill="#424242" />
                          </svg>
                          <div className="relative tracking-[0.17px] leading-[143%]">
                            {distanceMatrix.rows[index] && distanceMatrix.rows[index].elements[index + 1] && distanceMatrix.rows[index].elements[index + 1].distance ? distanceMatrix.rows[index].elements[index + 1].distance.text : "0 km"}
                          </div>
                        </div>
                        <div className="flex flex-row items-center justify-start">
                          <svg xmlns="http://www.w3.org/2000/svg" width="17" height="16" viewBox="0 0 17 16" fill="none">
                            <path d="M12.5 14.6667L12.4933 10.6667L9.83333 8.00001L12.4933 5.32668L12.5 1.33334H4.5V5.33334L7.16667 8.00001L4.5 10.66V14.6667H12.5ZM5.83333 5.00001V2.66668H11.1667V5.00001L8.5 7.66668L5.83333 5.00001Z" fill="#424242" />
                          </svg>
                          <div className="relative tracking-[0.17px] leading-[143%]">
                            {distanceMatrix.rows[index] && distanceMatrix.rows[index].elements[index + 1] && distanceMatrix.rows[index].elements[index + 1].duration ? distanceMatrix.rows[index].elements[index + 1].duration.text : "0 min"}
                          </div>
                        </div>
                        <div onClick={openTransportModal} data-index={"stay"} style={{ cursor: "pointer" }} className="flex flex-row items-center justify-start text-secondary-main">
                          <div className="flex flex-row items-center justify-center gap-[8px]">
                            <img
                              className="relative w-4 h-[18px] hidden"
                              alt=""
                              src="/masked-icon8.svg"
                            />
                            <b className="relative tracking-[0.46px] leading-[22px] capitalize">
                              Detailed transport info
                            </b>
                            <img
                              className="relative w-4 h-[18px] hidden"
                              alt=""
                              src="/masked-icon9.svg"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    : null}
                </Map1>
                  :
                  null
                }
                <Actvities id="activity-container">
                  <TitleParent id="activities-heading">
                    <Title1>
                      <Location1>My Options:</Location1>
                    </Title1>
                    <Button12 onClick={openActivitiesModal} style={{ cursor: "pointer" }}>
                      <Base>
                        <MaskedIcon alt="" src="/masked-icon.svg" />
                        <Button13>Add Activity</Button13>
                        <AddIcon />
                      </Base>
                    </Button12>
                  </TitleParent>

                  <ActivityCards>
                    <ReactSortable list={day.activities} setList={(s) => {
                      const newItinerary = itinerary;
                      newItinerary[value].activities = s;
                      setChangedPos(newItinerary);

                    }} id="draggable">
                      {day.activities.map((activity, index) => {
                        return <div key={index} className="flex-item" > <ActivityCardBig id="activity card big" className="darken-on-hover bigcard">
                          {/* <ActivityImage
                            alt=""
                            src={activity.images[0].url}
                            style={{ cursor: "pointer" }}
                            data-index={index}
                            onClick={openCarousal}
                          /> */}
                          <ImageComponent key={index} length={day.activities.length} images={activity.images} style={{
                            cursor: "pointer",
                            position: "relative",
                            borderRadius: "4px",
                            width: "285px",
                            height: "190px",
                            objectFit: "cover"

                          }} index={index} onClick={openCarousal} />

                          <Description>
                            <Text1>
                              <ActivityTitle>
                                <div className="activity-no" style={{
                                  position: "absolute",
                                  top: "-20px",
                                  left: "-300px",
                                  color: "#AD1457",
                                  fontWeight: "bold",
                                  fontSize: "20px",
                                }}>
                                  {index + 1}
                                </div>
                                {activity.activity}
                                <div className="deleteicon" style={{ position: "relative", top: "-2px", right: "0px", cursor: "pointer", zIndex: "10" }} id={activity.id} target={activity.id} onClick={deleteActivity}>
                                  <TrashIcon />
                                </div>
                              </ActivityTitle>
                              <ActivityDescription>
                                <p>
                                  {activity.summary}
                                  <a href="/" className="readmore" style={{ pointer: "cursor", color: "blue", zIndex: "10" }} id={activity.id} target={activity.id} onClick={hangleActivityDetailCLick} >  ... read more</a>
                                </p>

                              </ActivityDescription>
                            </Text1>
                          </Description>
                        </ActivityCardBig>
                          {distanceMatrix ?
                            <div style={{ width: "100%" }} className="flex flex-col items-center justify-center gap-[8px] text-sm">
                              <div className="overflow-hidden flex flex-row p-2 items-center justify-start gap-[16px]">
                                <div className="flex flex-row items-center justify-start">
                                  <svg xmlns="http://www.w3.org/2000/svg" width="13" height="11" viewBox="0 0 13 11" fill="none">
                                    <path d="M1.83333 4.33334L2.83333 1.33334H10.1667L11.1667 4.33334M10.1667 7.66668C9.90145 7.66668 9.6471 7.56132 9.45956 7.37378C9.27202 7.18625 9.16667 6.93189 9.16667 6.66668C9.16667 6.40146 9.27202 6.14711 9.45956 5.95957C9.6471 5.77203 9.90145 5.66668 10.1667 5.66668C10.4319 5.66668 10.6862 5.77203 10.8738 5.95957C11.0613 6.14711 11.1667 6.40146 11.1667 6.66668C11.1667 6.93189 11.0613 7.18625 10.8738 7.37378C10.6862 7.56132 10.4319 7.66668 10.1667 7.66668ZM2.83333 7.66668C2.56812 7.66668 2.31376 7.56132 2.12623 7.37378C1.93869 7.18625 1.83333 6.93189 1.83333 6.66668C1.83333 6.40146 1.93869 6.14711 2.12623 5.95957C2.31376 5.77203 2.56812 5.66668 2.83333 5.66668C3.09855 5.66668 3.3529 5.77203 3.54044 5.95957C3.72798 6.14711 3.83333 6.40146 3.83333 6.66668C3.83333 6.93189 3.72798 7.18625 3.54044 7.37378C3.3529 7.56132 3.09855 7.66668 2.83333 7.66668ZM11.1133 1.00001C10.98 0.613344 10.6067 0.333344 10.1667 0.333344H2.83333C2.39333 0.333344 2.02 0.613344 1.88667 1.00001L0.5 5.00001V10.3333C0.5 10.5102 0.570238 10.6797 0.695262 10.8047C0.820286 10.9298 0.989856 11 1.16667 11H1.83333C2.01014 11 2.17971 10.9298 2.30474 10.8047C2.42976 10.6797 2.5 10.5102 2.5 10.3333V9.66668H10.5V10.3333C10.5 10.5102 10.5702 10.6797 10.6953 10.8047C10.8203 10.9298 10.9899 11 11.1667 11H11.8333C12.0101 11 12.1797 10.9298 12.3047 10.8047C12.4298 10.6797 12.5 10.5102 12.5 10.3333V5.00001L11.1133 1.00001Z" fill="#424242" />
                                  </svg>
                                  <div className="relative tracking-[0.17px] leading-[143%]">
                                    {distanceMatrix.rows[index] && distanceMatrix.rows[index].elements[index + 1] && distanceMatrix.rows[index].elements[index + 1].distance ? distanceMatrix.rows[index].elements[index + 1].distance.text : "0 km"}
                                  </div>
                                </div>
                                <div className="flex flex-row items-center justify-start">
                                  <svg xmlns="http://www.w3.org/2000/svg" width="17" height="16" viewBox="0 0 17 16" fill="none">
                                    <path d="M12.5 14.6667L12.4933 10.6667L9.83333 8.00001L12.4933 5.32668L12.5 1.33334H4.5V5.33334L7.16667 8.00001L4.5 10.66V14.6667H12.5ZM5.83333 5.00001V2.66668H11.1667V5.00001L8.5 7.66668L5.83333 5.00001Z" fill="#424242" />
                                  </svg>
                                  <div className="relative tracking-[0.17px] leading-[143%]">
                                    {distanceMatrix.rows[index] && distanceMatrix.rows[index].elements[index + 1] && distanceMatrix.rows[index].elements[index + 1].duration ? distanceMatrix.rows[index].elements[index + 1].duration.text : "0 min"}
                                  </div>
                                </div>
                                <div onClick={openTransportModal} data-index={index} style={{ cursor: "pointer" }} className="flex flex-row items-center justify-start text-secondary-main">
                                  <div className="flex flex-row items-center justify-center gap-[8px]">
                                    <img
                                      className="relative w-4 h-[18px] hidden"
                                      alt=""
                                      src="/masked-icon8.svg"
                                    />
                                    <b className="relative tracking-[0.46px] leading-[22px] capitalize">
                                      Detailed transport info
                                    </b>
                                    <img
                                      className="relative w-4 h-[18px] hidden"
                                      alt=""
                                      src="/masked-icon9.svg"
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>
                            : null}
                        </div>
                      })
                      }
                    </ReactSortable>
                  </ActivityCards>
                </Actvities>
              </DayDetails>
            </CustomTabPanel>


          })
          }

        </ItineraryDetailsContainer>
        <ShareModal open={shareModalOpen} handleClose={closeShareModal} handleShare={handleShare} />
        <ActivitiesModal action={addActivityAction} trip_data={trip_data} itinerary={itinerary} open={ActivitiesModalOpen} handleClose={closeActivitiesModal} />

        {transportModalOpen && (
          <PortalPopup
            overlayColor="rgba(113, 113, 113, 0.3)"
            placement="Centered"
            onOutsideClick={closeTransportModal}
          >
            <TransportInfoModal data={transportModalData} handleClose={closeTransportModal} />
          </PortalPopup>
        )}
        {/* <TransportInformationModal open={transportModalOpen} handleClose={closeTransportModal} data={transportModalData} /> */}
      </Container>
      <div id="clear" style={{ clear: "both", height: "4200px" }}></div>
      <Footer />
    </ItineraryPageMapOnHorizonRoot>
  );
};

export default ViewItinerary;
