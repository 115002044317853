import React,{useEffect} from 'react';
import axios from "axios"; 
import {useNavigate} from 'react-router-dom';


const Logout = () => {

    const navigate = useNavigate();
    localStorage.removeItem('token');

    useEffect(() => {
      setTimeout(() => {
        navigate('/', { replace: true });
      }, 500);
    }, []);
  
    return <div>Logging Out...</div>;
};

export default Logout;
