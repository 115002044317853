import React from 'react';
import MainHeader from '../components/Header';
import Footer from '../components/Footer';

const PrivacyPolicy = () => {
  return (
    <>
    <MainHeader />
    <div style={{padding: "20px"}}>
      <h1>TripDaddy Privacy Policy</h1>
      <p>
        <h2>Introduction</h2> This Privacy Policy applies to TripDaddy, owned by GoodVibesOnlyAI Inc., a Delaware-based C-Corporation. It governs the collection, use, and sharing of personal data through our website and services. By using TripDaddy, you agree to the practices described in this Policy.
      </p>
      <p>
        <h2>Scope of Policy</h2> This Policy covers how we collect and use your personal data when you use TripDaddy services, including but not limited to travel itinerary creation, collaboration with other users, and use of third-party services. It also details your rights and our obligations in relation to your personal data.
      </p>
      <p>
        <h2>Data Collection</h2> We collect the following categories of personal data:
      </p>
      <ul>
        <li>Personal Identifiers: Including your name, email, home airport, travel preferences, IP address, and account details.</li>
        <li>Commercial Information: Details of services you engage with on our platform.</li>
        <li>Internet or Network Activity: Your interaction with our website and services.</li>
        <li>Geolocation Information: Derived from your device to enhance our service offerings.</li>
        <li>Inferences: Drawn from your data to personalize your experience.</li>
      </ul>
      <p>
        <h2>Use of Data</h2> We use your data for:
      </p>
      <ul>
        <li>Providing and improving our services.</li>
        <li>Personalizing your experience.</li>
        <li>Operational necessities and securing our services.</li>
        <li>Compliance with legal obligations.</li>
      </ul>
      <p>
        <h2>Sharing of Data</h2>
      </p>
      <ul>
        <li>We share data with third parties essential for providing our services.</li>
        <li>We may disclose data to comply with legal processes and protect our rights.</li>
        <li>Your data is not sold, rented, or loaned to third parties for monetary gain.</li>
      </ul>
      <p>
        <h2>Data Retention</h2> Your data is kept for as long as necessary to provide you services and as required for legal and business purposes.
      </p>
      <p>
        <h2>Children’s Data</h2> TripDaddy is not intended for children under 16 years of age. We do not knowingly collect data from children under this age.
      </p>
      <p>
        <h2>Cookies and Tracking</h2> We use cookies for website functionality and analytics. You can adjust your browser settings to control cookie use.
      </p>
      <p>
        <h2>Your Data Control</h2> You have rights regarding the access, correction, and deletion of your personal data. Requests can be made as per the procedures outlined in this policy.
      </p>
      <p>
        <h2>Region and Service Specific Notices</h2> For users in specific regions, additional privacy information and rights may apply. Please refer to the applicable section of this policy.
      </p>
      <p>
        <h2>Complaints and Contact Information</h2> For complaints or queries, contact us at hello@tripdaddy.app
      </p>
      <p>
        <h2>Changes to this Policy</h2> We may update this policy to reflect changes in our practices. Changes will be communicated via updates to this document.
      </p>
    </div>
    <Footer />
    </>
  );
};

export default PrivacyPolicy;
