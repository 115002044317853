import { useCallback } from "react";
import { Autocomplete, TextField } from "@mui/material";
import styled from "styled-components";
import InputAdornment from '@mui/material/InputAdornment';


const AutocompleteRoot = styled(Autocomplete)`
  cursor: pointer;
`;
const AutoCompleteForm1 = () => {
    const onAutocompleteClick = useCallback(() => {
        // Please sync "✔️  Landing page - dropdown opened" to the project
    }, []);
    return (
        <TextField style={{ width: "292px" }}
            name="interests"
            placeholder="Iconic Sights, Kids Activities, Food"
            label="Activities"
            InputProps={{
                startAdornment: (
                    <InputAdornment position="start">
                    </InputAdornment>
                ),
            }}
            variant="standard"
        />
    )

    //   return (
    //     <AutocompleteRoot
    //       sx={{ width: 295 }}
    //       disablePortal
    //       options={[]}
    //       renderInput={(params) => (
    //         <TextField
    //           {...params}
    //           color="primary"
    //           label="Activities"
    //           variant="standard"
    //           placeholder="Food"
    //           helperText=""
    //         />
    //       )}
    //       defaultValue="Iconic Sights, Kids Activities, Food"
    //       size="medium"
    //       onClick={onAutocompleteClick}
    //     />
    //   );
};

export default AutoCompleteForm1;
