import * as React from "react";
import MainHeader from "../components/Header";
import styled from "styled-components";

const ItineraryHeroSection = styled.div`
width: 100%;
`;
const ShapeIcon = styled.img`
  position: relative;
  left: 0px;
  width: 1239px;
  height: 38px;
`;
const ItineraryHero = styled.div`
  position: relative;
  top: 0px;
  left: 0px;
  border-radius: 50px 50px 0px 0px;
  height: 574px;
    width: 100%;
  overflow: hidden;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: top;
  text-align: left;
  font-size: 48px;
  color: #ad1457;
  font-family: Quicksand;
`;
const HeroInfoContainerParent = styled.div`
  position: absolute;
  top: 304px;
  left: 0px;
  border-radius: 0px 4px 0px 0px;
  background-color: rgba(255, 248, 225, 0.9);
  display: flex;
  flex-direction: column;
  padding: 16px 8px;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 24px;
`;
const TripToContainer = styled.b`
  position: relative;
  line-height: 116.7%;
  display: inline-block;
  width: 551px;
`;

const DurationContainer = styled.b`
  position: relative;
  font-size: 20px;
  letter-spacing: 0.15px;
  line-height: 160%;
  display: inline-block;
  color: rgba(0, 0, 0, 0.87);
  width: 162px;
`;

const ActivityDetails = ({ data, city, image, url }) => {
    const trip_data = {
        city_or_address: "San Francisco",
        start_date: "2021-09-01",
        end_date: "2021-09-05",
        trip_name: "My Trip",
        trip_id: "1234567890"
    }
    const itinerary = [
        {

        },
        {

        }
    ]



    return (
        <div id="ActivityDetailsPageRoot" style={{
            display: "flex",
            flexDirection: "column",
        }}>
            <MainHeader />
            <div
                id="BreadcrumbsRoot"
                className="flex flex-row w-full items-start"
                style={{
                    padding: "24px"
                }}
            >
                <div
                    className="text-2xl font-['Quicksand'] font-bold leading-[32px] text-[#757575]"
                >
                    Home
                </div>
                <div className="flex flex-row gap-2 items-center">
                    <div className="font-['Quicksand'] tracking-[0.15] leading-[24px] text-[#757575]">
                        /
                    </div>
                    <div
                        className="text-2xl font-['Quicksand'] font-bold leading-[32px] text-[#757575] self-start"
                    >
                        Itinerary
                    </div>
                </div>
                <div className="flex flex-row gap-2 w-1/2 items-center">
                    <div className="font-['Quicksand'] tracking-[0.15] leading-[24px] text-black/60">
                        /
                    </div>
                    <div
                        className="text-2xl font-['Quicksand'] font-bold leading-[32px] text-[#e91e63] self-start"
                    >
                        Activity Details
                    </div>
                </div>
            </div>

            <div
                id="ActivityDetailsRoot"
                className="flex flex-col w-full items-center"
                style={{
                    width: "100%",

                }}


            >
                <ItineraryHeroSection>
                    <ItineraryHero id="activity-info" style={{ backgroundImage: `url("${"/it_image.jpeg"}")` }}>
                        <HeroInfoContainerParent>
                            <TripToContainer>
                                Trip to {trip_data.city_or_address}
                            </TripToContainer>
                            <DurationContainer>Duration: {itinerary.length} days</DurationContainer>
                        </HeroInfoContainerParent>
                    </ItineraryHero>
                    <svg xmlns="http://www.w3.org/2000/svg" width="100%" height="59" viewBox="0 0 1440 59" fill="none">
                        <path d="M855 53C677.186 63.0353 569.128 56.4038 501.5 53C417.549 48.7746 185.587 19.7714 0 0H1440C1340.11 16.5494 1007.5 44.3934 855 53Z" fill="#5C6BC0" />
                    </svg>
                </ItineraryHeroSection>
                <div
                    id="ActivityDetailsContainer"
                    className="flex flex-col w-full "
                    style={{
                        width: "100vw",
                        gap: "24px",
                        marginLeft: "64px",
                        padding: "24px",
                    }}
                >
                    <div
                        id="HighlightsRoot"
                        className="flex flex-row  w-full items-start gap-8"
                    >
                        <div
                            id="Highlight"

                            className="shadow-[0px_3px_1px_-2px_rgba(0,_0,_0,_0.2),_0px_2px_2px_0px_rgba(0,_0,_0,_0.14),_0px_1px_5px_0px_rgba(0,_0,_0,_0.12)] bg-[#ffd54f] flex flex-col justify-center h-12 items-center rounded-[24px]"
                        >
                            <div className="text-xl font-['Quicksand'] font-bold tracking-[0.15] leading-[32px] text-black/87 mx-8">
                                Photography
                            </div>
                        </div>
                        <div
                            id="Highlight1"
                            className="shadow-[0px_3px_1px_-2px_rgba(0,_0,_0,_0.2),_0px_2px_2px_0px_rgba(0,_0,_0,_0.14),_0px_1px_5px_0px_rgba(0,_0,_0,_0.12)] bg-[#ffd54f] flex flex-col justify-center h-12 items-center rounded-[24px]"
                        >
                            <div className="text-xl font-['Quicksand'] font-bold tracking-[0.15] leading-[32px] text-black/87 mx-5">
                                Delightful Dining
                            </div>
                        </div>
                        <div
                            id="Highlight2"
                            className="shadow-[0px_3px_1px_-2px_rgba(0,_0,_0,_0.2),_0px_2px_2px_0px_rgba(0,_0,_0,_0.14),_0px_1px_5px_0px_rgba(0,_0,_0,_0.12)] bg-[#ffd54f] flex flex-col justify-center h-12 items-center rounded-[24px]"
                        >
                            <div className="text-xl font-['Quicksand'] font-bold tracking-[0.15] leading-[32px] text-black/87 mx-8">
                                Museum
                            </div>
                        </div>
                    </div>
                    <div

                        style={{
                            maxWidth: "900px",
                            position: "relative",
                            left: "0px",
                        }}
                        id="QuoteRoot"
                        className="shadow-[0px_2px_4px_-1px_rgba(0,_0,_0,_0.2),_0px_4px_5px_0px_rgba(0,_0,_0,_0.14),_0px_1px_10px_0px_rgba(0,_0,_0,_0.12)] bg-[#e8eaf6] relative flex flex-col pb-20 w-full items-start rounded"
                    >
                        <div style={{
                            width: "90%",
                        }} className="text-xl font-['Quicksand'] font-bold tracking-[0.15] leading-[32px] text-black/87 absolute top-3 left-10 h-24 w-full">
                            Climbing the Eiffel Tower is a thrilling journey. As you rise up this
                            world-famous symbol, soak in mesmerizing Paris views and make cherished
                            memories in the sky above the City of Light
                        </div>
                        <img
                            src="https://file.rendit.io/n/QfNfdZUisCg9ZdKvfBpD.svg"
                            alt="EditorFormatQuote icon"
                            id="Mdiformatquoteclose"
                            className="w-12 h-12 absolute top-20 left-[824px]"
                        />
                        <img
                            src="https://file.rendit.io/n/Xl1MmOix8rFITe0JODLZ.svg"
                            alt="CommunicationMarkChatRead icon"
                            id="Mdiformatquoteclose1"
                            className="relative w-12"
                        />
                    </div>
                    <div style={{
                        display: "flex",
                        flexDirection: "row",
                        width: "100%"
                    }} >
                        <div
                            id="TextRoot"
                            className="flex flex-col justify-between gap-4 w-full items-start"
                            style={{
                                maxWidth: "50vw",
                            }}
                        >
                            <div className="text-xl font-['Quicksand'] font-bold tracking-[0.15] leading-[32px] text-black/87">
                                Visiting the Eiffel Tower is a must-do in Paris.
                            </div>
                            <div className="font-['Quicksand'] tracking-[0.15] leading-[24px] text-black/87 w-5/6">
                                Here’s what you can do there:
                            </div>
                            <div className="text-xl font-['Quicksand'] font-bold tracking-[0.15] leading-[32px] text-black/87">
                                Eiffel Tower Adventure: Discover, Dine, and Delight
                            </div>
                            <div
                                id="Headline"
                                className="bg-[#ffecb3] flex flex-col justify-center pl-4 w-1/2 h-12 shrink-0 items-start rounded"
                            >
                                <div className="font-['Quicksand'] tracking-[0.15] leading-[24px] text-black/87">
                                    Exploration (Approx. 2-3 hours)
                                </div>
                            </div>
                            <div className="font-['Quicksand'] tracking-[0.15] leading-[24px] text-black/87 w-5/6">
                                Begin your Eiffel Tower adventure with a ride to the observation decks.
                                Here, you'll experience awe-inspiring 360-degree views of Paris, capturing
                                incredible photos of renowned landmarks such as the Louvre and Notre-Dame
                                Cathedral.
                            </div>
                            <div
                                id="Headline1"
                                className="bg-[#ffecb3] flex flex-col justify-center h-12 shrink-0 items-center rounded"
                            >
                                <div className="font-['Quicksand'] tracking-[0.15] leading-[24px] text-black/87 mx-4">
                                    Culinary Delights
                                </div>
                            </div>
                            <div className="font-['Quicksand'] tracking-[0.15] leading-[24px] text-black/87 w-5/6">
                                After your exploration, indulge in the delightful culinary offerings at one
                                of the tower's restaurants. Choose from options like the 58 Tour Eiffel or
                                the Michelin-starred Jules Verne, where gourmet French cuisine meets
                                breathtaking views.
                            </div>
                            <div
                                id="Headline2"
                                className="bg-[#ffecb3] flex flex-col justify-center h-12 shrink-0 items-center rounded"
                            >
                                <div className="font-['Quicksand'] tracking-[0.15] leading-[24px] text-black/87 mx-4">
                                    Historical Discovery
                                </div>
                            </div>
                            <div className="font-['Quicksand'] tracking-[0.15] leading-[24px] text-black/87 w-5/6">
                                Don't miss the chance to stroll through the Eiffel Tower's intricate
                                ironwork and dive into its fascinating history in the on-site museum.
                            </div>
                            <div
                                id="Headline3"
                                className="bg-[#ffecb3] flex flex-col justify-center h-12 shrink-0 items-center rounded"
                            >
                                <div className="font-['Quicksand'] tracking-[0.15] leading-[24px] text-black/87 mx-4">
                                    Day or Night
                                </div>
                            </div>
                            <div className="font-['Quicksand'] tracking-[0.15] leading-[24px] text-black/87 w-5/6">
                                Whether you visit during the radiant daylight or when the tower sparkles at
                                night, this experience will etch unforgettable memories in your heart
                                forever.
                            </div>
                        </div>
                        <div style={{
                            display: "flex",
                            flexDirection: "column",
                        }}>
                            <div id="ImageRoot" className="flex flex-col gap-4 w-full items-start">
                                <img
                                    src="https://file.rendit.io/n/WbIzlH4dQeriFxXqtlTU.png"
                                    alt="UnsplashpVQmMFoHA"
                                    id="UnsplashpVQmMFoHA"
                                />
                                <button
                                    id="SeeDirectionsButton"
                                    style={{
                                        background: "none"
                                    }}
                                    className="border-solid border-[rgba(33,_150,_243,_0.5)] flex flex-row justify-center gap-2 h-10 shrink-0 cursor-pointer items-center pl-5 pr-4 py-2 border rounded"
                                >
                                    <div
                                        id="Button1"
                                        className="text-sm font-['Quicksand'] font-bold tracking-[0.46] leading-[26px] capitalize text-[#0057b2]"
                                    >
                                        See directions
                                    </div>
                                    <img
                                        src="https://file.rendit.io/n/jD4L8LfyrGynHZDhFFD6.svg"
                                        alt="MapsDirections icon"
                                        id="IconRight"
                                        className="w-6 shrink-0"
                                    />
                                </button>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    );

}

export default ActivityDetails;