import { Button } from "@mui/material";
import Logo from "./Logo";
import styled from "styled-components";
import { Link, NavLink } from 'react-router-dom';
import Cookies from 'js-cookie';
import jwt_decode from 'jwt-decode';
import { useState } from "react";
import "@fontsource/quicksand/700.css";
import MobileNavbar from "../pages/mobile/Navbar"
import Avatar from '@mui/material/Avatar';

const Button1 = styled(Button)``;
const Buttons = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-5xs);
`;
const Actions = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
`;
const HeaderRoot = styled.header`
  position: relative;
  top: 0px;
  left: 0px;
  background-color: var(--color-white);
  box-shadow: var(--elevation-5);
  width: 100%;
  height: 75px;
  overflow: hidden;
  display: flex;
  flex-direction: row;
  padding: var(--padding-base);
  box-sizing: border-box;
  align-items: center;
  justify-content: space-between;
`;
const ProfileNameContainer = styled.div`
color: #080a0b;
font-size: 16px;
font-family: "Poppins";
font-weight: 700;
line-height: 22px;
padding: 10px;
text-decoration: none;
cursor: pointer;
`
const ActiveButton = styled(Button)`
color: var(--primary-main, #D81B60);
font-feature-settings: 'clig' off, 'liga' off;

font-family: Quicksand;
font-size: 20px;
font-style: normal;
font-weight: 700;
line-height: 160%; /* 32px */
letter-spacing: 0.15px;
`


const MainHeader = () => {
  const [isuserLoggedIn, setIsuserLoggedIn] = useState(false);
  const [user, setUser] = useState(null);
  const token = localStorage.getItem('token');
  if (token && !isuserLoggedIn) {
    try {
      setIsuserLoggedIn(true);
      setUser(jwt_decode(token));
    } catch (error) {
      setIsuserLoggedIn(false);
      localStorage.removeItem('token');
    }

  }
  const isSmalldevice = () => {return window.innerWidth < 600 };
  if (isSmalldevice()) {
    return (
      <MobileNavbar />
    )
  }


  return (
    <HeaderRoot>
      <NavLink onClick={()=>{window.location.href="/"}}>
        <Logo />
      </NavLink>
      <Actions>
        <Buttons>
          {isuserLoggedIn ?
            (<>
            <NavLink onClick={()=>{window.location.href="/"}} style={{ textDecoration: "none" }}>
              <Button>Home</Button>
            </NavLink>
            <NavLink to="/mytrips" style={{ textDecoration: "none" }}>
              <div 
              // style={{borderBottom: "2px solid", borderBottomColor:"var(--primary-main, #D81B60)"}}
              >
              <ActiveButton>My Trips</ActiveButton>  
              {/* style={{color: "var(--primary-main, #D81B60)" , }} */}
              </div>
            </NavLink>
            <NavLink to="/profile" style={{ textDecoration: "none" }}>
              {/* <ProfileNameContainer>Hi, {user.firstname}</ProfileNameContainer> */}
              <Avatar
                alt={user.firstname}
                src="/broken-image.jpg"
                sx={{ width: 32, height: 32 }}
              />
              </NavLink>
            </>
            ) :
            (<>
              {/* <NavLink to="/register">
                <Button1 style={{ textDecoration: "none", fontWeight: "700" }} variant="contained" color="primary">
                  Sign Up
                </Button1>
              </NavLink> */}
              <NavLink to="/login">
                <Button1 variant="text" style={{fontFamily: "Quicksand", textTransform: "unset", fontWeight: "700"}} color="primary">
                  Log In
                </Button1>
              </NavLink>
            </>
            )
          }
        </Buttons>
      </Actions>
    </HeaderRoot>
  );
};

export default MainHeader;
