import * as React from "react";
import { GoogleMap, useLoadScript, useJsApiLoader, MarkerF,  InfoWindow } from "@react-google-maps/api";
const REACT_APP_GOOGLE_MAPS_KEY = process.env.REACT_APP_MAPS_API_KEY;
import Marker from "./Marker";
import GoogleMapReact from 'google-map-react';
const required_libraries = ["places"];




const MapComponentAdd = ({ locations, small, activities, trip_data }) => {
    let containerStyle = {
        width: '792px',
        height: '530px'
    };
    if (small) {
        containerStyle = {
            width: '303px',
            height: '163px'
        };
    }


    const { isLoaded } = useJsApiLoader({
        id: 'google-map-script',
        googleMapsApiKey: REACT_APP_GOOGLE_MAPS_KEY,
        libraries: required_libraries,
    })
    // console.log(locations)

    const distanceToMouse = (pt, mp) => {
        if (pt && mp) {
            // return distance between the marker and mouse pointer
            return Math.sqrt(
                (pt.x - mp.x) * (pt.x - mp.x) + (pt.y - mp.y) * (pt.y - mp.y)
            );
        }
    };

    const [map, setMap] = React.useState(null)

    const onLoad = React.useCallback(function callback(map) {
        // This is just an example of getting and using the map instance!!! don't just blindly copy!
        // const bounds = new window.google.maps.LatLngBounds(center);
        // map.fitBounds(bounds);

        setMap(map)
    }, [])

    const onUnmount = React.useCallback(function callback(map) {
        setMap(null)
    }, [])

    return isLoaded ? (
        <div style={containerStyle}>
            <GoogleMapReact
                defaultZoom={9}
                defaultCenter={locations[0]}
                bootstrapURLKeys={{
                    key: REACT_APP_GOOGLE_MAPS_KEY,
                    libraries: ['places', 'geometry'],
                }}
                options={{
                    mapTypeId: "terrain",
                }}
                distanceToMouse={distanceToMouse}
            >
                {activities ?
                    activities.map((activity, index) => {
                        return <Marker key={index} lat={locations[index].lat} lng={locations[index].lng} data={activity} trip_data={trip_data} image={activity.images[0].url} text={"test"} tooltip={"test tooltip"} />
                    })
                    : null}
                <></>
            </GoogleMapReact>
        </div>
        // <GoogleMap
        //     mapContainerStyle={containerStyle}
        //     center={locations[0]}
        //     zoom={9}
        //     onLoad={onLoad}
        //     onUnmount={onUnmount}
        // >
        //     {/* {activities ?
        //         activities.map((activity, index) => {
        //             return <Marker key={index} lat={locations[index].lat} lng={locations[index].lng} data={activity} trip_data={activities.trip_data} image={activity.images[0].url} text={"test"} tooltip={"test tooltip"} />
        //         })
        //         : null} */}
        //     {locations.map((location) => {
        //         return <CustomMarker lat={location.lat} lng={location.lng} info={location.name} />


        //         // return <MarkerF
        //         //     key={location.lat}
        //         //     position={location}
        //         //     onMouseOut={() => {
        //         //         setInfoWindowOpen(false)
        //         //         setInfoWindow(null)
        //         //     }
        //         //     }
        //         //     onMouseOver={() => {
        //         //         setInfoWindowOpen(true)
        //         //         setInfoWindow(<div>
        //         //             <h3>{location.name}</h3>
        //         //             <p>{location.address}</p>
        //         //         </div>)
        //         //     }}

        //         // />
        //     })
        //     }
        //     <></>
        // </GoogleMap>
    ) : <></>
};

export default MapComponentAdd;