import React from 'react';
import styled from 'styled-components';

const FormContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
`;

const Input = styled.input`
  margin: 10px 0;
  padding: 10px;
  border: 1px solid #ccc;
`;

const PlanButton = styled.button`
  background-color: #333;
  color: white;
  padding: 10px 20px;
  border: none;
  cursor: pointer;
`;

const TripForm = () => {
  return (
    <FormContainer>
      <Input type="text" placeholder="Where to go" />
      <Input type="date" placeholder="From when" />
      <Input type="date" placeholder="To when" />
      <Input type="text" placeholder="Any specific intent" />
      <PlanButton>Plan</PlanButton>
    </FormContainer>
  );
};

export default TripForm;
