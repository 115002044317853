import * as React from "react";
import styled from "styled-components";
import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';
import MainHeader from "../components/Header";
import { useNavigate, useSearchParams } from 'react-router-dom';
import axios from "axios";
import { useRef } from 'react';
import ReCAPTCHA from "react-google-recaptcha";
import { RECAPTCHA_SITEKEY } from "../constants";
import Footer from "../components/Footer";
import { GoogleOAuthProvider } from '@react-oauth/google';
import { GoogleLogin } from '@react-oauth/google';


const Custom = styled.b`
  position: relative;
  letter-spacing: -1.5px;
  line-height: 120%;
  display: inline-block;
  width: 398px;
`;
const Title = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;



const MaskedIcon = styled.img`
  position: relative;
  width: 16px;
  height: 18px;
  display: none;
`;
const Button1 = styled.b`
  position: relative;
  letter-spacing: 0.46px;
  line-height: 22px;
  text-transform: capitalize;
`;
const Base = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 8px;
`;
const Button = styled.div`
  border-radius: 4px;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 4px 5px;
  font-size: 13px;
  color: #d81b60;
`;
const Password = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: flex-start;
  gap: 8px;
`;
const MaskedIcon2 = styled.img`
  position: relative;
  width: 18px;
  height: 22px;
  display: none;
`;
const Button3 = styled.b`
  position: relative;
  letter-spacing: 0.46px;
  line-height: 26px;
  text-transform: capitalize;
`;
const Button2 = styled.button`
    border-width: 0px;
    color: #fff;
  border-radius: 4px;
  background-color: #d81b60;
  box-shadow: 0px 1px 5px rgba(0, 0, 0, 0.12), 0px 2px 2px rgba(0, 0, 0, 0.14),
    0px 3px 1px -2px rgba(0, 0, 0, 0.2);
  width: 292px;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 8px 22px;
  box-sizing: border-box;
`;
const NoAccountYet = styled.div`
  position: relative;
  letter-spacing: 0.17px;
  line-height: 143%;
`;
const Button4 = styled.div`
  border-radius: 4px;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 8px 11px;
  font-size: 15px;
  color: #d81b60;
`;
const Text1 = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 8px;
  font-size: 14px;
  color: rgba(0, 0, 0, 0.87);
`;
const Buttons = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 8px;
  font-size: 15px;
  color: #fff;
`;
const Form = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
  gap: 24px;
  text-align: left;
  font-size: 12px;
  color: rgba(0, 0, 0, 0.6);
  font-family: Quicksand;
`;
const Signup = styled.div`
  position: absolute;
  top: calc(50% - 187.5px);
  left: calc(50% - 212px);
  width: 424px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 16px;
`;

const LogInRoot = styled.div`
  position: relative;
  background-color: #fff;
  width: 100%;
  height: 785px;
  overflow: hidden;
  text-align: center;
  font-size: 72px;
  color: #d81b60;
  font-family: Caveat;
`;

const LogIn = () => {
    const navigate = useNavigate();
    const reCaptchaRef = useRef();
    const [searchParams, setSearchParams] = useSearchParams();

    const captchaOnChange = (value) => {
        // console.log("Captcha value:", value);
    };

    const handleCaptchaExpire = () => {
        reCaptchaRef.current.reset();
    };
    const [redirect, setRedirect] = React.useState(false);

    React.useEffect(() => {
        if (searchParams.has('redirect')) {
            setRedirect(true);
        }
    }, []);



    const handleSubmit = async (event) => {
        event.preventDefault();
        if (!event.target.checkValidity()) {
            alert("Please Fill the form.");
            return
        }

        const handleResponse = (response) => {
            const token = response.data.token;
            localStorage.setItem('token', token);
            alert("Login Successful");
            if (redirect) {
                navigate("/itinerary?it=" + searchParams.get('it') + "&token=" + searchParams.get('token'));
                return
            }
            navigate("/");
        }

        const handleError = (error) => {
            console.log(error);
            if (error.response.status === 500) {
                alert("Some error occured on the server. Please try again later.");
                return
            }
            alert("Login Failed : " + error.response.data.message);
        }

        const data = new FormData(document.getElementById('login-form'));
        let bodyContent = {
            "email": data.get('email'),
            "password": data.get('password'),
        };

        let reqOptions = {
            url: process.env.REACT_APP_BACKEND_URL + "/api/auth/signin",
            method: "POST",
            data: bodyContent,
            withCredentials: true,
        }

        axios.post(reqOptions.url, reqOptions.data, {
            withCredentials: true,
        }).then(handleResponse).catch(handleError);

    };

    const submitWithCaptcha = (event) => {
        event.preventDefault();

        reCaptchaRef.current.executeAsync().then((token) => {
            // console.log(token);
            handleSubmit(event);
        });
    };

    const handleLoginViaGoogle = (credentialResponse) => {
        console.log(credentialResponse);
        const token = credentialResponse.credential;
        console.log(token);
        let bodyContent = {
            "token": token
        };

        let reqOptions = {
            url: process.env.REACT_APP_BACKEND_URL + "/api/auth/google",
            method: "POST",
            data: bodyContent,
            withCredentials: true,
        }

        axios.post(reqOptions.url, reqOptions.data, {
            withCredentials: true,
        }).then((res)=> {
            console.log(res);
            localStorage.setItem('token', res.data.token);
            alert("Login Successful");
            navigate("/");
        }).catch((err) => {
            console.log(err);
            alert("Login Failed. Please try again later.");
        });
        
        
    }

    const handleLoginViaGoogleError = () => {
        alert("Login Failed. Please try again later.");
    }



    return (

        <GoogleOAuthProvider clientId={process.env.REACT_APP_OAUTH_CLIENT_ID}>
            <LogInRoot>
                <MainHeader />
                <Signup>
                    <Title>
                        <Custom>Welcome back!</Custom>
                    </Title>
                    <GoogleLogin
                        onSuccess={handleLoginViaGoogle}
                        onError={handleLoginViaGoogleError}
                    />
                </Signup>
                <Footer />

            </LogInRoot>
        </GoogleOAuthProvider>

    );
};

export default LogIn;
