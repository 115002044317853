import { useState, useCallback } from "react";
import TransportInformationModal from "../mobile/components/TransportInformationModal";
import PortalPopup from "../mobile/components/PortalPopup";

const ItineraryPageNotSignedUp = () => {
  const [isTransportInformationModalOpen, setTransportInformationModalOpen] =
    useState(false);
  const [isTransportInformationModal1Open, setTransportInformationModal1Open] =
    useState(false);
  const [isTransportInformationModal2Open, setTransportInformationModal2Open] =
    useState(false);

  const openTransportInformationModal = useCallback(() => {
    setTransportInformationModalOpen(true);
  }, []);

  const closeTransportInformationModal = useCallback(() => {
    setTransportInformationModalOpen(false);
  }, []);

  const openTransportInformationModal1 = useCallback(() => {
    setTransportInformationModal1Open(true);
  }, []);

  const closeTransportInformationModal1 = useCallback(() => {
    setTransportInformationModal1Open(false);
  }, []);

  const openTransportInformationModal2 = useCallback(() => {
    setTransportInformationModal2Open(true);
  }, []);

  const closeTransportInformationModal2 = useCallback(() => {
    setTransportInformationModal2Open(false);
  }, []);

  const onLogoClick = useCallback(() => {
    // Please sync "🟡 landing page - mobile" to the project
  }, []);

  return (
    <>
      <div className="relative bg-secondary-contrast w-full h-[4248px] overflow-hidden text-left text-xl text-text-primary font-typography-h5">
        <div className="absolute top-[64px] left-[calc(50%_-_180px)] w-[360px] h-[4138px]">
          <div className="absolute top-[3792px] left-[calc(50%_-_164px)] flex flex-col items-start justify-start">
            
          </div>
          <div className="absolute top-[1234px] left-[20px] w-80 h-[2531px] text-5xl">
            <div className="absolute top-[64px] left-[0px] rounded-xl bg-secondary-contrast shadow-[0px_1px_8px_rgba(0,_0,_0,_0.12),_0px_3px_4px_rgba(0,_0,_0,_0.14),_0px_3px_3px_-2px_rgba(0,_0,_0,_0.2)] flex flex-col p-4 items-start justify-start gap-[16px] border-[2px] border-solid border-primary-main">
              <div className="flex flex-row items-start justify-start">
                <div className="flex flex-row items-start justify-start">
                  <b className="relative leading-[133.4%] inline-block w-[271px] shrink-0">
                    Capturing the Essence of Paris
                  </b>
                </div>
              </div>
              <div className="relative text-sm tracking-[0.17px] leading-[143%] inline-block w-72">
                Your journey begins with the timeless beauty and rich culture of
                the City of Light. Marvel at the grandeur of the Eiffel Tower,
                take in the romantic Seine River, and start capturing the
                essence of Paris, one iconic landmark at a time
              </div>
              <div className="flex flex-col items-start justify-start gap-[16px] text-xl">
                <div className="flex flex-col items-start justify-start gap-[8px]">
                  <div className="bg-tripdaddy-amber-200 flex flex-row py-0 px-boundvariablesdata1 items-center justify-center">
                    <b className="relative tracking-[0.15px] leading-[160%]">
                      Locations:
                    </b>
                  </div>
                  <img
                    className="relative w-[274px] h-[163px]"
                    alt=""
                    src="/map-with-pins.svg"
                  />
                </div>
                <div className="flex flex-col items-start justify-start gap-[8px]">
                  <div className="w-72 flex flex-row items-center justify-between">
                    <div className="bg-tripdaddy-amber-200 flex flex-row py-0 px-boundvariablesdata1 items-center justify-center">
                      <b className="relative tracking-[0.15px] leading-[160%]">
                        Activities:
                      </b>
                    </div>
                    <div className="rounded-borderradius overflow-hidden flex flex-col py-1 px-2.5 items-center justify-center text-smi text-info-main border-[1px] border-solid border-info-outlinedborder">
                      <div className="flex flex-row items-center justify-center gap-[8px]">
                        <img
                          className="relative w-4 h-[18px] hidden"
                          alt=""
                          src="/masked-icon4.svg"
                        />
                        <b className="relative tracking-[0.46px] leading-[22px] capitalize">
                          Add Activity
                        </b>
                        <img
                          className="relative w-[18px] h-[18px]"
                          alt=""
                          src="/masked-icon5.svg"
                        />
                      </div>
                    </div>
                  </div>
                  <div className="flex flex-col items-start justify-start gap-[40px]">
                    <div className="flex flex-col items-center justify-center gap-[8px]">
                      <div className="rounded-borderradius bg-tripdaddy-grey-50 shadow-[0px_1px_10px_rgba(0,_0,_0,_0.12),_0px_4px_5px_rgba(0,_0,_0,_0.14),_0px_2px_4px_-1px_rgba(0,_0,_0,_0.2)] flex flex-col p-2 items-center justify-start gap-[8px]">
                        <div className="relative w-[264px] h-[195px]">
                          <img
                            className="absolute top-[0px] left-[0px] rounded-borderradius w-[264px] h-[195px] object-cover"
                            alt=""
                            src="/unsplashflkxnzdebmk@2x.png"
                          />
                          <div className="absolute top-[6px] left-[195px] w-16 h-[30px]">
                            <div className="absolute top-[0px] left-[34px] rounded-81xl bg-secondary-contrast overflow-hidden flex flex-col p-[5px] items-center justify-center border-[1px] border-solid border-bluegrey-50">
                              <div className="flex flex-row items-start justify-start">
                                <img
                                  className="relative w-5 h-5 overflow-hidden shrink-0"
                                  alt=""
                                  src="/editfilled.svg"
                                />
                              </div>
                            </div>
                            <div className="absolute top-[0px] left-[0px] rounded-81xl bg-secondary-contrast overflow-hidden flex flex-col p-[5px] items-center justify-center border-[1px] border-solid border-bluegrey-50">
                              <div className="flex flex-row items-start justify-start">
                                <img
                                  className="relative w-5 h-5 overflow-hidden shrink-0"
                                  alt=""
                                  src="/deletefilled.svg"
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="flex flex-col items-start justify-start">
                          <div className="flex flex-col items-start justify-start gap-[16px]">
                            <b className="relative tracking-[0.15px] leading-[160%]">
                              From the iconic Eiffel Tower
                            </b>
                            <div className="self-stretch relative text-sm tracking-[0.17px] leading-[143%]">
                              Experience breathtaking views of Paris from the
                              iconic Eiffel Tower
                            </div>
                            <div className="flex flex-row items-start justify-start text-smi text-secondary-main">
                              <div className="rounded-borderradius box-border w-[136px] overflow-hidden shrink-0 flex flex-col py-1 px-2.5 items-center justify-center border-[1px] border-solid border-secondary-outlinedborder">
                                <div className="flex flex-row items-center justify-center gap-[8px]">
                                  <img
                                    className="relative w-4 h-[18px] hidden"
                                    alt=""
                                    src="/masked-icon6.svg"
                                  />
                                  <b className="relative tracking-[0.46px] leading-[22px] capitalize">
                                    Read more
                                  </b>
                                  <img
                                    className="relative w-4 h-[18px] hidden"
                                    alt=""
                                    src="/masked-icon7.svg"
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="flex flex-col items-center justify-center gap-[8px] text-sm">
                        <div className="overflow-hidden flex flex-row p-2 items-center justify-start gap-[16px]">
                          <div className="flex flex-row items-center justify-start">
                            <img
                              className="relative w-4 h-4 overflow-hidden shrink-0"
                              alt=""
                              src="/mdicar.svg"
                            />
                            <div className="relative tracking-[0.17px] leading-[143%]">
                              2 miles
                            </div>
                          </div>
                          <div className="flex flex-row items-center justify-start">
                            <img
                              className="relative w-4 h-4 overflow-hidden shrink-0"
                              alt=""
                              src="/hourglassbottomfilled.svg"
                            />
                            <div className="relative tracking-[0.17px] leading-[143%]">
                              30 mins
                            </div>
                          </div>
                        </div>
                        <div className="rounded-borderradius overflow-hidden flex flex-col py-1 px-[5px] items-center justify-center text-smi text-secondary-main">
                          <div className="flex flex-row items-center justify-center gap-[8px]">
                            <img
                              className="relative w-4 h-[18px] hidden"
                              alt=""
                              src="/masked-icon8.svg"
                            />
                            <b className="relative tracking-[0.46px] leading-[22px] capitalize">
                              Detailed transport info
                            </b>
                            <img
                              className="relative w-4 h-[18px] hidden"
                              alt=""
                              src="/masked-icon9.svg"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="flex flex-col items-center justify-center gap-[8px]">
                      <div className="rounded-borderradius bg-tripdaddy-grey-50 shadow-[0px_1px_10px_rgba(0,_0,_0,_0.12),_0px_4px_5px_rgba(0,_0,_0,_0.14),_0px_2px_4px_-1px_rgba(0,_0,_0,_0.2)] flex flex-col p-2 items-center justify-start gap-[8px]">
                        <div className="relative w-[264px] h-[195px]">
                          <img
                            className="absolute top-[0px] left-[0px] rounded-borderradius w-[264px] h-[195px] object-cover"
                            alt=""
                            src="/unsplashflkxnzdebmk1@2x.png"
                          />
                          <div className="absolute top-[6px] left-[195px] w-16 h-[30px]">
                            <div className="absolute top-[0px] left-[34px] rounded-81xl bg-secondary-contrast overflow-hidden flex flex-col p-[5px] items-center justify-center border-[1px] border-solid border-bluegrey-50">
                              <div className="flex flex-row items-start justify-start">
                                <img
                                  className="relative w-5 h-5 overflow-hidden shrink-0"
                                  alt=""
                                  src="/editfilled1.svg"
                                />
                              </div>
                            </div>
                            <div className="absolute top-[0px] left-[0px] rounded-81xl bg-secondary-contrast overflow-hidden flex flex-col p-[5px] items-center justify-center border-[1px] border-solid border-bluegrey-50">
                              <div className="flex flex-row items-start justify-start">
                                <img
                                  className="relative w-5 h-5 overflow-hidden shrink-0"
                                  alt=""
                                  src="/deletefilled1.svg"
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="flex flex-col items-start justify-start">
                          <div className="flex flex-col items-start justify-start gap-[16px]">
                            <b className="relative tracking-[0.15px] leading-[160%]">
                              Discover world-famous art
                            </b>
                            <div className="self-stretch relative text-sm tracking-[0.17px] leading-[143%]">
                              Discover world-famous art at the Louvre Museum in
                              Paris, where iconic masterpieces await your
                              exploration
                            </div>
                            <div className="flex flex-row items-start justify-start text-smi text-secondary-main">
                              <div className="rounded-borderradius box-border w-[136px] overflow-hidden shrink-0 flex flex-col py-1 px-2.5 items-center justify-center border-[1px] border-solid border-secondary-outlinedborder">
                                <div className="flex flex-row items-center justify-center gap-[8px]">
                                  <img
                                    className="relative w-4 h-[18px] hidden"
                                    alt=""
                                    src="/masked-icon10.svg"
                                  />
                                  <b className="relative tracking-[0.46px] leading-[22px] capitalize">
                                    Read more
                                  </b>
                                  <img
                                    className="relative w-4 h-[18px] hidden"
                                    alt=""
                                    src="/masked-icon11.svg"
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="flex flex-col items-center justify-center gap-[8px] text-sm">
                        <div className="overflow-hidden flex flex-row p-2 items-center justify-start gap-[16px]">
                          <div className="flex flex-row items-center justify-start">
                            <img
                              className="relative w-4 h-4 overflow-hidden shrink-0"
                              alt=""
                              src="/mdicar1.svg"
                            />
                            <div className="relative tracking-[0.17px] leading-[143%]">
                              2 miles
                            </div>
                          </div>
                          <div className="flex flex-row items-center justify-start">
                            <img
                              className="relative w-4 h-4 overflow-hidden shrink-0"
                              alt=""
                              src="/hourglassbottomfilled1.svg"
                            />
                            <div className="relative tracking-[0.17px] leading-[143%]">
                              30 mins
                            </div>
                          </div>
                        </div>
                        <div className="rounded-borderradius overflow-hidden flex flex-col py-1 px-[5px] items-center justify-center text-smi text-secondary-main">
                          <div className="flex flex-row items-center justify-center gap-[8px]">
                            <img
                              className="relative w-4 h-[18px] hidden"
                              alt=""
                              src="/masked-icon12.svg"
                            />
                            <b
                              className="relative tracking-[0.46px] leading-[22px] capitalize cursor-pointer"
                              onClick={openTransportInformationModal}
                            >
                              Detailed transport info
                            </b>
                            <img
                              className="relative w-4 h-[18px] hidden"
                              alt=""
                              src="/masked-icon13.svg"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="flex flex-col items-center justify-center gap-[8px]">
                      <div className="rounded-borderradius bg-tripdaddy-grey-50 shadow-[0px_1px_10px_rgba(0,_0,_0,_0.12),_0px_4px_5px_rgba(0,_0,_0,_0.14),_0px_2px_4px_-1px_rgba(0,_0,_0,_0.2)] flex flex-col p-2 items-center justify-start gap-[8px]">
                        <div className="relative w-[264px] h-[195px]">
                          <img
                            className="absolute top-[0px] left-[0px] rounded-borderradius w-[264px] h-[195px] object-cover"
                            alt=""
                            src="/unsplashflkxnzdebmk2@2x.png"
                          />
                          <div className="absolute top-[6px] left-[195px] w-16 h-[30px]">
                            <div className="absolute top-[0px] left-[34px] rounded-81xl bg-secondary-contrast overflow-hidden flex flex-col p-[5px] items-center justify-center border-[1px] border-solid border-bluegrey-50">
                              <div className="flex flex-row items-start justify-start">
                                <img
                                  className="relative w-5 h-5 overflow-hidden shrink-0"
                                  alt=""
                                  src="/editfilled2.svg"
                                />
                              </div>
                            </div>
                            <div className="absolute top-[0px] left-[0px] rounded-81xl bg-secondary-contrast overflow-hidden flex flex-col p-[5px] items-center justify-center border-[1px] border-solid border-bluegrey-50">
                              <div className="flex flex-row items-start justify-start">
                                <img
                                  className="relative w-5 h-5 overflow-hidden shrink-0"
                                  alt=""
                                  src="/deletefilled2.svg"
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="flex flex-col items-start justify-start">
                          <div className="flex flex-col items-start justify-start gap-[16px]">
                            <b className="relative tracking-[0.15px] leading-[160%]">
                              Strolling the Seine River
                            </b>
                            <div className="self-stretch relative text-sm tracking-[0.17px] leading-[143%]">
                              Strolling along the Seine River is a captivating
                              journey through Paris's heart, with scenic views
                              of historic bridges and riverside beauty
                            </div>
                            <div className="flex flex-row items-start justify-start text-smi text-secondary-main">
                              <div className="rounded-borderradius box-border w-[136px] overflow-hidden shrink-0 flex flex-col py-1 px-2.5 items-center justify-center border-[1px] border-solid border-secondary-outlinedborder">
                                <div className="flex flex-row items-center justify-center gap-[8px]">
                                  <img
                                    className="relative w-4 h-[18px] hidden"
                                    alt=""
                                    src="/masked-icon14.svg"
                                  />
                                  <b className="relative tracking-[0.46px] leading-[22px] capitalize">
                                    Read more
                                  </b>
                                  <img
                                    className="relative w-4 h-[18px] hidden"
                                    alt=""
                                    src="/masked-icon15.svg"
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="flex flex-col items-center justify-center gap-[8px] text-sm">
                        <div className="overflow-hidden flex flex-row p-2 items-center justify-start gap-[16px]">
                          <div className="flex flex-row items-center justify-start">
                            <img
                              className="relative w-4 h-4 overflow-hidden shrink-0"
                              alt=""
                              src="/mdicar2.svg"
                            />
                            <div className="relative tracking-[0.17px] leading-[143%]">
                              2 miles
                            </div>
                          </div>
                          <div className="flex flex-row items-center justify-start">
                            <img
                              className="relative w-4 h-4 overflow-hidden shrink-0"
                              alt=""
                              src="/hourglassbottomfilled2.svg"
                            />
                            <div className="relative tracking-[0.17px] leading-[143%]">
                              30 mins
                            </div>
                          </div>
                        </div>
                        <div className="rounded-borderradius overflow-hidden flex flex-col py-1 px-[5px] items-center justify-center text-smi text-secondary-main">
                          <div className="flex flex-row items-center justify-center gap-[8px]">
                            <img
                              className="relative w-4 h-[18px] hidden"
                              alt=""
                              src="/masked-icon16.svg"
                            />
                            <b
                              className="relative tracking-[0.46px] leading-[22px] capitalize cursor-pointer"
                              onClick={openTransportInformationModal1}
                            >
                              Detailed transport info
                            </b>
                            <img
                              className="relative w-4 h-[18px] hidden"
                              alt=""
                              src="/masked-icon17.svg"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="flex flex-col items-center justify-center gap-[8px]">
                      <div className="rounded-borderradius bg-tripdaddy-grey-50 shadow-[0px_1px_10px_rgba(0,_0,_0,_0.12),_0px_4px_5px_rgba(0,_0,_0,_0.14),_0px_2px_4px_-1px_rgba(0,_0,_0,_0.2)] flex flex-col p-2 items-center justify-start gap-[8px]">
                        <div className="relative w-[264px] h-[195px]">
                          <img
                            className="absolute top-[0px] left-[0px] rounded-borderradius w-[264px] h-[195px] object-cover"
                            alt=""
                            src="/unsplashflkxnzdebmk3@2x.png"
                          />
                          <div className="absolute top-[6px] left-[195px] w-16 h-[30px]">
                            <div className="absolute top-[0px] left-[34px] rounded-81xl bg-secondary-contrast overflow-hidden flex flex-col p-[5px] items-center justify-center border-[1px] border-solid border-bluegrey-50">
                              <div className="flex flex-row items-start justify-start">
                                <img
                                  className="relative w-5 h-5 overflow-hidden shrink-0"
                                  alt=""
                                  src="/editfilled3.svg"
                                />
                              </div>
                            </div>
                            <div className="absolute top-[0px] left-[0px] rounded-81xl bg-secondary-contrast overflow-hidden flex flex-col p-[5px] items-center justify-center border-[1px] border-solid border-bluegrey-50">
                              <div className="flex flex-row items-start justify-start">
                                <img
                                  className="relative w-5 h-5 overflow-hidden shrink-0"
                                  alt=""
                                  src="/deletefilled3.svg"
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="flex flex-col items-start justify-start">
                          <div className="flex flex-col items-start justify-start gap-[16px]">
                            <b className="relative tracking-[0.15px] leading-[160%]">
                              The Notre-Dame Cathedral
                            </b>
                            <div className="self-stretch relative text-sm tracking-[0.17px] leading-[143%]">
                              Visiting Notre-Dame Cathedral feels like stepping
                              back in time, with its amazing old-fashioned
                              design and fascinating details
                            </div>
                            <div className="flex flex-row items-start justify-start text-smi text-secondary-main">
                              <div className="rounded-borderradius box-border w-[136px] overflow-hidden shrink-0 flex flex-col py-1 px-2.5 items-center justify-center border-[1px] border-solid border-secondary-outlinedborder">
                                <div className="flex flex-row items-center justify-center gap-[8px]">
                                  <img
                                    className="relative w-4 h-[18px] hidden"
                                    alt=""
                                    src="/masked-icon18.svg"
                                  />
                                  <b className="relative tracking-[0.46px] leading-[22px] capitalize">
                                    Read more
                                  </b>
                                  <img
                                    className="relative w-4 h-[18px] hidden"
                                    alt=""
                                    src="/masked-icon19.svg"
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="flex flex-col items-center justify-center gap-[8px] text-sm">
                        <div className="overflow-hidden flex flex-row p-2 items-center justify-start gap-[16px]">
                          <div className="flex flex-row items-center justify-start">
                            <img
                              className="relative w-4 h-4 overflow-hidden shrink-0"
                              alt=""
                              src="/mdicar3.svg"
                            />
                            <div className="relative tracking-[0.17px] leading-[143%]">
                              2 miles
                            </div>
                          </div>
                          <div className="flex flex-row items-center justify-start">
                            <img
                              className="relative w-4 h-4 overflow-hidden shrink-0"
                              alt=""
                              src="/hourglassbottomfilled3.svg"
                            />
                            <div className="relative tracking-[0.17px] leading-[143%]">
                              30 mins
                            </div>
                          </div>
                        </div>
                        <div className="rounded-borderradius overflow-hidden flex flex-col py-1 px-[5px] items-center justify-center text-smi text-secondary-main">
                          <div className="flex flex-row items-center justify-center gap-[8px]">
                            <img
                              className="relative w-4 h-[18px] hidden"
                              alt=""
                              src="/masked-icon20.svg"
                            />
                            <b
                              className="relative tracking-[0.46px] leading-[22px] capitalize cursor-pointer"
                              onClick={openTransportInformationModal2}
                            >
                              Detailed transport info
                            </b>
                            <img
                              className="relative w-4 h-[18px] hidden"
                              alt=""
                              src="/masked-icon21.svg"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="absolute top-[0px] left-[8px] flex flex-col items-start justify-start text-sm text-text-secondary">
              <div className="overflow-hidden flex flex-col items-start justify-start gap-[56px]">
                <div className="flex flex-row items-start justify-start">
                  <div className="flex flex-col items-center justify-center relative text-base text-primary-main">
                    <div className="flex flex-row py-[9px] px-4 items-center justify-center gap-[8px] z-[0]">
                      <div className="hidden flex-row items-start justify-start">
                        <img
                          className="relative w-6 h-6 overflow-hidden shrink-0"
                          alt=""
                          src="/starsharp.svg"
                        />
                      </div>
                      <div className="relative tracking-[0.15px] leading-[150%]">
                        Day 1
                      </div>
                    </div>
                    <div className="absolute my-0 mx-[!important] w-[calc(100%_+_2px)] right-[-1px] bottom-[-1px] left-[-1px] box-border h-0.5 z-[1] border-t-[2px] border-solid border-primary-main" />
                  </div>
                  <div className="flex flex-col items-center justify-center text-base">
                    <div className="flex flex-row py-[9px] px-4 items-center justify-center gap-[8px]">
                      <div className="hidden flex-row items-start justify-start">
                        <img
                          className="relative w-6 h-6 overflow-hidden shrink-0"
                          alt=""
                          src="/starsharp1.svg"
                        />
                      </div>
                      <div className="relative tracking-[0.15px] leading-[150%]">
                        Day 2
                      </div>
                    </div>
                  </div>
                  <div className="flex flex-col items-center justify-center text-base">
                    <div className="flex flex-row py-[9px] px-4 items-center justify-center gap-[8px]">
                      <div className="hidden flex-row items-start justify-start">
                        <img
                          className="relative w-6 h-6 overflow-hidden shrink-0"
                          alt=""
                          src="/starsharp2.svg"
                        />
                      </div>
                      <div className="relative tracking-[0.15px] leading-[150%]">
                        Day 3
                      </div>
                    </div>
                  </div>
                  <div className="flex flex-col items-center justify-center text-base">
                    <div className="flex flex-row py-[9px] px-4 items-center justify-center gap-[8px]">
                      <div className="hidden flex-row items-start justify-start">
                        <img
                          className="relative w-6 h-6 overflow-hidden shrink-0"
                          alt=""
                          src="/starsharp3.svg"
                        />
                      </div>
                      <div className="relative tracking-[0.15px] leading-[150%]">
                        Day 4
                      </div>
                    </div>
                  </div>
                  <div className="hidden flex-col items-center justify-center">
                    <div className="flex flex-row py-[9px] px-4 items-center justify-center gap-[8px]">
                      <div className="hidden flex-row items-start justify-start">
                        <img
                          className="relative w-6 h-6 overflow-hidden shrink-0"
                          alt=""
                          src="/starsharp4.svg"
                        />
                      </div>
                      <b className="relative tracking-[0.4px] leading-[24px] capitalize">
                        Tab
                      </b>
                    </div>
                  </div>
                  <div className="hidden flex-col items-center justify-center">
                    <div className="flex flex-row py-[9px] px-4 items-center justify-center gap-[8px]">
                      <div className="hidden flex-row items-start justify-start">
                        <img
                          className="relative w-6 h-6 overflow-hidden shrink-0"
                          alt=""
                          src="/starsharp5.svg"
                        />
                      </div>
                      <b className="relative tracking-[0.4px] leading-[24px] capitalize">
                        Tab
                      </b>
                    </div>
                  </div>
                  <div className="hidden flex-col items-center justify-center">
                    <div className="flex flex-row py-[9px] px-4 items-center justify-center gap-[8px]">
                      <div className="hidden flex-row items-start justify-start">
                        <img
                          className="relative w-6 h-6 overflow-hidden shrink-0"
                          alt=""
                          src="/starsharp6.svg"
                        />
                      </div>
                      <b className="relative tracking-[0.4px] leading-[24px] capitalize">
                        Tab
                      </b>
                    </div>
                  </div>
                  <div className="hidden flex-col items-center justify-center">
                    <div className="flex flex-row py-[9px] px-4 items-center justify-center gap-[8px]">
                      <div className="hidden flex-row items-start justify-start">
                        <img
                          className="relative w-6 h-6 overflow-hidden shrink-0"
                          alt=""
                          src="/starsharp7.svg"
                        />
                      </div>
                      <b className="relative tracking-[0.4px] leading-[24px] capitalize">
                        Tab
                      </b>
                    </div>
                  </div>
                  <div className="hidden flex-col items-center justify-center">
                    <div className="flex flex-row py-[9px] px-4 items-center justify-center gap-[8px]">
                      <div className="hidden flex-row items-start justify-start">
                        <img
                          className="relative w-6 h-6 overflow-hidden shrink-0"
                          alt=""
                          src="/starsharp8.svg"
                        />
                      </div>
                      <b className="relative tracking-[0.4px] leading-[24px] capitalize">
                        Tab
                      </b>
                    </div>
                  </div>
                  <div className="hidden flex-col items-center justify-center">
                    <div className="flex flex-row py-[9px] px-4 items-center justify-center gap-[8px]">
                      <div className="hidden flex-row items-start justify-start">
                        <img
                          className="relative w-6 h-6 overflow-hidden shrink-0"
                          alt=""
                          src="/starsharp9.svg"
                        />
                      </div>
                      <b className="relative tracking-[0.4px] leading-[24px] capitalize">
                        Tab
                      </b>
                    </div>
                  </div>
                </div>
                <div className="self-stretch relative bg-native-scrollbar-bg h-[15px] hidden">
                  <div className="absolute top-[calc(50%_+_3.5px)] left-[4px] rounded bg-tripdaddy-grey-400 w-[7px] h-[88px] [transform:_rotate(-90deg)] [transform-origin:0_0]" />
                </div>
              </div>
            </div>
          </div>
          <div className="absolute top-[496px] left-[calc(50%_-_178px)] rounded-xl bg-tripdaddy-amber-50 flex flex-col p-4 items-start justify-start gap-[16px] text-[32px] font-caveat">
            <div className="relative w-[149px] h-8">
              <b className="absolute top-[0px] left-[0px] tracking-[-1.5px] leading-[120%]">
                An overview:
              </b>
            </div>
            <div className="flex flex-col items-start justify-start gap-[16px] text-base font-typography-h5">
              <div className="flex flex-col items-start justify-start gap-[8px]">
                <div className="relative text-xs tracking-[0.4px] leading-[166%] inline-block w-[313px]">
                  Embark on an enchanting journey through the City of Light with
                  our 'Enchanting Adventures Through Paris' tour. Over the
                  course of four captivating days, you'll delve into the heart
                  and soul of Paris, experiencing its most iconic landmarks,
                  savoring delectable French cuisine, and immersing yourself in
                  its rich history and culture.
                </div>
                <div className="flex flex-col items-start justify-start gap-[8px]">
                  <div className="flex flex-col items-start justify-center gap-[8px]">
                    <div className="flex flex-row items-center justify-start gap-[8px]">
                      <img
                        className="relative w-4 h-4 overflow-hidden shrink-0"
                        alt=""
                        src="/localactivityfilled1.svg"
                      />
                      <div className="relative tracking-[0.15px] leading-[150%] font-semibold">
                        Activities:
                      </div>
                    </div>
                    <div className="flex flex-row items-center justify-start">
                      <div className="relative tracking-[0.15px] leading-[150%]">
                        18 (Changeable to your liking)
                      </div>
                    </div>
                  </div>
                  <div className="flex flex-col items-start justify-center gap-[8px]">
                    <div className="flex flex-row items-center justify-start gap-[8px]">
                      <img
                        className="relative w-4 h-4 overflow-hidden shrink-0"
                        alt=""
                        src="/calendartodayfilled1.svg"
                      />
                      <div className="relative tracking-[0.15px] leading-[150%] font-semibold">
                        Duration:
                      </div>
                    </div>
                    <div className="flex flex-row items-center justify-start">
                      <div className="relative tracking-[0.15px] leading-[150%]">
                        4 days
                      </div>
                    </div>
                  </div>
                </div>
                <div className="flex flex-col items-start justify-center gap-[8px]">
                  <div className="flex flex-row items-center justify-center gap-[8px]">
                    <img
                      className="relative w-4 h-4 overflow-hidden shrink-0"
                      alt=""
                      src="/locationcityfilled.svg"
                    />
                    <div className="relative tracking-[0.15px] leading-[150%] font-semibold">
                      Staying at:
                    </div>
                  </div>
                  <div className="flex flex-row items-start justify-start text-secondary-main">
                    <div className="flex flex-row items-center justify-start gap-[8px]">
                      <div className="relative tracking-[0.15px] leading-[150%]">
                        Le Château Étoile
                      </div>
                      <img
                        className="relative w-4 h-4 overflow-hidden shrink-0"
                        alt=""
                        src="/mdimapmarkerradius.svg"
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="flex flex-row items-start justify-start text-5xl text-primary-main font-caveat">
                <div className="flex flex-col items-start justify-start gap-[16px]">
                  <div className="rounded-borderradius flex flex-col items-start justify-start gap-[8px]">
                    <b className="relative tracking-[-1.5px] leading-[120%]">
                      Day 1:
                    </b>
                    <div className="flex flex-row items-center justify-center gap-[8px] text-xs text-text-primary font-typography-h5">
                      <div className="relative tracking-[0.4px] leading-[166%]">
                        Capturing the Essence of Paris
                      </div>
                      <div className="relative tracking-[0.4px] leading-[166%]">
                        |
                      </div>
                      <div className="relative tracking-[0.4px] leading-[166%]">
                        4 Activities
                      </div>
                    </div>
                  </div>
                  <div className="rounded-borderradius flex flex-col items-start justify-start gap-[8px]">
                    <b className="relative tracking-[-1.5px] leading-[120%]">
                      Day 2:
                    </b>
                    <div className="flex flex-row items-center justify-center gap-[8px] text-xs text-text-primary font-typography-h5">
                      <div className="relative tracking-[0.4px] leading-[166%]">
                        A Taste of Culture and Cuisine
                      </div>
                      <div className="relative tracking-[0.4px] leading-[166%]">
                        |
                      </div>
                      <div className="relative tracking-[0.4px] leading-[166%]">
                        5 Activities
                      </div>
                    </div>
                  </div>
                  <div className="rounded-borderradius flex flex-col items-start justify-start gap-[8px]">
                    <b className="relative tracking-[-1.5px] leading-[120%]">
                      Day 3:
                    </b>
                    <div className="flex flex-row items-center justify-center gap-[8px] text-xs text-text-primary font-typography-h5">
                      <div className="relative tracking-[0.4px] leading-[166%]">
                        Art, Culture, and Culinary Delights
                      </div>
                      <div className="relative tracking-[0.4px] leading-[166%]">
                        |
                      </div>
                      <div className="relative tracking-[0.4px] leading-[166%]">
                        6 Activities
                      </div>
                    </div>
                  </div>
                  <div className="rounded-borderradius flex flex-col items-start justify-start gap-[8px]">
                    <b className="relative tracking-[-1.5px] leading-[120%]">
                      Day 4:
                    </b>
                    <div className="flex flex-row items-center justify-center gap-[8px] text-xs text-text-primary font-typography-h5">
                      <div className="relative tracking-[0.4px] leading-[166%]">
                        Historic Treasures and Secluded Gardens
                      </div>
                      <div className="relative tracking-[0.4px] leading-[166%]">
                        |
                      </div>
                      <div className="relative tracking-[0.4px] leading-[166%]">
                        3 Activities
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="absolute top-[0px] left-[11px] flex flex-row items-center justify-center text-base text-tripdaddy-grey-600">
            <div className="flex flex-row items-center justify-start gap-[8px] text-xl">
              <div className="h-4 hidden flex-row items-start justify-start">
                <img
                  className="self-stretch relative max-h-full w-4 overflow-hidden shrink-0"
                  alt=""
                  src="/starsharp10.svg"
                />
              </div>
              <div className="flex flex-col items-start justify-start">
                <b className="relative tracking-[0.15px] leading-[160%]">
                  Home
                </b>
              </div>
            </div>
            <div className="w-[23px] flex flex-col items-center justify-start">
              <div className="relative tracking-[0.15px] leading-[150%] inline-block w-[7px] h-6 shrink-0">
                /
              </div>
            </div>
            <div className="hidden flex-row items-center justify-start gap-[8px] text-text-secondary">
              <div className="h-4 hidden flex-row items-start justify-start">
                <img
                  className="self-stretch relative max-h-full w-4 overflow-hidden shrink-0"
                  alt=""
                  src="/starsharp11.svg"
                />
              </div>
              <div className="flex flex-col items-start justify-start">
                <div className="relative tracking-[0.15px] leading-[150%]">
                  Link
                </div>
              </div>
            </div>
            <div className="w-[23px] hidden flex-col items-center justify-start text-text-secondary">
              <div className="relative tracking-[0.15px] leading-[150%] inline-block w-[7px] h-6 shrink-0">
                /
              </div>
            </div>
            <div className="flex flex-row items-center justify-start gap-[8px] text-xl text-tripdaddy-pink-500">
              <div className="h-4 hidden flex-row items-start justify-start">
                <img
                  className="self-stretch relative max-h-full w-4 overflow-hidden shrink-0"
                  alt=""
                  src="/starsharp12.svg"
                />
              </div>
              <div className="flex flex-col items-start justify-start">
                <b className="relative tracking-[0.15px] leading-[160%]">
                  Itinerary
                </b>
              </div>
            </div>
          </div>
          <div className="absolute top-[48px] left-[calc(50%_-_180px)] w-[360px] h-[400px]">
            <img
              className="absolute top-[399px] left-[calc(50%_-_180px)] w-[360px] h-6"
              alt=""
              src="/shape.svg"
            />
            <div className="absolute top-[-1px] left-[calc(50%_-_180px)] rounded-t-31xl rounded-b-none w-[360px] h-[400px] overflow-hidden bg-[url('/public/it_image.jpeg')] bg-cover bg-no-repeat bg-[top]">
              <div className="absolute top-[178px] left-[0px] rounded-tl-none rounded-tr-borderradius rounded-b-none bg-cornsilk flex flex-col p-4 items-start justify-start gap-[16px]">
                <b className="relative text-5xl leading-[133.4%] inline-block w-[277px]">
                  Enchanting Adventures Through Paris
                </b>
                <div className="flex flex-row items-start justify-start">
                  <div className="flex flex-row items-center justify-center">
                    <div className="flex flex-row items-center justify-start gap-[8px]">
                      <img
                        className="relative w-6 h-6 overflow-hidden shrink-0"
                        alt=""
                        src="/calendartodayfilled2.svg"
                      />
                      <b className="relative tracking-[0.15px] leading-[160%]">
                        4 days
                      </b>
                    </div>
                  </div>
                </div>
                <div className="flex flex-row items-center justify-start gap-[8px]">
                  <img
                    className="relative w-6 h-6 overflow-hidden shrink-0"
                    alt=""
                    src="/locationonfilled1.svg"
                  />
                  <b className="relative tracking-[0.15px] leading-[160%] inline-block w-[131px] shrink-0">
                    Paris, France
                  </b>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="absolute top-[0px] left-[0px] bg-secondary-contrast shadow-[0px_1px_14px_rgba(0,_0,_0,_0.12),_0px_5px_8px_rgba(0,_0,_0,_0.14),_0px_3px_5px_-1px_rgba(0,_0,_0,_0.2)] w-[360px] h-12 overflow-hidden flex flex-row p-4 box-border items-center justify-between text-smi text-secondary-contrast">
          <img
            className="relative w-[104px] h-[19px] cursor-pointer"
            alt=""
            src="/logo.svg"
            onClick={onLogoClick}
          />
          <div className="w-[136px] h-[42px] flex flex-row items-center justify-center">
            <div className="flex flex-row items-start justify-start gap-[8px]">
              <div className="rounded-borderradius bg-secondary-main shadow-[0px_1px_5px_rgba(0,_0,_0,_0.12),_0px_2px_2px_rgba(0,_0,_0,_0.14),_0px_3px_1px_-2px_rgba(0,_0,_0,_0.2)] overflow-hidden flex flex-col py-1 px-2.5 items-center justify-center">
                <div className="flex flex-row items-center justify-center gap-[8px]">
                  <img
                    className="relative w-4 h-[18px] hidden"
                    alt=""
                    src="/masked-icon22.svg"
                  />
                  <b className="relative tracking-[0.46px] leading-[22px] capitalize">
                    Sign Up
                  </b>
                  <img
                    className="relative w-4 h-[18px] hidden"
                    alt=""
                    src="/masked-icon23.svg"
                  />
                </div>
              </div>
              <div className="rounded-borderradius overflow-hidden flex flex-col py-1 px-[5px] items-center justify-center text-secondary-main">
                <div className="flex flex-row items-center justify-center gap-[8px]">
                  <img
                    className="relative w-4 h-[18px] hidden"
                    alt=""
                    src="/masked-icon24.svg"
                  />
                  <b className="relative tracking-[0.46px] leading-[22px] capitalize">
                    Log in
                  </b>
                  <img
                    className="relative w-4 h-[18px] hidden"
                    alt=""
                    src="/masked-icon25.svg"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {isTransportInformationModalOpen && (
        <PortalPopup
          overlayColor="rgba(113, 113, 113, 0.3)"
          placement="Centered"
          onOutsideClick={closeTransportInformationModal}
        >
          <TransportInformationModal onClose={closeTransportInformationModal} />
        </PortalPopup>
      )}
      {isTransportInformationModal1Open && (
        <PortalPopup
          overlayColor="rgba(113, 113, 113, 0.3)"
          placement="Centered"
          onOutsideClick={closeTransportInformationModal1}
        >
          <TransportInformationModal
            onClose={closeTransportInformationModal1}
          />
        </PortalPopup>
      )}
      {isTransportInformationModal2Open && (
        <PortalPopup
          overlayColor="rgba(113, 113, 113, 0.3)"
          placement="Centered"
          onOutsideClick={closeTransportInformationModal2}
        >
          <TransportInformationModal
            onClose={closeTransportInformationModal2}
          />
        </PortalPopup>
      )}
    </>
  );
};

export default ItineraryPageNotSignedUp;
