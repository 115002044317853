import { createGlobalStyle } from "styled-components";


export default createGlobalStyle`
    body {
      margin: 0; line-height: normal;
      font-family: Quicksand;
    }
    . MuiTabs-indicator {
      background-color: var(--primary-main, #D81B60);
      }

    .MuiButton-contained {
      text-transform: unset !important;
    }

    .img.darker {
      transition: all 0.2s ease-in-out;
    }
    
    .img.darker:hover {
      filter: brightness(50%);
    }

    a:link {
      text-decoration: none;
      }

a:visited {
      text-decoration: none;
}

a:hover {
      text-decoration: none;
}

a:active {
      text-decoration: none;
}
:root {

/* fonts */
--typography-h6: Quicksand;
--typography-h1-handwriting: Caveat;

/* font sizes */
--typography-h6-size: 20px;
--typography-h1-handwriting-size: 72px;
--button-large-size: 15px;
--typography-caption-size: 12px;
--input-value-size: 16px;
--chip-label-size: 13px;

/* Colors */
--color-white: #fff;
--color-gray-100: rgba(0, 0, 0, 0.42);
--color-gray-200: rgba(0, 0, 0, 0.08);
--color-gray-300: rgba(0, 0, 0, 0.38);
--color-gray-400: rgba(0, 0, 0, 0.6);
--color-gray-500: rgba(0, 0, 0, 0.87);
--color-crimson: #d81b60;
--color-gold: #ffd740;
--color-lightskyblue: #81d4fa;
--color-palevioletred: #f48fb1;
--color-darkslateblue: #0057b2;
--color-silver: #bdbdbd;
--color-darkgreen: #2e7d32;

/* Gaps */
--gap-5xs: 8px;
--gap-5xl: 24px;
--gap-7xs: 6px;

/* Paddings */
--padding-base: 16px;
--padding-5xs: 8px;
--padding-3xl: 22px;
--padding-10xs: 3px;
--padding-9xs: 4px;
--padding-7xs: 6px;

/* border radiuses */
--br-78xl: 97px;
--br-84xl: 103px;
--br-120xl: 139px;
--br-9xs: 4px;
--br-81xl: 100px;
--br-45xl: 64px;

/* Effects */
--elevation-5: 0px 1px 14px rgba(0, 0, 0, 0.12), 0px 5px 8px rgba(0, 0, 0, 0.14), 0px 3px 5px -1px rgba(0, 0, 0, 0.2);
--elevation-4: 0px 1px 10px rgba(0, 0, 0, 0.12), 0px 4px 5px rgba(0, 0, 0, 0.14), 0px 2px 4px -1px rgba(0, 0, 0, 0.2);
--elevation-2: 0px 1px 5px rgba(0, 0, 0, 0.12), 0px 2px 2px rgba(0, 0, 0, 0.14), 0px 3px 1px -2px rgba(0, 0, 0, 0.2);
}
`;
