import React from "react";

const NoHover = ({ mouseEnter, mouseLeave, label }) => {

    return (
        <div onMouseEnter={mouseEnter}
            onMouseLeave={mouseLeave}
            className="flex flex-col w-full items-center">
                <div style={{
                    position: "absolute",
                    top: "-20px",
                    color: "#AD1457",
                    fontWeight: "bold",
                    fontSize: "20px",
                }}>
                    {label}
                </div>
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                <path d="M12 2C8.13 2 5 5.13 5 9C5 14.25 12 22 12 22C12 22 19 14.25 19 9C19 5.13 15.87 2 12 2ZM12 11.5C10.62 11.5 9.5 10.38 9.5 9C9.5 7.62 10.62 6.5 12 6.5C13.38 6.5 14.5 7.62 14.5 9C14.5 10.38 13.38 11.5 12 11.5Z" fill="#AD1457" />
            </svg>

        </div>

    )
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="48" height="48" viewBox="0 0 48 48" fill="none">
            <path id="Vector" d="M24 4C16.26 4 10 10.26 10 18C10 28.5 24 44 24 44C24 44 38 28.5 38 18C38 10.26 31.74 4 24 4ZM24 23C21.24 23 19 20.76 19 18C19 15.24 21.24 13 24 13C26.76 13 29 15.24 29 18C29 20.76 26.76 23 24 23Z" fill="#AD1457" />
        </svg>
    )
}

const Hover = ({ data, city, image, url, onMouseLeave }) => {
    // console.log(data)
    return (
        <div onMouseLeave={onMouseLeave} className="flex flex-col w-full items-center">
            <img
                src="https://file.rendit.io/n/soT2VCQ1nnSfg2LmVpnL.svg"
                id="LocationOnFilled"
                className="w-6"
            />
            <div
                id="Bubble"
                style={{
                    width: "300px",
                    height: "100px",
                    transform: "translate(-50%, -100%)",
                    position: "absolute",
                    left: "50%",
                    top: "0px",
                    zIndex: "1000",
                }}
                className="shadow-[0px_2px_1px_-1px_rgba(0,_0,_0,_0.2),_0px_1px_1px_0px_rgba(0,_0,_0,_0.14),_0px_1px_3px_0px_rgba(0,_0,_0,_0.12)] bg-white self-stretch flex flex-row gap-2 items-center pt-2 px-2 rounded-lg"
            >
                <img
                    style={{
                        width: "92px",
                        height: "92px",
                        borderRadius: "var(--borderRadius, 4px)"
                    }}
                    src={image}
                    id="UnsplashFlkxnZdebMk"
                    className="mb-2 w-24 shrink-0"
                />
                <div className="self-start flex flex-col justify-between gap-2 w-3/5 items-start">
                    <div className="text-sm font-['Quicksand'] font-bold tracking-[0.17] leading-[20px] text-black/87">
                        {data.point_of_interest}
                    </div>
                    <div className="text-sm font-['Quicksand'] font-bold tracking-[0.17] leading-[20px] text-[#0057b2]">
                        {city}
                    </div>
                    <div
                        style={{
                            cursor: "pointer"
                        }}
                        onClick={() => window.open(url, "_blank")}
                        id="Button2"
                        className="overflow-hidden self-stretch flex flex-row gap-2 items-center px-1 rounded"
                    >
                        <div
                            id="Button1"
                            className="text-xs font-['Quicksand'] font-bold tracking-[0.46] leading-[22px] capitalize text-[#d81b60] my-1"
                        >
                            See directions
                        </div>
                        <img
                            src="https://file.rendit.io/n/06GAN9NMBMlOU9Dsfp0g.svg"
                            id="DirectionsFilled"
                            className="w-4 shrink-0"
                        />
                    </div>
                </div>
            </div>
        </div>
    )
}
const Marker = ({ data, trip_data, image, text, tooltip, $hover }) => {
    const handleClick = () => {
        console.log(`You clicked on ${tooltip}, hover ${$hover}`);
    };
    // console.log(data)
    const [hover, setHover] = React.useState(false);
    const mouseEnter = () => {
        setHover(true)
    }
    const mouseLeave = () => {
        setHover(false)
    }

    const directions_url = `https://www.google.com/maps/dir/?api=1&destination=${data && data.place_details && data.place_details.candidates && data.place_details.candidates[0] ? data.place_details.candidates[0].name : ""}&travelmode=driving`

    return (
        hover ?
            <Hover onMouseLeave={mouseLeave} data={data} image={image} url={directions_url} city={trip_data.city_or_address} />
            :
            <NoHover mouseEnter={mouseEnter} mouseLeave={mouseLeave} label={text} />

        // <div className={$hover ? "circle hover" : "circle"} onClick={handleClick}>
        //   <span className="circleText" title={tooltip}>
        //     {text}
        //   </span>
        // </div>
    );
};

export default Marker;