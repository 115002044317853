import React from 'react';
import MainHeader from '../components/Header';
import Footer from '../components/Footer';

const TermsOfService = () => {
    return (
        <>
            <MainHeader />
            <div style={{padding: "20px"}}>
                <h1>TripDaddy Terms of Service</h1>
                <p><strong>Last Updated:</strong> January 7, 2024</p>
                <p>These terms govern your use of TripDaddy, including participation in our online community, content contribution, and site browsing. Please review these terms regularly as they may change.</p>

                <h2>1. Using TripDaddy Sites</h2>
                <ul>
                    <li>By using any TripDaddy site, you agree to these terms.</li>
                    <li>TripDaddy sites are for personal, non-commercial use only.</li>
                    <li>Users under 18 require parental permission.</li>
                    <li>Our Community Guidelines apply to your site use.</li>
                </ul>

                <h2>2. Trademarks and Copyright</h2>
                <ul>
                    <li>Content on TripDaddy sites is owned by GoodVibesOnlyAI Inc. or licensed to us. Copying or use without permission is prohibited.</li>
                    <li>Our trademarks may not be used without our permission.</li>
                    <li>Further information on intellectual property can be found <a href="/">here</a>.</li>
                </ul>

                <h2>3. Contributing to TripDaddy</h2>
                <ul>
                    <li>Interaction with third-party applications on our site creates a separate legal relationship with the application provider.</li>
                    <li>Your contributions to the site are licensed to us non-exclusively, perpetually, and royalty-free.</li>
                    <li>We may edit, store, adapt, or use your content, including feedback, for any purpose.</li>
                    <li>You consent to alteration of your content as necessary and to not always being attributed authorship.</li>
                </ul>
                <h2>4. Prohibited Content</h2>
                <ul>
                    <li>Ensure your content does not defame, infringe intellectual property, contain personal information against our privacy policy, contain commercial advertising, or include prohibited content as per the Australian Classification Board.</li>
                </ul>
                <h2>5. Removal of Content and Termination of Accounts</h2>
                <ul>
                    <li>We may remove content and terminate or suspend accounts for breaches of these terms.</li>
                </ul>
                <h2>6. Reporting Abuse and Defamation</h2>
                <ul>
                    <li>Use the "Report Abuse" function for any content that infringes these terms.</li>
                    <li>Defamation notifications must include specific information and be sent to <a href="mailto:hello@tripdaddy.app">hello@tripdaddy.app</a>.</li>
                </ul>
                <h2>7. Copyright Infringements</h2>
                <ul>
                    <li>For DMCA notices, provide detailed information as specified and send to <a href="mailto:hello@tripdaddy.app">hello@tripdaddy.app</a>.</li>
                </ul>
                <h2>8. Privacy</h2>
                <ul>
                    <li>Personal information collection purposes include site administration, communication about your content, and legal compliance.</li>
                    <li>Review our Privacy Policy <a href="/privacy-policy">here</a>.</li>
                </ul>
                <h2>9. Liability and Disclaimer</h2>
                <ul>
                    <li>You are responsible for content you upload and must have necessary permissions and rights.</li>
                    <li>You indemnify TripDaddy against losses due to content you upload.</li>
                    <li>Our liability is limited as per governing law exclusions.</li>
                </ul>
                <h2>10. Liability for Users Outside Australia, the UK, and the EU</h2>
                <ul>
                    <li>We are not responsible for losses in connection with the use of the site. The site is provided “as is”.</li>
                    <li>We exclude liability to the maximum extent permitted by law.</li>
                </ul>
                <h2>11. Liability for Users Within Australia, the UK, and the EU</h2>
                <ul>
                    <li>We use reasonable care and skill but do not guarantee the site's accuracy.</li>
                    <li>Subject to statutory rights, we are not responsible for certain losses.</li>
                    <li>Verify critical information independently before travel.</li>
                </ul>
                <h2>12. Governing Law</h2>
                <ul>
                    <li>Legal issues will be dealt with under the laws of California, USA.</li>
                </ul>
                <h2>Changes to Terms</h2>
                <ul>
                    <li>We reserve the right to modify these terms. Continued use of the site post-changes constitutes acceptance of the new terms.</li>
                </ul>
                <h2>Contact</h2>
                <ul>
                    <li>For any queries or complaints, please contact us at <a href="mailto:hello@tripdaddy.app">hello@tripdaddy.app</a></li>
                </ul>
            </div>
        <Footer />
        </>
    );
};

export default TermsOfService;
