import axios from 'axios';

async function getTravelInfo(locations) {
    // console.log(locations);
    const response = await axios.post(process.env.REACT_APP_BACKEND_URL + '/api/distance', {
        origins: locations,
        destinations: locations,
    });
    
    return response.data;
}

export default getTravelInfo;