import * as React from "react";
import { useNavigate, NavLink } from "react-router-dom";
import jwt_decode from 'jwt-decode';
import styled from 'styled-components';
import { Button } from "@mui/material";
import Avatar from "@mui/material/Avatar";

const ActiveButton = styled(Button)`
color: var(--primary-main, #D81B60);
font-feature-settings: 'clig' off, 'liga' off;

font-family: Quicksand;
font-size: 20px;
font-style: normal;
font-weight: 700;
line-height: 160%; /* 32px */
letter-spacing: 0.15px;
`
const ProfileNameContainer = styled.div`
color: #080a0b;
font-size: 16px;
font-family: "Poppins";
font-weight: 700;
line-height: 22px;
padding: 10px;
text-decoration: none;
`


const MobileNavbar = () => {
    const navigate = useNavigate();
    const [isuserLoggedIn, setIsuserLoggedIn] = React.useState(false);
    const [user, setUser] = React.useState(null);
    const token = localStorage.getItem('token');
    if (token && !isuserLoggedIn) {
        try {
        setIsuserLoggedIn(true);
        setUser(jwt_decode(token));
        } catch (error) {
            setIsuserLoggedIn(false);
            localStorage.removeItem('token');
        }
        
    }


    return <div style={{ width: "100%" }} className="absolute top-[0px] left-[0px] bg-secondary-contrast shadow-[0px_1px_14px_rgba(0,_0,_0,_0.12),_0px_5px_8px_rgba(0,_0,_0,_0.14),_0px_3px_5px_-1px_rgba(0,_0,_0,_0.2)] w-[360px] h-12 overflow-hidden flex flex-row p-4 box-border items-center justify-between text-smi text-secondary-contrast">
        <img
            className="relative w-[104px] h-[19px] cursor-pointer"
            alt=""
            src="/logo.svg"
            onClick={() => { window.location.href = "/"; }}
        />
        <div style={{width: "100%", justifyContent: "flex-end"}} className="w-[136px] h-[42px] flex flex-row items-center justify-center">
            <div className="flex flex-row items-start justify-start gap-[8px]">
            {isuserLoggedIn ?
            (<>
            <NavLink onClick={()=>{window.location.href="/"}} style={{ textDecoration: "none" }}>
              <Button>Home</Button>
            </NavLink>
            <NavLink to="/mytrips" style={{ textDecoration: "none" }}>
              <div 
              // style={{borderBottom: "2px solid", borderBottomColor:"var(--primary-main, #D81B60)"}}
              >
              <ActiveButton>My Trips</ActiveButton>  
              {/* style={{color: "var(--primary-main, #D81B60)" , }} */}
              </div>
            </NavLink>
            <NavLink to="/profile"  style={{ textDecoration: "none" }}>
            <Avatar
                alt={user.firstname}
                src="/broken-image.jpg"
                sx={{ width: 32, height: 32 }}
              />
            </NavLink>
              {/* <ProfileNameContainer>Hi, test</ProfileNameContainer> */}
            </>
            ) : (
                <>
                {/* <div onClick={() => {
                    navigate("/register");
                }} className="rounded-borderradius bg-secondary-main shadow-[0px_1px_5px_rgba(0,_0,_0,_0.12),_0px_2px_2px_rgba(0,_0,_0,_0.14),_0px_3px_1px_-2px_rgba(0,_0,_0,_0.2)] overflow-hidden flex flex-col py-1 px-2.5 items-center justify-center">
                    <div className="flex flex-row items-center justify-center gap-[8px]">
                        <img
                            className="relative w-4 h-[18px] hidden"
                            alt=""
                            src="/masked-icon2.svg"
                        />
                        <b className="relative tracking-[0.46px] leading-[22px] capitalize">
                            Sign Up
                        </b>
                        <img
                            className="relative w-4 h-[18px] hidden"
                            alt=""
                            src="/masked-icon3.svg"
                        />
                    </div>
                </div> */}
                <div onClick={() => {
                    navigate("/login");
                }} className="rounded-borderradius overflow-hidden flex flex-col py-1 px-[5px] items-center justify-center text-secondary-main">
                    <div className="flex flex-row items-center justify-center gap-[8px]">
                        <img
                            className="relative w-4 h-[18px] hidden"
                            alt=""
                            src="/masked-icon4.svg"
                        />
                        <b className="relative tracking-[0.46px] leading-[22px] capitalize">
                            Log in
                        </b>
                        <img
                            className="relative w-4 h-[18px] hidden"
                            alt=""
                            src="/masked-icon5.svg"
                        />
                    </div>
                </div>
                </>
            )}
            </div>
        </div>
    </div>;



};

export default MobileNavbar;