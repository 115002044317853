import * as React from 'react';
import { useRef } from "react";
import { TextField, InputAdornment } from '@mui/material';
import MainHeader from '../components/Header';
import axios from "axios";
import { useNavigate, useSearchParams } from 'react-router-dom';
import ReCAPTCHA from "react-google-recaptcha";
import { RECAPTCHA_SITEKEY } from "../constants";
import Footer from '../components/Footer';


const Register = () => {
    const navigate = useNavigate();
    const reCaptchaRef = useRef();
    const [searchParams, setSearchParams] = useSearchParams();
  
    const captchaOnChange = (value) => {
      // console.log("Captcha value:", value);
    };
  
    const handleCaptchaExpire = () => {
      reCaptchaRef.current.reset();
    };
    const [redirect, setRedirect] = React.useState(false);
  
    React.useEffect(() => {
      if (searchParams.has('redirect')) {
        setRedirect(true);
      }
    }, []);
  
  
    const handleSubmit = async (event) => {
      event.preventDefault();
      if (!event.target.checkValidity()) {
        alert("Please Fill the form.");
        return
      }
  
      const handleResponse = (response) => {
        console.log(response.data);
        alert("Signup Successful");
        if (redirect) {
          navigate("/login?it=" + searchParams.get('it') + "&token=" + searchParams.get('token')+ "&redirect=1");
          return
        }
        navigate("/login");
      }
  
      const handleError = (error) => {
        console.log(error);
        if (error.response.status === 500) {
          alert("Some error occured on the server. Please try again later.");
          return
        }
        alert("Signup Failed : " + error.response.data.message);
      }
  
      const data = new FormData(document.getElementById("signup-form"));
      let bodyContent = {
        "firstname": data.get('firstName'),
        "lastname": data.get('lastName'),
        "email": data.get('email'),
        "password": data.get('password'),
      };
  
      let reqOptions = {
        url: process.env.REACT_APP_BACKEND_URL + "/api/auth/signup",
        method: "POST",
        data: bodyContent,
        withCredentials: true,
      }
  
      axios.request(reqOptions).then(handleResponse).catch(handleError);
  
    };
    const submitWithCaptcha = (event) => {
      event.preventDefault();
  
      reCaptchaRef.current.executeAsync().then((token) => {
        // console.log(token);
        handleSubmit(event);
      });
    };

    return (
        <div className="relative bg-secondary-contrast w-full h-[800px] overflow-hidden text-center text-15xl text-primary-main font-typography-body2">
            <div className="absolute top-[calc(50%_-_203px)] left-[calc(50%_-_212px)] w-[424px] flex flex-col items-center justify-center gap-[16px]">
                <div className="flex flex-col items-center justify-center gap-[8px]">
                    <b style={{fontSize: "34px"}} className="relative tracking-[0.25px] leading-[123.5%] inline-block w-[398px]">
                        Join the jounrey!
                    </b>
                    <b className="relative text-xl tracking-[0.15px] leading-[160%] inline-block text-text-primary w-[391px]">
                        Sign up with TripDaddy
                    </b>
                </div>
                <form id="signup-form" onSubmit={submitWithCaptcha}>
                <div className="flex flex-col items-center justify-center gap-[24px] text-left text-xs text-text-secondary">
                    <TextField style={{ width: "292px" }}
                        name="firstName"
                        //   onChange={handleChange}
                        placeholder="John Doe"
                        label="Full Name"
                        type='text'
                        required
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                </InputAdornment>
                            ),
                        }}
                        variant="standard"
                    />
                    <TextField style={{ width: "292px" }}
                        name="email"
                        //   onChange={handleChange}
                        placeholder="email@domain.com"
                        label="Email"
                        type='email'
                        required
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                </InputAdornment>
                            ),
                        }}
                        variant="standard"
                    />
                    <TextField style={{ width: "292px" }}
                        name="password"
                        //   onChange={handleChange}
                        placeholder="********"
                        label="Password"
                        type='password'
                        required
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                </InputAdornment>
                            ),
                        }}
                        variant="standard"
                    />
                    <ReCAPTCHA
                            sitekey={RECAPTCHA_SITEKEY}
                            size="invisible"
                            onChange={captchaOnChange}
                            ref={reCaptchaRef}
                        />
                
                    <div className="flex flex-col items-center justify-center gap-[8px] text-mini text-secondary-contrast">
                        <button type='submit' style={{cursor: "pointer",     "border-width": "0px", "color": "#fff"}} className="rounded-borderradius bg-primary-main shadow-[0px_1px_5px_rgba(0,_0,_0,_0.12),_0px_2px_2px_rgba(0,_0,_0,_0.14),_0px_3px_1px_-2px_rgba(0,_0,_0,_0.2)] w-[292px] overflow-hidden flex flex-col items-center justify-center py-2 px-[22px] box-border">
                            <div className="flex flex-row items-center justify-center gap-[8px]">
                                <img
                                    className="relative w-[18px] h-[22px] hidden"
                                    alt=""
                                    src="/masked-icon.svg"
                                />
                                <b className="relative tracking-[0.46px] leading-[26px] capitalize">
                                    Sign Up
                                </b>
                                <img
                                    className="relative w-[18px] h-[22px] hidden"
                                    alt=""
                                    src="/masked-icon1.svg"
                                />
                            </div>
                        </button>
                        <div className="flex flex-row items-center justify-center gap-[8px] text-sm text-text-primary">
                            <div className="relative tracking-[0.17px] leading-[143%]">
                                Already a member?
                            </div>
                            <div style={{cursor: "pointer"}} onClick={()=>{navigate("/login")}} className="rounded-borderradius overflow-hidden flex flex-col items-center justify-center py-2 px-[11px] text-mini text-primary-main">
                                <div className="flex flex-row items-center justify-center gap-[8px]">
                                    <img
                                        className="relative w-[18px] h-[22px] hidden"
                                        alt=""
                                        src="/masked-icon2.svg"
                                    />
                                    <b className="relative tracking-[0.46px] leading-[26px] capitalize">
                                        log in
                                    </b>
                                    <img
                                        className="relative w-[18px] h-[22px] hidden"
                                        alt=""
                                        src="/masked-icon3.svg"
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                
                </div>
                </form>
            </div>
            <MainHeader />
            <Footer />
        </div>
    );
};

export default Register;
