import * as React from 'react';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
// import Link from '@mui/material/Link';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import axios from "axios";
import { useNavigate, useSearchParams } from 'react-router-dom';
import ReCAPTCHA from "react-google-recaptcha";
import { RECAPTCHA_SITEKEY } from "../constants";
import { useRef } from 'react';
import { NavLink, Link } from 'react-router-dom';

function Copyright(props) {
  return (
    <Typography variant="body2" color="text.secondary" align="center" {...props}>
      {'Copyright © '}
      <NavLink color="inherit" to="/">
        TripDaddy
      </NavLink>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}


const defaultTheme = createTheme();

export default function SignUp() {
  const navigate = useNavigate();
  const reCaptchaRef = useRef();
  const [searchParams, setSearchParams] = useSearchParams();

  const captchaOnChange = (value) => {
    // console.log("Captcha value:", value);
  };

  const handleCaptchaExpire = () => {
    reCaptchaRef.current.reset();
  };
  const [redirect, setRedirect] = React.useState(false);

  React.useEffect(() => {
    if (searchParams.has('redirect')) {
      setRedirect(true);
    }
  }, []);


  const handleSubmit = async (event) => {
    event.preventDefault();
    if (!event.target.checkValidity()) {
      alert("Please Fill the form.");
      return
    }

    const handleResponse = (response) => {
      console.log(response.data);
      alert("Signup Successful");
      if (redirect) {
        navigate("/login?it=" + searchParams.get('it') + "&token=" + searchParams.get('token')+ "&redirect=1");
        return
      }
      navigate("/login");
    }

    const handleError = (error) => {
      console.log(error);
      if (error.response.status === 500) {
        alert("Some error occured on the server. Please try again later.");
        return
      }
      alert("Signup Failed : " + error.response.data.message);
    }

    const data = new FormData(document.getElementById("signup-form"));
    let bodyContent = {
      "firstname": data.get('firstName'),
      "lastname": data.get('lastName'),
      "email": data.get('email'),
      "password": data.get('password'),
    };

    let reqOptions = {
      url: process.env.REACT_APP_BACKEND_URL + "/api/auth/signup",
      method: "POST",
      data: bodyContent,
      withCredentials: true,
    }

    axios.request(reqOptions).then(handleResponse).catch(handleError);

  };
  const submitWithCaptcha = (event) => {
    event.preventDefault();

    reCaptchaRef.current.executeAsync().then((token) => {
      // console.log(token);
      handleSubmit(event);
    });
  };
  return (
    <ThemeProvider theme={defaultTheme}>
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <Box
          sx={{
            marginTop: 8,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
            <LockOutlinedIcon />
          </Avatar>
          <Typography component="h1" variant="h5">
            Sign up
          </Typography>
          <Box component="form" id="signup-form" noValidate onSubmit={submitWithCaptcha} sx={{ mt: 3 }}>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6}>
                <TextField
                  autoComplete="given-name"
                  name="firstName"
                  required
                  fullWidth
                  id="firstName"
                  label="First Name"
                  autoFocus
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  required
                  fullWidth
                  id="lastName"
                  label="Last Name"
                  name="lastName"
                  autoComplete="family-name"
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  required
                  fullWidth
                  id="email"
                  label="Email Address"
                  name="email"
                  autoComplete="email"
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  required
                  fullWidth
                  name="password"
                  label="Password"
                  type="password"
                  id="password"
                  autoComplete="new-password"
                />
              </Grid>
              <Grid item xs={12}>
                <FormControlLabel
                  control={<Checkbox value="allowExtraEmails" color="primary" />}
                  label="I want to receive inspiration, marketing promotions and updates via email."
                />
              </Grid>
            </Grid>
            <ReCAPTCHA
              sitekey={RECAPTCHA_SITEKEY}
              size="invisible"
              onChange={captchaOnChange}
              ref={reCaptchaRef}
            />
            <Button
              type="submit"
              fullWidth
              variant="contained"
              sx={{ mt: 3, mb: 2 }}
            >
              Sign Up
            </Button>
            <Grid container justifyContent="flex-end">
              <Grid item>
                <Link to="/login" variant="body2">
                  Already have an account? Sign in
                </Link>
              </Grid>
            </Grid>
          </Box>
        </Box>
        <Copyright sx={{ mt: 5 }} />
      </Container>
    </ThemeProvider>
  );
}