import * as React from "react";
import { useNavigate } from "react-router-dom";
import { TextField, InputAdornment } from "@mui/material";
import MainHeader from "../components/Header";
import axios from "axios";
import jwt_decode from "jwt-decode";
import Footer from "../components/Footer";
axios.defaults.headers.common['Authorization'] = `Bearer ${localStorage.getItem('token')}`;



const Profile = () => {

    const [isuserLoggedIn, setIsuserLoggedIn] = React.useState(false);
    const [user, setUser] = React.useState(null);
    const token = localStorage.getItem('token');
    const [profile, setProfile] = React.useState({
        airport: "",
        travelers: "",
    });

    if (token && !isuserLoggedIn) {
        try {
            setIsuserLoggedIn(true);
            setUser(jwt_decode(token));
        } catch (error) {
            setIsuserLoggedIn(false);
            localStorage.removeItem('token');
        }

    }

    const navigate = useNavigate();

    if (!token) {
        alert("Please login to continue.");
        window.location.href = "/login";
    }

    const getSavedProfile = async () => {
        axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
        const handleResponse = (response) => {
            console.log(response.data);
            setProfile({
                airport: response.data.nearest_airport,
                travelers: response.data.no_of_travellers,
            });
        }

        const handleError = (error) => {
            console.log(error);
            if (error.response.status === 401) {
                localStorage.removeItem('token');
                alert("Please login to continue.");
                navigate("/login");
                return
            }
            if (error.response.status === 500) {
                alert("Some error occured on the server. Please try again later.");
                return
            }
            alert("Save Failed : " + error.response.data.message);
        }

        let reqOptions = {
            url: process.env.REACT_APP_BACKEND_URL + "/api/auth/getProfile",
            method: "GET",
            withCredentials: true,
        }

        axios.request(reqOptions).then(handleResponse).catch(handleError);
    }

    React.useEffect(() => {
        if (token) {
            getSavedProfile();
        }
    }, [token]);




    const saveProfile = async (event) => {
        event.preventDefault();
        if (!event.target.checkValidity()) {
            alert("Please Fill the form.");
            return
        }

        const handleResponse = (response) => {
            // console.log(response.data);
            alert("Profile Saved Successfully.");
            navigate("/");
        }

        const handleError = (error) => {
            console.log(error);
            if (error.response.status === 401) {
                localStorage.removeItem('token');
                alert("Please login to continue.");
                // navigate("/login");
                return
            }
            if (error.response.status === 500) {
                alert("Some error occured on the server. Please try again later.");
                return
            }
            alert("Save Failed : " + error.response.data.message);
        }

        const data = new FormData(document.getElementById("profile-form"));
        let bodyContent = {
            "nearest_airport": data.get('airport'),
            "no_of_travellers": data.get('travelers'),
            "no_of_children": "0",
        }
        console.log(bodyContent);
        let reqOptions = {
            url: process.env.REACT_APP_BACKEND_URL + "/api/auth/saveProfile",
            method: "POST",

            data: bodyContent,
            withCredentials: true,
        }

        axios.request(reqOptions).then(handleResponse).catch(handleError);
    }


    return (
        <div className="relative bg-secondary-contrast w-full h-[800px] overflow-hidden text-center text-15xl text-primary-main font-typography-body2">
            <div className="absolute top-[calc(50%_-_203px)] left-[calc(50%_-_212px)] w-[424px] flex flex-col items-center justify-center gap-[16px]">
                <div className="flex flex-col items-center justify-center gap-[8px]">
                    <b style={{ fontSize: "34px" }} className="relative tracking-[0.25px] leading-[123.5%] inline-block w-[398px]">
                        Profile
                    </b>
                    <b className="relative text-xl tracking-[0.15px] leading-[160%] inline-block text-text-primary w-[391px]">
                        Customize your experience
                    </b>
                </div>
                <form id="profile-form" onSubmit={saveProfile} >
                    <div className="flex flex-col items-center justify-center gap-[24px] text-left text-xs text-text-secondary">
                        <TextField style={{ width: "292px" }}
                            name="airport"
                            //   onChange={handleChange}
                            placeholder="JFK"
                            label="Home Airport "
                            type='text'
                            value={profile.airport}
                            required
                            onChange={(e) => setProfile({ ...profile, airport: e.target.value })}
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">
                                    </InputAdornment>
                                ),
                            }}
                            variant="standard"
                        />
                        <TextField style={{ width: "292px" }}
                            name="travelers"
                            //   onChange={handleChange}
                            placeholder="1"
                            label="Travelers "
                            type='number'
                            value={profile.travelers}
                            onChange={(e) => setProfile({ ...profile, travelers: e.target.value })}
                            required
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">
                                    </InputAdornment>
                                ),
                            }}
                            variant="standard"
                        />

                        <div className="flex flex-col items-center justify-center gap-[8px] text-mini text-secondary-contrast">
                            <button type='submit' style={{ cursor: "pointer", "border-width": "0px", "color": "#fff" }} className="rounded-borderradius bg-primary-main shadow-[0px_1px_5px_rgba(0,_0,_0,_0.12),_0px_2px_2px_rgba(0,_0,_0,_0.14),_0px_3px_1px_-2px_rgba(0,_0,_0,_0.2)] w-[292px] overflow-hidden flex flex-col items-center justify-center py-2 px-[22px] box-border">
                                <div className="flex flex-row items-center justify-center gap-[8px]">
                                    <img
                                        className="relative w-[18px] h-[22px] hidden"
                                        alt=""
                                        src="/masked-icon.svg"
                                    />
                                    <b className="relative tracking-[0.46px] leading-[26px] capitalize">
                                        Save
                                    </b>
                                    <img
                                        className="relative w-[18px] h-[22px] hidden"
                                        alt=""
                                        src="/masked-icon1.svg"
                                    />
                                </div>
                            </button>

                        </div>

                    </div>
                </form>
            </div>
            <MainHeader />
            <Footer />
        </div>
    );
}

export default Profile;