import {
  Routes,
  Route,
  useNavigationType,
  useLocation,
} from "react-router-dom";
import { createTheme, ThemeProvider } from "@mui/material/styles";


import TripDaddy from "./pages/TripDaddy";
// import ShowItinerary from './pages/ShowItinerary';
import { useEffect } from "react";
import { testData } from "./test_data";
import SignInSide from "./pages/Login";
import LogIn from "./pages/LoginTest";
import SignUp from "./pages/Signup";
import Register from "./pages/Register";
import Logout from "./pages/Logout";
import VerifyEmail from "./pages/VerifyEmail";
import Home from "./pages/Home";
import ViewItinerary from "./pages/ViewItinerary";
import MyTrips from "./pages/MyTrips";
import MapComponent from "./components/Map";
import LoadingState from "./pages/LoadingState";
import "./index.css";
import LandingPageMobile from "./pages/mobile/Home";
import ItineraryPageNotSignedUp from "./pages/mobile/Itinerary";
import posthog from 'posthog-js';
import ReactGA from "react-ga4";
import ComingSoon from "./ComingSoon";
ReactGA.initialize("G-KZZ98RPBC5");
posthog.init('phc_iT5IAG9SMBpsMnSOZF6sC7KARPKhcOtdf8rXvzFWVl4', { api_host: 'https://app.posthog.com' })
import Profile from "./pages/Profile";
import ActivityDetails from "./pages/ActivityDetails";
import PrivacyPolicy from "./pages/PrivacyPolicy";
import TermsOfService from "./pages/TermsOfService";


const theme = createTheme({
  palette: {
    secondary: {
      main: "#D81B60",
    },
  },
});

function App() {
  const action = useNavigationType();
  const location = useLocation();
  const pathname = location.pathname;
  const isDevEnvironment = process.env.REACT_APP_ENV === "dev";

  useEffect(() => {
    posthog.capture('$pageview')

  }, [location]);

  useEffect(() => {
    if (action !== "POP") {
      window.scrollTo(0, 0);
    }
  }, [action, pathname]);

  useEffect(() => {
    let title = "";
    let metaDescription = "";

    switch (pathname) {
      case "/":
        title = "";
        metaDescription = "";
        break;
    }

    if (title) {
      document.title = title;
    }

    if (metaDescription) {
      const metaDescriptionTag = document.querySelector(
        'head > meta[name="description"]'
      );
      if (metaDescriptionTag) {
        metaDescriptionTag.content = metaDescription;
      }
    }
  }, [pathname]);

  useEffect(() => {
    if (!isDevEnvironment) {
      return;
    }

    let metaDescription = "noindex, nofollow";

    const metaDescriptionTag = document.querySelector(
      'head > meta[name="robots"]'
    );
    if (metaDescriptionTag) {
      metaDescriptionTag.content = metaDescription;
    }

  }, [pathname]);

  const getPasswordFromCookies = () => {
    const cookies = document.cookie.split("; ");
    const password = cookies.find((row) => row.startsWith("password="));
    if (password) {
      return password.split("=")[1];
    }
    return null;
  }

  // const password = getPasswordFromCookies();
  // if (password !== process.env.REACT_APP_PASS) {
  //   return (
  //     <ComingSoon />
  //   );
  // }

  return (
    <ThemeProvider theme={theme}>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/test" element={<ItineraryPageNotSignedUp />} />
        <Route path="/mytrips" element={<MyTrips />} />
        <Route path="/itinerary" element={<ViewItinerary />} />
        <Route path="/activityDetails" element={<ActivityDetails />} />
        <Route path="/login" element={<LogIn />} />
        {/* <Route path="/register" element={<Register />} /> */}
        <Route path="/profile" element={<Profile />} />
        <Route path="/forgotpassword" element={<SignInSide />} />
        <Route path="/logout" element={<Logout />} />
        <Route path="/verifyEmail" element={<VerifyEmail />} />
        <Route path="/privacy-policy" element={<PrivacyPolicy />} />
        <Route path="/terms-of-service" element={<TermsOfService />} />
      </Routes>
    </ThemeProvider>
  );
}
export default App;
