import React from 'react';
import styled from 'styled-components';
import {Link, NavLink} from 'react-router-dom';
import Cookies from 'js-cookie';
import jwt_decode from 'jwt-decode';

const NavContainer = styled.div`
top: 0px;
left: 1px;
width: 100%;
height: 60px;
background-color: #ffffff;
border-bottom: 1px solid #f0f0f0;
box-sizing: border-box;
position: relative;
padding: 10px;
`
const TextContainer = styled.div`
color: #030303;
font-size: 32px;
font-family: "Poppins";
font-weight: 500;
line-height: 38px;
position: absolute;
top: 50%;
-ms-transform: translateY(-50%);
transform: translateY(-50%);
`
// position the below div to the righmost side of the navbar
const ProfileContainer = styled.div`
position: absolute;
top: 50%;
right: 0px;
-ms-transform: translateY(-50%);
transform: translateY(-50%);

`
const ProfileNameContainer = styled.div`
color: #080a0b;
font-size: 16px;
font-family: "Poppins";
font-weight: 700;
line-height: 22px;
padding: 10px;
text-decoration: none;
`
const ProfileImageContainer = styled.div`
width: 40px;
height: 40px;
border-radius: 50%;
background-color: #f0f0f0;
margin: 10px;
`
// cneter the text inside and remove unserline from text
const LoginButtonContainer = styled.div`
    top: 32px;
    left: 1268px;
    width: 108px;
    height: 38px;
    padding: 0px 8px;
    border: 1px solid #030303;
    box-sizing: border-box;
    border-radius: 2px;
    background-color: rgba(248,248,248,0.07);
    color: #030303;
    font-size: 14px;
    font-family: "Poppins";
    line-height: 18px;
    outline: none;
    cursor: pointer;
    margin-right: 40px;
    margin-top: 6px;
    text-decoration: none;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    `


const NavBar = () => {
    const [isuserLoggedIn, setIsuserLoggedIn] = React.useState(false);
    const [user, setUser] = React.useState(null);
    const token = localStorage.getItem('token');
    if (token && !isuserLoggedIn) {
        try {
        setIsuserLoggedIn(true);
        setUser(jwt_decode(token));
        } catch (error) {
            setIsuserLoggedIn(false);
            localStorage.removeItem('token');
        }
        
    }

    return (
        <>
        <NavContainer >
            <NavLink to="/">
            <TextContainer>TripDaddy</TextContainer>
            </NavLink>
            <ProfileContainer>
                {isuserLoggedIn ? 
                (
                    <ProfileNameContainer>Hi, {user.firstname}</ProfileNameContainer>
                    ) :
                (<NavLink to="/login">
                <LoginButtonContainer >Log in</LoginButtonContainer>
                </NavLink>)
                }
            </ProfileContainer>
        </NavContainer>
        </>
    );
};

export default NavBar;
