import * as React from "react";
import { Modal } from "@mui/material";
import axios from "axios";
import LoadingFindActivities from "../pages/LoadingFindActivities";
import MapComponentAdd from "./MapComponentAdd";
import { TextField } from "@mui/material";


const getCircularReplacer = () => {
    const seen = new WeakSet();
    return (key, value) => {
        if (typeof value === "object" && value !== null) {
            if (seen.has(value)) {
                return;
            }
            seen.add(value);
        }
        return value;
    };
};

const ActivitiesModal = ({ action, open, handleClose, trip_data, itinerary }) => {
    const [loading, setLoading] = React.useState(false);
    const [user_pref, setUserPref] = React.useState("");
    const [alternatives, setAlternatives] = React.useState([]);


    // console.log(trip_data)
    const onClickActivity = (event) => {
        event.preventDefault();
        const el = event.currentTarget;
        const id = el.id;
        const activity = alternatives.find((activity) => {
            return activity.id === id;
        })
        if (confirm(`Are you sure you want to add ${activity.activity_name} to your itinerary?`)) {
            return action(activity);
        }
    }


    const getAlternativesUser = (pref) => {
        // event.preventDefault();
        if (!pref) {
            pref = user_pref;
        }
        setLoading(true);

        axios.post(`${process.env.REACT_APP_BACKEND_URL}/getAlternativesUser`, {
            trip_data: JSON.parse(JSON.stringify(trip_data, getCircularReplacer())),
            old_itinerary: JSON.parse(JSON.stringify(itinerary, getCircularReplacer())),
            // activity: content,
            user_pref: pref
        })
            .then(res => {
                console.log(res.data)
                setAlternatives(res.data.activities);
            }).catch(err => {
                console.log(err)
                alert("Error getting alternatives");
                setLoading(false);
            }).finally(() => {
                setLoading(false);
            }
            )
    }
    const findActivityButtonClicked = (event) => {
        event.preventDefault();
        const pref = document.getElementById("activities_input").value;
        if (!pref) {
            alert("Please enter an activity");
            return;
        }
        setUserPref(pref);
        getAlternativesUser(pref);
    }

    React.useEffect(() => {
        setUserPref(trip_data.interests);
    }, [])

    // React.useEffect(() => {
    //     getAlternativesUser();

    // }, [])

    const isSmallDevice = () => {
        return window.innerWidth < 600;
    }

    if (isSmallDevice()) {
        return <Modal open={open} onClose={handleClose} style={{}}>
            {loading ? <LoadingFindActivities /> :
                <div style={{ backgroundColor: "white", position: "fixed", width: "95%", top: "50%", left: "50%", transform: "translate(-50%, -50%)", height: "700px" }} className="relative rounded-[10px] bg-primary-contrast shadow-[0px_5px_26px_4px_rgba(0,_0,_0,_0.12),_0px_14px_21px_2px_rgba(0,_0,_0,_0.14),_0px_7px_9px_-4px_rgba(0,_0,_0,_0.2)] w-full overflow-hidden flex flex-col py-boundvariablesdata px-boundvariablesdata1 box-border items-center justify-start gap-[16px] text-left text-base text-primary-contrast font-button-small">
                    <div className="flex flex-row items-start justify-start gap-[40px]">
                        <div className="flex flex-col items-start justify-start gap-[8px]">
                            <TextField id="activities_input" label="Activities" variant="outlined" defaultValue={user_pref} style={{ width: "230px", height: "40px", paddingBottom: "15px" }} />

                            <div onClick={findActivityButtonClicked} className="rounded-borderradius bg-primary-main shadow-[0px_1px_5px_rgba(0,_0,_0,_0.12),_0px_2px_2px_rgba(0,_0,_0,_0.14),_0px_3px_1px_-2px_rgba(0,_0,_0,_0.2)] overflow-hidden flex flex-col py-1 px-2.5 items-center justify-center text-smi">
                                <div style={{ color: "white", cursor: "pointer" }} className="flex flex-row items-center justify-center gap-[8px]">
                                    <img
                                        className="relative w-4 h-[18px] hidden"
                                        alt=""
                                        src="/masked-icon.svg"
                                    />
                                    <b className="relative tracking-[0.46px] leading-[22px] capitalize">
                                        Find Activites
                                    </b>
                                    <img
                                        className="relative w-4 h-[18px] hidden"
                                        alt=""
                                        src="/masked-icon1.svg"
                                    />
                                </div>
                            </div>
                        </div>
                        <div onClick={handleClose} className="rounded-81xl bg-tripdaddy-grey-50 shadow-[0px_1px_3px_rgba(0,_0,_0,_0.12),_0px_1px_1px_rgba(0,_0,_0,_0.14),_0px_2px_1px_-1px_rgba(0,_0,_0,_0.2)] overflow-hidden flex flex-row items-center justify-start">
                            <div className="rounded-81xl overflow-hidden flex flex-row p-2 items-center justify-start">
                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                    <path d="M19 6.41L17.59 5L12 10.59L6.41 5L5 6.41L10.59 12L5 17.59L6.41 19L12 13.41L17.59 19L19 17.59L13.41 12L19 6.41Z" fill="black" fill-fillOpacity="0.87" />
                                </svg>
                            </div>
                        </div>
                    </div>
                    <div className="relative w-[325px] h-[514px] text-primary-main">
                        <div className="absolute top-[218px] left-[1px] rounded-xl flex flex-col items-start justify-start gap-[8px]">
                            <div className="relative tracking-[0.15px] leading-[150%] font-medium">{`Swap right to see more `}</div>
                            <div className="w-[325px] flex flex-row items-start justify-start text-xs text-black">
                                <div className="w-[325px] overflow-x-auto shrink-0 flex flex-row p-2 box-border items-start justify-start gap-[16px]">
                                    {alternatives.map((activity) => {
                                        return <div id={activity.id}
                                            key={activity.id}
                                            onClick={onClickActivity}
                                            style={{ cursor: "pointer"}}
                                            className="rounded-borderradius bg-tripdaddy-grey-50 shadow-[0px_1px_10px_rgba(0,_0,_0,_0.12),_0px_4px_5px_rgba(0,_0,_0,_0.14),_0px_2px_4px_-1px_rgba(0,_0,_0,_0.2)] flex flex-col p-2 items-center justify-start gap-[8px]">
                                            <img
                                                className="relative rounded-borderradius w-[130px] h-[79px] object-cover"
                                                alt=""
                                                src={activity.images[0].url}
                                            />
                                            <div className="w-[131px] flex flex-col items-start justify-start gap-[8px]">
                                                <div className="relative tracking-[0.4px] leading-[166%] font-medium flex items-center w-[131px]">
                                                    {activity.activity_name}
                                                </div>
                                                <div className="relative text-3xs tracking-[0.4px] leading-[166%] flex items-center w-[131px]">
                                                    {activity.summary}
                                                </div>
                                            </div>
                                        </div>
                                    })}

                                </div>
                            </div>
                        </div>
                        <div className="absolute top-[0px] left-[calc(50%_-_162.5px)] rounded-xl flex flex-col p-2 items-start justify-start gap-[8px] border-[1px] border-solid border-tripdaddy-amber-200">
                            <div className="relative tracking-[0.15px] leading-[150%] font-medium">
                                Explore The Activities on the map
                            </div>
                            <MapComponentAdd trip_data={trip_data} activities={alternatives} small={true} locations={alternatives.map((activity) => {
                                return activity.place_details.candidates[0] ? activity.place_details.candidates[0].geometry.location : { lat: 0, lng: 0 };
                            })} />
                        </div>
                    </div>
                </div>
            }

        </Modal>
    }

    return (
        <Modal open={open} onClose={handleClose} style={{}}>
            {loading ? <LoadingFindActivities /> :
                <div
                    className="shadow-[0px_7px_9px_-4px_rgba(0,_0,_0,_0.2),_0px_14px_21px_2px_rgba(0,_0,_0,_0.14),_0px_5px_26px_4px_rgba(0,_0,_0,_0.12)] overflow-hidden bg-white flex flex-row gap-12 w-full items-start pt-6 px-4 rounded-lg"
                    id="AcitivitesModalRoot"
                >
                    <div className="flex flex-col mb-8 gap-3 items-end">
                        <div className="self-start flex flex-row gap-6 w-[446px] items-start mb-2 mr-6">
                            <div className="relative flex flex-col w-[295px] items-start pb-8 px-4">
                                <TextField id="activities_input" label="Activities" variant="outlined" defaultValue={user_pref} style={{ width: "295px", height: "40px" }} />
                            </div>
                            <button
                                className="shadow-[0px_3px_1px_-2px_rgba(0,_0,_0,_0.2),_0px_2px_2px_0px_rgba(0,_0,_0,_0.14),_0px_1px_5px_0px_rgba(0,_0,_0,_0.12)] overflow-hidden bg-[#d81b60] flex flex-col mt-2 h-8 items-center py-1 rounded"
                                id="Button2"
                                style={{ cursor: "pointer", color: "white" }}
                                onClick={findActivityButtonClicked}
                            >
                                <div
                                    className="text-sm font-['Quicksand'] font-bold tracking-[0.4] leading-[24px] capitalize text-white mx-4"
                                    id="Button1"

                                >
                                    Find Activites
                                </div>
                            </button>
                        </div>
                        <div className="text-xs font-['Quicksand'] tracking-[0.4] leading-[19.9px] mr-2">
                            viewing 10 results
                        </div>
                        <div id="ActivitiesContainer" style={{ maxHeight: "650px", overflowY: "scroll" }}>
                            {alternatives.map((activity) => {
                                return <div
                                    className="shadow-[0px_2px_4px_-1px_rgba(0,_0,_0,_0.2),_0px_4px_5px_0px_rgba(0,_0,_0,_0.14),_0px_1px_10px_0px_rgba(0,_0,_0,_0.12)] bg-[#fafafa] flex flex-row justify-center pt-4 gap-4 w-[411px] items-center mb-5 mr-2 rounded darken-on-hover"
                                    id={activity.id}
                                    key={activity.id}
                                    onClick={onClickActivity}
                                    style={{ cursor: "pointer" }}
                                >
                                    <img
                                        src={activity.images[0].url}
                                        className="mb-4"
                                        id="UnsplashDZCRrmJk"
                                        style={{ height: "110px", width: "170px" }}
                                    />
                                    <div className="self-start flex flex-col mt-2 gap-2 w-1/2 items-start">
                                        <div className="text-sm font-['Quicksand'] font-medium tracking-[0.17] leading-[20px]">
                                            {activity.activity_name}
                                        </div>
                                        <div className="text-xs font-['Quicksand'] tracking-[0.4] leading-[19.9px] w-full">
                                            {activity.summary}
                                        </div>
                                    </div>
                                </div>
                            }
                            )
                            }
                        </div>
                    </div>
                    <div className="flex flex-col mt-1 gap-10 w-3/5 items-end">
                        <div
                            className="shadow-[0px_2px_1px_-1px_rgba(0,_0,_0,_0.2),_0px_1px_1px_0px_rgba(0,_0,_0,_0.14),_0px_1px_3px_0px_rgba(0,_0,_0,_0.12)] overflow-hidden bg-[#fafafa] flex flex-col justify-center w-12 h-12 shrink-0 items-center rounded-[100px]"
                            id="Fab"
                            style={{ cursor: "pointer" }}
                            onClick={handleClose}
                        >
                            <img
                                src="https://file.rendit.io/n/wUlSclxSP2R5NPn7zdp4.svg"
                                className="w-6"
                                id="CloseFilled1"
                            />
                        </div>
                        <div
                            className="border-solid border-[#ffe082] shadow-[0px_3px_3px_-2px_rgba(0,_0,_0,_0.2),_0px_3px_4px_0px_rgba(0,_0,_0,_0.14),_0px_1px_8px_0px_rgba(0,_0,_0,_0.12)] self-stretch flex flex-col gap-4 items-center px-8 py-2 border rounded-[20px]"
                            id="Map1"
                        >
                            <div className="text-4xl font-['Quicksand'] font-bold tracking-[0.25] leading-[42px] text-[#d81b60]">
                                Explore The Activities on the map
                            </div>
                            <MapComponentAdd trip_data={trip_data} activities={alternatives} locations={alternatives.map((activity) => {
                                return activity.place_details.candidates[0] ? activity.place_details.candidates[0].geometry.location : { lat: 0, lng: 0 };
                            })} />
                            {/* <div
                            className="bg-[url(https://file.rendit.io/n/sNS2dKKTw0WTWFE0wrpT.png)] bg-cover bg-50%_50% bg-blend-normal bg-no-repeat self-stretch flex flex-row gap-10 h-[537px] shrink-0 items-center pt-32 px-32"
                            id="Snazzyimage"
                        >
                            <div className="self-start flex flex-col gap-10 w-2/3">
                                <div className="flex flex-row ml-20 gap-12 items-start">
                                    <img
                                        src="https://file.rendit.io/n/uyXCoNsZTjWTOGdfTVab.svg"
                                        className="w-12 shrink-0"
                                        id="LocationOnFilled2"
                                    />
                                    <img
                                        src="https://file.rendit.io/n/qCyKiHe5dpoECqiBNLTr.svg"
                                        className="self-end mt-12 w-16 shrink-0"
                                        id="LocationOnFilled3"
                                    />
                                </div>
                                <div className="flex flex-row mr-20 gap-10 items-start">
                                    <img
                                        src="https://file.rendit.io/n/NXXO3OZPCOUNpmQeSlad.svg"
                                        className="w-16 shrink-0"
                                        id="LocationOnFilled4"
                                    />
                                    <img
                                        src="https://file.rendit.io/n/gbzH3mbb2nLZUiexMvHV.svg"
                                        className="self-end mt-16 w-16 shrink-0"
                                        id="LocationOnFilled1"
                                    />
                                </div>
                            </div>
                            <img
                                src="https://file.rendit.io/n/2JiMO95UDT2EfRzawamu.svg"
                                className="w-16 shrink-0"
                                id="LocationOnFilled"
                            />
                        </div> */}
                        </div>
                    </div>
                </div>
            }

        </Modal>
    );

}

export default ActivitiesModal;