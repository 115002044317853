import * as React from "react";
import Header from "../components/Header";
import axios from "axios";
import { useState } from "react";

const defaultTips = [
    {
        "tip": '“I haven’t been everywhere, but it’s on my list.” – Susan Sontag'
    },
    {
        "tip": '“When preparing to travel, lay out all your clothes and all your money. Then take half the clothes and twice the money.” – Susan Heller'
    },
    {
        "tip": '“Vacation calories don’t count. Right?” – Unknown'
    },
    {
        "tip": '“I want someone to look at me the way I look at a travel magazine.” – Unknown'
    },
    {
        "tip": '“The worst thing about being a tourist is having other tourists recognize you as a tourist.” – Russell Baker'
    },
    {
        "tip": '“Man cannot discover new oceans unless he has the courage to lose sight of the shore.” – Andre Gide'
    },
    {
        "tip": '“Remember that happiness is a way of travel – not a destination.” – Roy M. Goodman'
    },
    {
        "tip": 'The most beautiful in the world is, of course, the world itself.” – Wallace Stevens'
    },
    {
        "tip": '“Traveling – it leaves you speechless, then turns you into a storyteller.” – Ibn Battuta'
    },
    {
        "tip": '“Perhaps travel cannot prevent bigotry, but by demonstrating that all people cry, laugh, eat, worry, and die, it can introduce the idea that if we try and understand each other, we may even become friends.” – Maya Angelou'
    },
    {
        "tip": '“A person susceptible to ‘wanderlust’ is not so much addicted to movement as committed to transformation.”  – Pico Iyer'
    },
    {
        "tip": '“We travel, some of us forever, to seek other states, other lives, other souls.” – Anais Nin'
    },
    {
        "tip": '“I travel not to go anywhere, but to go. I travel for travel’s sake. The great affair is to move.” – Robert Louis Stevenson'
    },
    {
        "tip": '“To Travel is to Live.” – Hans Christian Andersen'
    },
    {
        "tip": '“Life is either a daring adventure or nothing at all.” – Helen Keller'
    },
    {
        "tip": '“Blessed are the curious for they shall have adventures.” – Lovelle Drachman'
    },
    {
        "tip": '“Take only memories, leave only footprints.” – Chief Seattle'
    },
    {
        "tip": '“Live your life by a compass not a clock.” – Stephen Covey'
    },
    {
        "tip": '“Not all those who wander are lost.” – J.R.R. Tolkien'
    },
    {
        "tip": '“Oh the places you’ll go.” – Dr. Seuss'
    },
    {
        "tip": '“Adventure is worthwhile.” – Aesop'
    },
    {
        "tip": '“Life is short and the world is wide.” – Unknown'
    },
    {
        "tip": '“A journey of a thousand miles begins with a single step.” – Lao Tzu'
    },
    {
        "tip": '“It is not down in any map; true places never are.” – Herman Melville'
    },
    {
        "tip": '“I’m in love with cities I’ve never been to and people I’ve never met.” – John Green'
    },
    {
        "tip": '“The world is a book and those who do not travel read only one page.” – Augustine of Hippo'
    },
    {
        "tip": '“Live life with no excuses, travel with no regret.” – Oscar Wilde'
    },
    {
        "tip": '“Once a year, go someplace you’ve never been before.” – Dalai Lama'
    },
    {
        "tip": '“People don’t take trips, trips take people.” – John Steinbeck'
    },
    {
        "tip": 'A good traveler has no fixed plans, and is not intent on arriving.” – Lao Tzu'
    },
    {
        "tip": '“The world makes way for the man who knows where he is going.” – Ralph Waldo Emerson'
    },
    {
        "tip": '“It’s not what you look at that matters. It’s what you see.” – Henry David Thoreau'
    },
    {
        "tip": '“Two roads diverged in a wood and I – I took the one less traveled by, and that has made all the difference.” – Robert Frost'
    },
    {
        "tip": '“Twenty years from now you will be more disappointed by the things that you didn’t do than by the ones you did do. So throw off the bowlines. Sail away from the safe harbor. Catch the trade winds in your sails. Explore. Dream. Discover.” – Mark Twain'
    },
    {
        "tip": '“Travel is  fatal to prejudice, bigotry, and narrow mindedness, and many of our people need it sorely on these accounts.” – Mark Twain'
    },
    {
        "tip": '“I have found out that there ain’t no surer way to find out whether you like people or hate them than to travel with them.” – Mark Twain'
    }
]

const LoadingState = ({ place }) => {
    const [quote, setQuote] = React.useState(null);
    const [resData, setResData] = React.useState(null);

    
    var data;
    const [tips, setTips] = useState(false);
    const changeTips = () => {
        // console.log(data);

        if (resData && resData.length > 0) {
            // console.log(data[0].tip);
            const tip = resData[Math.floor(Math.random() * resData.length)].tip;
            // console.log(tip);
            setQuote(tip);
            // console.log(quote);

        } else {
            setQuote(defaultTips[Math.floor(Math.random() * defaultTips.length)].tip);
        }

    }

    const getTips = () => {
        const d = {
            place: place
        }

        // const d = {
        //     place: "France, Paris"
        // }
        axios.post(`${process.env.REACT_APP_BACKEND_URL}/getTips`, { d })
            .then(res => {
                console.log(res.data)
                data = res.data.tips;
                setTips(true);
                setResData(res.data.tips);


            }).catch(err => {
                console.log(err)
                // alert("Error in creating itinerary. Please try again.")
            })
    }


    React.useEffect(() => {
        getTips();
        changeTips();
    }, []);




    const bagStates = [<svg xmlns="http://www.w3.org/2000/svg" width="143" height="121" viewBox="0 0 143 121" fill="none">
        <path d="M11.0764 72.873L67.4695 116.83H24.6304C17.1511 116.83 11.0764 110.722 11.0764 103.202V72.873Z" fill="#FFF8E1" />
        <path d="M135.836 23.4425L46.8927 91.7614L46.366 92.1497L11.0764 64.681V35.4468C11.0764 27.9265 17.1511 21.8184 24.6304 21.8184H129.375C131.728 21.8184 133.94 22.4186 135.836 23.4425Z" fill="#FCE4EC" />
        <path d="M143 35.4473V103.166C143 110.686 136.925 116.794 129.446 116.794H78.0039L51.6333 96.2811L140.682 27.8916C142.157 30.0453 143 32.658 143 35.4473Z" fill="#E1F5FE" />
        <path d="M124.6 117.959H16.4487C9.00449 117.959 3 111.886 3 104.436V39.3658C3 29.0562 11.322 20.6885 21.5753 20.6885H119.473C129.726 20.6885 138.048 29.0562 138.048 39.3658V104.436C138.084 111.922 132.044 117.959 124.6 117.959Z" stroke="#424242" strokeWidth="5" strokeMiterlimit="10" />
        <path d="M56.5576 36.5002L25.6499 52.3477L32.956 66.754L63.8637 50.9066L56.5576 36.5002Z" fill="white" />
        <path d="M49.1828 40.2795L18.2751 56.127L25.5813 70.5333L56.489 54.6859L49.1828 40.2795Z" stroke="#424242" strokeWidth="5" strokeMiterlimit="10" />
        <path d="M92.9975 105.92C99.8432 105.92 105.393 100.34 105.393 93.4565C105.393 86.5732 99.8432 80.9932 92.9975 80.9932C86.1518 80.9932 80.6023 86.5732 80.6023 93.4565C80.6023 100.34 86.1518 105.92 92.9975 105.92Z" fill="white" />
        <path d="M84.9216 106.414C91.7673 106.414 97.3169 100.834 97.3169 93.9506C97.3169 87.0673 91.7673 81.4873 84.9216 81.4873C78.0759 81.4873 72.5264 87.0673 72.5264 93.9506C72.5264 100.834 78.0759 106.414 84.9216 106.414Z" stroke="#424242" strokeWidth="5" strokeMiterlimit="10" />
        <path d="M114.803 49.2168H97.8081V66.3053H114.803V49.2168Z" fill="white" />
        <path d="M109.992 49.2168H92.9971V66.3053H109.992V49.2168Z" stroke="#424242" strokeWidth="5" strokeMiterlimit="10" />
        <path d="M53.5291 20.6535V12.5682C53.5291 7.27214 57.7778 3 63.0449 3H79.0569C84.324 3 88.5728 7.27214 88.5728 12.5682V20.6535" stroke="#424242" strokeWidth="5" strokeMiterlimit="10" />
    </svg>,
    <svg xmlns="http://www.w3.org/2000/svg" width="143" height="121" viewBox="0 0 143 121" fill="none">
        <path d="M11.0764 72.873L67.4695 116.83H24.6304C17.1511 116.83 11.0764 110.722 11.0764 103.202V72.873Z" fill="#FFF8E1" />
        <path d="M135.836 23.4425L46.8927 91.7614L46.366 92.1497L11.0764 64.681V35.4468C11.0764 27.9265 17.1511 21.8184 24.6304 21.8184H129.375C131.728 21.8184 133.94 22.4186 135.836 23.4425Z" fill="#F48FB1" />
        <path d="M143 35.4473V103.166C143 110.686 136.925 116.794 129.446 116.794H78.0039L51.6333 96.2811L140.682 27.8916C142.157 30.0453 143 32.658 143 35.4473Z" fill="#E1F5FE" />
        <path d="M124.6 117.959H16.4487C9.00449 117.959 3 111.886 3 104.436V39.3658C3 29.0562 11.322 20.6885 21.5753 20.6885H119.473C129.726 20.6885 138.048 29.0562 138.048 39.3658V104.436C138.084 111.922 132.044 117.959 124.6 117.959Z" stroke="#424242" strokeWidth="5" strokeMiterlimit="10" />
        <path d="M56.5576 36.5002L25.6499 52.3477L32.956 66.754L63.8637 50.9066L56.5576 36.5002Z" fill="white" />
        <path d="M49.1828 40.2795L18.2751 56.127L25.5813 70.5333L56.489 54.6859L49.1828 40.2795Z" stroke="#424242" strokeWidth="5" strokeMiterlimit="10" />
        <path d="M92.9975 105.92C99.8432 105.92 105.393 100.34 105.393 93.4565C105.393 86.5732 99.8432 80.9932 92.9975 80.9932C86.1518 80.9932 80.6023 86.5732 80.6023 93.4565C80.6023 100.34 86.1518 105.92 92.9975 105.92Z" fill="white" />
        <path d="M84.9216 106.414C91.7673 106.414 97.3169 100.834 97.3169 93.9506C97.3169 87.0673 91.7673 81.4873 84.9216 81.4873C78.0759 81.4873 72.5264 87.0673 72.5264 93.9506C72.5264 100.834 78.0759 106.414 84.9216 106.414Z" stroke="#424242" strokeWidth="5" strokeMiterlimit="10" />
        <path d="M114.803 49.2168H97.8081V66.3053H114.803V49.2168Z" fill="white" />
        <path d="M109.992 49.2168H92.9971V66.3053H109.992V49.2168Z" stroke="#424242" strokeWidth="5" strokeMiterlimit="10" />
        <path d="M53.5291 20.6535V12.5682C53.5291 7.27214 57.7778 3 63.0449 3H79.0569C84.324 3 88.5728 7.27214 88.5728 12.5682V20.6535" stroke="#424242" strokeWidth="5" strokeMiterlimit="10" />
    </svg>,
    <svg xmlns="http://www.w3.org/2000/svg" width="143" height="121" viewBox="0 0 143 121" fill="none">
        <path d="M11.0764 72.873L67.4695 116.83H24.6304C17.1511 116.83 11.0764 110.722 11.0764 103.202V72.873Z" fill="#FFF8E1" />
        <path d="M135.836 23.4425L46.8927 91.7614L46.366 92.1497L11.0764 64.681V35.4468C11.0764 27.9265 17.1511 21.8184 24.6304 21.8184H129.375C131.728 21.8184 133.94 22.4186 135.836 23.4425Z" fill="#F48FB1" />
        <path d="M143 35.4473V103.166C143 110.686 136.925 116.794 129.446 116.794H78.0039L51.6333 96.2811L140.682 27.8916C142.157 30.0453 143 32.658 143 35.4473Z" fill="#81D4FA" />
        <path d="M124.6 117.959H16.4487C9.00449 117.959 3 111.886 3 104.436V39.3658C3 29.0562 11.322 20.6885 21.5753 20.6885H119.473C129.726 20.6885 138.048 29.0562 138.048 39.3658V104.436C138.084 111.922 132.044 117.959 124.6 117.959Z" stroke="#424242" strokeWidth="5" strokeMiterlimit="10" />
        <path d="M56.5576 36.5002L25.6499 52.3477L32.956 66.754L63.8637 50.9066L56.5576 36.5002Z" fill="white" />
        <path d="M49.1828 40.2795L18.2751 56.127L25.5813 70.5333L56.489 54.6859L49.1828 40.2795Z" stroke="#424242" strokeWidth="5" strokeMiterlimit="10" />
        <path d="M92.9975 105.92C99.8432 105.92 105.393 100.34 105.393 93.4565C105.393 86.5732 99.8432 80.9932 92.9975 80.9932C86.1518 80.9932 80.6023 86.5732 80.6023 93.4565C80.6023 100.34 86.1518 105.92 92.9975 105.92Z" fill="white" />
        <path d="M84.9216 106.414C91.7673 106.414 97.3169 100.834 97.3169 93.9506C97.3169 87.0673 91.7673 81.4873 84.9216 81.4873C78.0759 81.4873 72.5264 87.0673 72.5264 93.9506C72.5264 100.834 78.0759 106.414 84.9216 106.414Z" stroke="#424242" strokeWidth="5" strokeMiterlimit="10" />
        <path d="M114.803 49.2168H97.8081V66.3053H114.803V49.2168Z" fill="white" />
        <path d="M109.992 49.2168H92.9971V66.3053H109.992V49.2168Z" stroke="#424242" strokeWidth="5" strokeMiterlimit="10" />
        <path d="M53.5291 20.6535V12.5682C53.5291 7.27214 57.7778 3 63.0449 3H79.0569C84.324 3 88.5728 7.27214 88.5728 12.5682V20.6535" stroke="#424242" strokeWidth="5" strokeMiterlimit="10" />
    </svg>,
    <svg xmlns="http://www.w3.org/2000/svg" width="143" height="121" viewBox="0 0 143 121" fill="none">
        <path d="M11.0764 72.873L67.4695 116.83H24.6304C17.1511 116.83 11.0764 110.722 11.0764 103.202V72.873Z" fill="#FFE082" />
        <path d="M135.836 23.4425L46.8927 91.7614L46.366 92.1497L11.0764 64.681V35.4468C11.0764 27.9265 17.1511 21.8184 24.6304 21.8184H129.375C131.728 21.8184 133.94 22.4186 135.836 23.4425Z" fill="#F48FB1" />
        <path d="M143 35.4473V103.166C143 110.686 136.925 116.794 129.446 116.794H78.0039L51.6333 96.2811L140.682 27.8916C142.157 30.0453 143 32.658 143 35.4473Z" fill="#81D4FA" />
        <path d="M124.6 117.959H16.4487C9.00449 117.959 3 111.886 3 104.436V39.3658C3 29.0562 11.322 20.6885 21.5753 20.6885H119.473C129.726 20.6885 138.048 29.0562 138.048 39.3658V104.436C138.084 111.922 132.044 117.959 124.6 117.959Z" stroke="#424242" strokeWidth="5" strokeMiterlimit="10" />
        <path d="M56.5576 36.5002L25.6499 52.3477L32.956 66.754L63.8637 50.9066L56.5576 36.5002Z" fill="white" />
        <path d="M49.1828 40.2795L18.2751 56.127L25.5813 70.5333L56.489 54.6859L49.1828 40.2795Z" stroke="#424242" strokeWidth="5" strokeMiterlimit="10" />
        <path d="M92.9975 105.92C99.8432 105.92 105.393 100.34 105.393 93.4565C105.393 86.5732 99.8432 80.9932 92.9975 80.9932C86.1518 80.9932 80.6023 86.5732 80.6023 93.4565C80.6023 100.34 86.1518 105.92 92.9975 105.92Z" fill="white" />
        <path d="M84.9216 106.414C91.7673 106.414 97.3169 100.834 97.3169 93.9506C97.3169 87.0673 91.7673 81.4873 84.9216 81.4873C78.0759 81.4873 72.5264 87.0673 72.5264 93.9506C72.5264 100.834 78.0759 106.414 84.9216 106.414Z" stroke="#424242" strokeWidth="5" strokeMiterlimit="10" />
        <path d="M114.803 49.2168H97.8081V66.3053H114.803V49.2168Z" fill="white" />
        <path d="M109.992 49.2168H92.9971V66.3053H109.992V49.2168Z" stroke="#424242" strokeWidth="5" strokeMiterlimit="10" />
        <path d="M53.5291 20.6535V12.5682C53.5291 7.27214 57.7778 3 63.0449 3H79.0569C84.324 3 88.5728 7.27214 88.5728 12.5682V20.6535" stroke="#424242" strokeWidth="5" strokeMiterlimit="10" />
    </svg>

    ]


    const [animationStep, setAnimationStep] = React.useState(0);

    React.useEffect(() => {
        const interval = setInterval(() => {
            setAnimationStep((prevStep) => (prevStep + 1) % 4);
        }, 2000); // interval time of animation in milliseconds

        return () => clearInterval(interval);
    }, []);
    React.useEffect(() => {
        if (animationStep == 0) {
            changeTips();
            getRandomTip();
        }
    }, [animationStep]);
    const [oldTip, setOldTip] = React.useState(0);

    const getRandomTip = () => {
        if (!resData) return;
        return setOldTip((oldTip + 1) % resData.length);
    }

    const renderEllipses = () => {
        const ellipses = [];
        for (let i = 0; i < 4; i++) {
            ellipses.push(
                <img
                    key={`Ellipse${i}`}
                    src={
                        animationStep >= i
                            ? "/dot_filled_loading.svg"
                            : "/dot_empty_loading.svg"
                    }
                    className="w-4 shrink-0"
                    alt={`Ellipse${i}`}
                />
            );
        }
        return ellipses;
    };

    return <>
        <div
            className="overflow-hidden bg-white flex flex-col justify-between pb-[287px] w-full h-[800px] items-center"
            id="LoadingRoot"
            style={{ maxHeight: "100vh" }}
        >
            <Header />
            <div className="grid h-screen place-items-center">
                <div className="flex flex-col gap-1 items-start ">
                    <div className="text-center text-xl font-['Quicksand'] font-bold tracking-[0.15] leading-[32px] text-black/87 mb-2" style={{ width: "100%" }}>
                        Preparing itinerary...
                    </div>
                    <div className="self-end mr-[109px]"
                        style={{ margin: "auto" }}>
                        {bagStates[animationStep]}

                    </div>
                    <div className="self-end flex flex-row justify-between w-20 items-start mb-3 mr-32" style={{ margin: "auto" }}>
                        {renderEllipses()}
                    </div>
                    <div className="text-center font-['Quicksand'] tracking-[0.15] leading-[24px] text-black/87 ml-1" style={{ maxWidth: "400px", marginTop: "15px" }}>
                        {tips ? resData[oldTip].tip : quote}
                    </div>
                </div>
            </div>
            <div style={{ display: "none" }}>{tips}</div>
        </div>
    </>;
}

export default LoadingState;